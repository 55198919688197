import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../Iaq.css";
import LineGraph from "../../../components/charts/LineGraph";
import {
  CLAIRCO_CLIENT_HISTORY_TYPES,
  CLAIRCO_INSTALLATION_TYPE,
} from "../../../utils/constants";
import GraphSkeleton from "../../../components/loading_skeletons/GraphSkeleton";
import { useLocation } from "react-router-dom";
import { getIAQData } from "../../../utils/Api";
import moment from "moment";
import { toast } from "react-toastify";
import CardSkeleton from "../../../components/loading_skeletons/CardSkeleton";
const Occupancy = () => {
  const location = useLocation();
  const [graphLoading, setGraphLoading] = useState(true);
  const [interval, setInterval] = useState(
    CLAIRCO_CLIENT_HISTORY_TYPES.LAST_12_HOURS,
  );
  const [isIaqDataViewRestricted, setIsIaqDataViewRestricted] = useState(false);
  const [avgTempandHumLoading, setAvgTempandHumLoading] = useState(true);
  const [selectedDate, setselectedDate] = useState(
    moment().format("YYYY-MM-DD"),
  );
  const [occupancy, setOccupancy] = useState({
    data: null,
    timestamp: null,
  });
  const [occupancyGraphData, setOccupancyGraphData] = useState({
    labels: [],
    datasets: [],
  });
  const branchId =
    JSON.parse(localStorage.getItem("schema"))?.[0]?.metaData?.branch_id || "";
  const deviceId = location.state?.deviceInfo?.id || "  ";

  const calculateLastHourIAQAvg = (lastHourValues) => {
    // Occupancy Calculation
    let countNonNullOccupancyValues = 0;

    // console.log("Last Hour Values ", lastHourValues);

    // const totalOccupancyValueInLastHour = lastHourValues.reduce((acc, data) => {
    //   if (data?.metaData?.occupancy_number) {
    //     countNonNullOccupancyValues++;
    //     return acc + Number(data.metaData.occupancy_number ?? 0);
    //   }
    //   return acc;
    // }, 0);

    // if (totalOccupancyValueInLastHour) {
    //   let avgOccupancy =
    //     totalOccupancyValueInLastHour / countNonNullOccupancyValues;
    //   setOccupancy(parseInt(avgOccupancy));
    // }

    // console.log("Latest Value ", lastHourValues.slice(-1));
    const lastElement = lastHourValues?.slice(-1)?.[0] ?? {};
    const occupancyNumber = lastElement?.metaData?.occupancy_number ?? "NA";
    const updateDateTime = lastElement?.updatedateandtime ?? null;
    // console.log("update date and time ", updateDateTime);

    if (!updateDateTime) {
      setOccupancy({
        data: occupancyNumber,
        timestamp: null,
      });
    } else {
      const formattedTimestamp = moment(updateDateTime).format(
        "h:mm A, DD MMM YYYY",
      );
      setOccupancy({
        data: occupancyNumber,
        timestamp: formattedTimestamp,
      });
    }

    return;
  };

  useEffect(() => {
    const payload = {
      branchId,
      deviceId,
      fetchType: "history",
      flag_details: {
        installation_type: CLAIRCO_INSTALLATION_TYPE,
        interval: interval,
      },
    };
    setGraphLoading(true);
    getIAQData(payload)
      .then(({ data }) => {
        const dataset = data?.msg ?? [];
        // console.log(data);
        // const labels =
        //   dataset.map((data) => `${data?.time},${data?.date || ""}`) || [];
        const labels =
          dataset.map((data) => {
            const date = data.date;
            const time = data.time;
            const dateTime = moment(date + "T" + time);
            const formattedDateTime = dateTime.format("hh:mm A, DD MMM");
            return formattedDateTime;
          }) || [];
        // const occupancyValues =
        //   dataset.map((data) => data?.metaData?.occupancy_number[0]) || [];
        const occupancyValues =
          dataset.map((data) => data?.metaData?.occupancy_number ?? 0) || [];
        // console.log("occupancy values ", occupancyValues);
        setOccupancyGraphData({
          labels,
          datasets: [
            {
              label: "Occupancy",
              data: occupancyValues,
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
              fill: true,
              pointRadius: 2,
              tension: 0.5,
              yAxisID: "iaq_occupancy",
            },
          ],
        });
        const lastHourValues = dataset.slice(-30);
        if (!lastHourValues?.length) return;
        calculateLastHourIAQAvg(lastHourValues);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong! Please try again");
        if (error?.response?.status === 403) {
          setIsIaqDataViewRestricted(true);
        }
      })
      .finally(() => {
        setGraphLoading(false);
        setAvgTempandHumLoading(false);
      });
  }, [interval]);

  return (
    <>
      <select
        value={interval}
        onChange={(e) => setInterval(e.target.value)}
        style={{ width: "300px" }}
      >
        <option value="12 hours">12 Hours</option>
        <option value="1 day">1 Day</option>
        <option value="3 days">3 Days</option>
        <option value="7 days">7 Days</option>
      </select>
      <Grid container spacing={3} rowGap={3}>
        <Grid item md={8} xs={12}>
          <Box
            sx={{ width: "100%", height: "400px", backgroundColor: "white" }}
          >
            {graphLoading ? (
              <div style={{ padding: "2rem" }}>
                <GraphSkeleton />
              </div>
            ) : (
              <LineGraph
                title="Current Consumption"
                data={occupancyGraphData}
                temp_flow={false}
                cooling_energy={false}
                temp={false}
                ChWP={false}
                iaq_temp={false}
                iaq_hum={false}
                iaq_occupancy={true}
              />
            )}
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          {graphLoading ? (
            <div style={{ padding: "2rem", backgroundColor: "white" }}>
              <CardSkeleton />
            </div>
          ) : (
            <Box className="iaq_cards">
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "right",
                  fontSize: "14px",
                }}
                gutterBottom
              >
                {occupancy.timestamp && occupancy.timestamp}
              </Typography>
              <Box>
                <Typography variant="h4" className="iaq_card_title">
                  {occupancy.data ?? "NA"}
                </Typography>

                <Typography gutterBottom className="iaq_card_description">
                  Occupancy
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Occupancy;
