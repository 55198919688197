import React from "react";
import { useAhuContext } from "../../context/AhuContext";
import { Grid, Typography, Paper, Box, Stack } from "@mui/material";
import {
  CLAIRCO_INSTALLATION_TYPE,
  ahuTableHeads,
} from "../../utils/constants";
import CardSkeleton from "../../components/loading_skeletons/CardSkeleton";
import moment from "moment";
import "./overview.css";

import { Air } from "@mui/icons-material";

import { getItemFromLocalstroage } from "../../utils/helper";

const AhuStatus = () => {
  const {
    ahusCurrentDayStatus,
    ahusCurrentDayStatusLoading,
    isAhuOverviewRestricted,
  } = useAhuContext();
  // console.log(ahusCurrentDayStatus);
  let ahuArray = ahusCurrentDayStatus.map((data) => Object.values(data));
  const installationType = getItemFromLocalstroage("installationType");
  const today = moment().format("DD MMM");

  // console.log(ahuArray);
  // ahuArray.map((item, index) => item.unshift(index + 1));
  return (
    <Grid item lg={12} xs={12} xl={12} sx={{ mb: "20px" }}>
      {isAhuOverviewRestricted ? (
        <Box>
          <Typography variant="h6">AHU</Typography>
          <Typography variant="subtitle2" gutterBottom>
            Running Hours & AHU status
          </Typography>
          <Typography variant="body1">View is Restricted</Typography>
        </Box>
      ) : ahusCurrentDayStatusLoading ? (
        <CardSkeleton />
      ) : (
        // <TabularData tableHeaders={ahuTableHeads} tableBodyData={ahuArray} />
        <Paper sx={{ padding: "20px" }} elevation={0}>
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            sx={{ marginBottom: "12px" }}
          >
            <Box>
              <Air sx={{ color: "#344853", fontSize: "30px" }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#344a53",
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                AHU
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ color: "gray" }}
              >
                {installationType === CLAIRCO_INSTALLATION_TYPE
                  ? "Status & Running Hours (Last 24 hours)"
                  : `Status & Running Hours (${today})`}
              </Typography>
            </Box>
          </Stack>

          <div style={{ overflowX: "auto" }}>
            <table className="ahu_table">
              <thead>
                <tr>
                  {ahuTableHeads.map((item, index) => (
                    <th key={index} className="ahu_table_head">
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(ahuArray) && ahuArray.length > 0 ? (
                  ahuArray.map((row) => (
                    <tr>
                      {row.map((cell) => (
                        <td className="ahu_table_cell">{cell}</td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="100%" align="center">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Paper>
      )}
    </Grid>
  );
};

export default AhuStatus;
