import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useDataContext } from "../../context/DataContext";
import GraphSkeleton from "../../components/loading_skeletons/GraphSkeleton";
import AreaChart from "../../components/charts/AreaChart";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Engineering, Error, Hvac } from "@mui/icons-material";

const EnBtu = () => {
  const { enBtuGraph, fetchEnBtuData, btuDeviceInfo, schemaStatus } =
    useDataContext();
  const graphData = {
    labels: enBtuGraph?.labels,
    datasets: [
      {
        fill: true,
        label: `Daily BTU Consumption in ${enBtuGraph.month}`,
        data: enBtuGraph.data,
        borderColor: "rgb(0, 224, 212)",
        backgroundColor: "rgba(0, 224, 212,0.5)",
        pointRadius: 4,
        tension: 0.4,
        yAxisID: "enBTU",
        xAxisID: "Days",
      },
    ],
  };

  const EnBtuGraph = ({ handleMonthChange, graphData }) => {
    const { enBtuGraphLoading } = useDataContext();
    // console.log(graphData);
    if (
      typeof graphData == "object" &&
      graphData !== null &&
      graphData.hasOwnProperty("labels") &&
      graphData.labels.length > 0
    ) {
      return (
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Month"
              format="MM"
              views={["month"]}
              disableFuture={true}
              onChange={handleMonthChange}
              sx={{ zIndex: 1 }}
            />
          </LocalizationProvider>
          {enBtuGraphLoading ? (
            <div style={{ padding: "2rem" }}>
              <GraphSkeleton />
            </div>
          ) : (
            <div className="chart_container">
              <div>
                <AreaChart
                  data={graphData}
                  enBtu={true}
                  temp_flow={false}
                  cooling_energy={false}
                  temp={false}
                  ChWP={false}
                  heading=""
                />
              </div>
            </div>
          )}
        </div>
      );
    } else if (!graphData.labels.length || !graphData.data.length) {
      return (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Month"
              format="MM"
              views={["month"]}
              disableFuture={true}
              onChange={handleMonthChange}
              sx={{ zIndex: 1 }}
            />
          </LocalizationProvider>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ mt: "30px" }}
          >
            <Box>
              <IconButton color="primary">
                <Error sx={{ fontSize: "90px", color: "red" }} />
              </IconButton>
            </Box>
            <Typography>No Data Available</Typography>
          </Stack>
        </>
      );
    } else {
      return (
        <Stack justifyContent="center" alignItems="center">
          <Box>
            <IconButton color="primary">
              <Engineering sx={{ fontSize: "110px" }} />
            </IconButton>
          </Box>
          <Typography>BTU Meter is not installed at this site</Typography>
        </Stack>
      );
    }
  };
  const handleMonthChange = (newValue) => {
    let date =
      newValue["$y"] + "-0" + (newValue["$M"] + 1) + "-" + newValue["$D"];
    const month = newValue["$M"] + 1;
    // fetchEnBtuData(btuDeviceInfo.branchId, btuDeviceInfo.deviceId, month);
  };
  return (
    <Grid item xl={12} lg={12} md={12} xs={12}>
      <div className="enbtu_container">
        <Paper sx={{ padding: "20px" }} elevation={0}>
          {/* <EnBtuGraph
          handleMonthChange={handleMonthChange}
          graphData={graphData}
          enBtuGraph={enBtuGraph}
        /> */}
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            sx={{ marginBottom: "12px" }}
          >
            <Box>
              <Hvac sx={{ color: "#344853", fontSize: "30px" }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#344a53",
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                BTU
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ color: "gray" }}
              >
                Daily Consumption
              </Typography>
            </Box>
          </Stack>

          <Typography>No Data Available</Typography>
        </Paper>
      </div>
    </Grid>
  );
};

export default EnBtu;
