import { useContext, createContext, useState } from "react";

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [mode, setMode] = useState("light");
  const [selected, setSelected] = useState(0); // first menu
  const [selectedTwo, setSelectedTwo] = useState(null); // second menu

  return (
    <UserContext.Provider
      value={{
        mode,
        setMode,
        selected,
        setSelected,
        selectedTwo,
        setSelectedTwo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext); // Move useContext inside a custom Hook

export default UserContextProvider;
