import { useContext, createContext, useState, useEffect } from "react";
import {
  getAhuOptimizationStatus,
  getCustomerBySchema,
  getEnBtuData,
  updateCustomerData,
} from "../utils/Api";
import moment from "moment-timezone";
import { useAuthContext } from "./AuthContext";
import { useAdmin } from "./AdminContext";
import { SCHEMA_STATUS } from "../utils/constants";

import { useIaqContext } from "./IaqContext";
import { useBtuContext } from "./BtuContext";
import { useAhuContext } from "./AhuContext";
import { setItemInLocalstorage } from "../utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const DataContext = createContext();

const DataContextProvider = ({ children }) => {
  const { isLoggedIn, isAdmin } = useAuthContext();
  const { selectedClient } = useAdmin();
  const [schema, setSchema] = useState(null);
  const [btu, setBtu] = useState(null);

  const [schemaStatus, setSchemaStatus] = useState(SCHEMA_STATUS[0]);
  const [pilotMode, setPilotMode] = useState(false);
  const [pilotModeUpdateStatus, setPilotModeUpdateStatus] = useState("");

  // the cards state holds the last hour btu consumption, savings, ahu optimization status

  const [optimizationStatus, setOptimizationStatus] = useState("NA");

  const [enBtuGraph, setEnBtuGraph] = useState({
    labels: "",
    data: "",
    month: "",
  });
  const [enBtuGraphLoading, setEnBtuGraphLoading] = useState(false);
  const [isClientViewRestricted, setIsClientViewRestricted] = useState(false);
  const { calculateAvgIaqOfCurrentHour } = useIaqContext();
  const { calculateBtuLastHourConsumptionOfAllDevices } = useBtuContext();
  const { calculateAhuSavings } = useAhuContext();
  const navigate = useNavigate();

  const fetchSchema = async (id) => {
    try {
      const { data: schemaResponse } = await getCustomerBySchema(id);
      if (
        schemaResponse &&
        schemaResponse?.type == "error" &&
        schemaResponse?.title == "Token is invalid"
      ) {
        let identifier = localStorage.getItem("identifier");
        if (!identifier) {
          identifier = "clairco";
        }
        return navigate(`/${identifier}/sessiontimeout`);
      }
      if (
        schemaResponse &&
        schemaResponse !== "No Customer found with this Identifier !!" &&
        Array.isArray(schemaResponse)
      ) {
        localStorage.setItem("schema", JSON.stringify(schemaResponse));
        const name = schemaResponse?.[0].Name || "";
        const city = schemaResponse?.[0].city || "";
        setSchema(schemaResponse);
        setSchemaStatus(SCHEMA_STATUS[1]);
        localStorage.setItem("clientName", `${name}, ${city}`);
        const installationType =
          schemaResponse[0].metaData?.installation_type || "agile";
        setItemInLocalstorage("installationType", installationType);
      } else {
        setSchemaStatus(SCHEMA_STATUS[2]);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        // toast.warning(
        //   "You do not have enough permissions to perform this action.",
        // );
        setIsClientViewRestricted(true);
      }
      throw error;
    }
  };

  const fetchEnBtuData = async (branchId, deviceId, month) => {
    try {
      if (!branchId || !deviceId || !month) {
        throw new Error("Inputs are missing");
      }
      setEnBtuGraphLoading(true);
      const btuResponse = await getEnBtuData(branchId, deviceId, month);
      if (btuResponse?.code !== 400) {
        const { data_set } = btuResponse;
        let labels = data_set.map((data) => data.day_dt);
        let data = data_set.map((btu) => btu.day_kwh);
        let en_month = moment(month, "M").format("MMMM");
        setEnBtuGraph({ labels, data, month: en_month });
        setEnBtuGraphLoading(false);
      } else {
        setBtu(null);
        setEnBtuGraphLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateOptimizationStatus = (optimizationStatus) => {
    // console.log("received  optimization status ", optimizationStatus);
    if (optimizationStatus == "1") setOptimizationStatus(`ON`);
    if (optimizationStatus == "0") setOptimizationStatus(`OFF`);
    return;
  };

  const fetchBranchOptimizationStatus = async (branchId) => {
    try {
      const ahuOptimization = await getAhuOptimizationStatus(branchId);
      // console.log("received optimization status");
      if (ahuOptimization?.optimizationStatus) {
        // console.log("updating optimization status");
        updateOptimizationStatus(optimizationStatus);
        // console.log("updated optimization status");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePilotMode = async (schema, status) => {
    let payload = {
      schema: schema,
      type: "metaData",
      data: {
        column: "pilotMode",
        data: {
          pilotMode: status,
        },
      },
    };
    try {
      const { data: updateStatus } = await updateCustomerData(payload);
      setPilotModeUpdateStatus(updateStatus);
      return;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (isLoggedIn && isAdmin == false) {
      const schema = localStorage.getItem("identifier");
      fetchSchema(schema);
    }
  }, [isLoggedIn, isAdmin]);

  useEffect(() => {
    const fetchAgileData = async () => {
      if (schema && typeof schema !== String) {
        try {
          const branchId = schema[0]?.metaData?.branch_id;
          // console.log("client useeffect executing...");
          calculateBtuLastHourConsumptionOfAllDevices();

          const ahus = schema[0].metaData.devices.ahus;
          const data = ahus.map((ahu) => {
            return {
              branchId,
              deviceId: ahu.device_id,
            };
          });
          calculateAhuSavings(data);
          calculateAvgIaqOfCurrentHour(schema);
        } catch (error) {
          console.log(error);
        }
      }
    };
    if (isAdmin) return;
    fetchAgileData();
  }, [schema]);

  // The below useeffect runs for admin when a specific client is selected to view a Client Dashboard

  useEffect(() => {
    const getSelectedClientData = async () => {
      if (!isAdmin) return;
      if (!selectedClient) return;
      try {
        // console.log("selected client => ", selectedClient);
        await fetchSchema(selectedClient);
        let schema = JSON.parse(localStorage.getItem("schema"));
        if (!schema) return;
        const branchId = schema[0]?.metaData?.branch_id;
        // console.log("admin useeffect executing...");
        calculateBtuLastHourConsumptionOfAllDevices();
        const ahus = schema[0].metaData.devices.ahus;
        const data = ahus.map((ahu) => {
          return {
            branchId,
            deviceId: ahu.device_id,
          };
        });
        calculateAhuSavings(data);
        calculateAvgIaqOfCurrentHour(schema);
      } catch (error) {
        console.log("Error in getting the selected client data ", error);
      }
    };

    getSelectedClientData();
  }, [selectedClient]);

  return (
    <DataContext.Provider
      value={{
        isAdmin,
        enBtuGraphLoading,
        schemaStatus,
        enBtuGraph,
        fetchEnBtuData,
        setEnBtuGraph,
        pilotMode,
        updatePilotMode,
        pilotModeUpdateStatus,
        isClientViewRestricted,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext); // Move useContext inside a custom Hook

export default DataContextProvider;
