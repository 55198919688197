import React, { useState } from "react";
import "./forgotpassword.css";
import { useLocation } from "react-router-dom";
import { forgotPassword } from "../../utils/Api";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !email.trim().length) {
      return setMessage("Please enter a valid email address.");
    }
    try {
      const schema = location.state;
      const { data: response } = await forgotPassword(email, schema);
      setMessage(
        `A password reset link has been sent to ${email}. You can close this page now, or the page will close automatically in 5 seconds.`,
      );
      setEmail("");
      setTimeout(() => {
        window.close();
      }, 5000);
    } catch (error) {
      console.log(error);
      setMessage(
        "There is an error while sending the mail. Please try again later.",
      );
    }
  };
  return (
    <>
      <img src={require("../../images/Clairco.png")} className="company_logo" />
      <div className="forgot-password-outer-container">
        <div className="forgot-password-inner-container">
          <img src={require("../../images/lock.png")} className="lock-icon" />
          <form className="forgot-password-form" onSubmit={handleSubmit}>
            <h2>Trouble Logging In?</h2>
            <p>Enter your email address to receive a password reset link.</p>
            <label htmlFor="email" id="forgot_password_label">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <button type="submit">Continue</button>
            {message && <p className="message">{message}</p>}
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
