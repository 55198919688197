import {
  Card,
  Paper,
  Typography,
  Box,
  CardContent,
  Stack,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AccessTime } from "@mui/icons-material";
import { useAuthContext } from "../../context/AuthContext";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import moment from "moment";
import { FONT_FAMILIES } from "../../utils/constants";

const convertTimestamp = (timestamp) => {
  if (moment(timestamp).isValid()) {
    const date = moment(timestamp);
    const formattedDate = date.format("DD MMM, YYYY");
    // console.log(formattedDate); // Output: 24 Mar, 2024
    return formattedDate;
  } else {
    return "Invalid date";
  }
};

const ChillerDetails = () => {
  const location = useLocation();
  const { setBreadcrumbs } = useAuthContext();
  const schema = useParams().schema;
  const [chillerData, setChillerData] = useState([]);

  useEffect(() => {
    setBreadcrumbs([
      { path: `/${schema}/chiller/list`, breadcrumbName: "Chiller List" },
      {
        path: "/chiller/details",
        breadcrumbName: "Chiller Details",
      },
    ]);
  }, []);

  useEffect(() => {
    if (!location?.state?.data?.length) return;
    const chillerArray = location.state.data.map((chiller) => {
      return {
        parameter: chiller?.key ?? "Not Available",
        value:
          chiller?.values?.[0].value == 0
            ? "OFF"
            : chiller?.values?.[0].value == 1
            ? `ON`
            : "UNKNOWN",
        time: convertTimestamp(chiller?.values?.[0]?.ts) ?? "UNKNOWN",
      };
    });
    setChillerData(chillerArray);
  }, [location.state]);

  return (
    <Paper elevation={0} sx={{ p: "20px" }}>
      <CustomBreadcrumb />
      <br />
      <br />
      <Typography
        sx={{
          textAlign: "left",
          color: "#272829",
          fontWeight: "bold",
          fontSize: {
            sm: "22px",
            xs: "16px",
          },
        }}
      >
        Chiller - {location.state?.entityId ?? "Not Available"}
      </Typography>
      <br />
      <Grid container spacing={2}>
        {chillerData.map((chiller, index) => {
          return (
            <Grid item md={4} xs={12} sm={4} lg={3}>
              <Card
                elevation={0}
                sx={{ width: "100%", backgroundColor: "#ebf5f0" }}
                key={index}
              >
                <CardContent>
                  <Typography
                    sx={{
                      color: "#008675",
                      fontWeight: "bold",
                      fontSize: "16px",
                      textAlign: "left",
                      wordWrap: "break-word",
                    }}
                    gutterBottom
                  >
                    {chiller.parameter}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    {chiller.value.toString().toUpperCase()}
                  </Typography>
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    gap={1}
                  >
                    <AccessTime
                      sx={{
                        fontSize: "14px",
                        color: "#272829",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#272829",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      {chiller.time}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

export default ChillerDetails;
