import {
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDevice } from "../../../context/DeviceContext";
import "./Device.css";
import DeviceGraph from "../../../components/charts/DeviceGraph";
import moment from "moment";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { Refresh } from "@mui/icons-material";
const formatDate = (date) => {
  const originalDate = date;
  const dateObject = new Date(originalDate);
  const options = {
    hour: "numeric",
    minute: "numeric",
    month: "short",
    day: "numeric",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObject,
  );
  return formattedDate;
};

const DeviceHistory = () => {
  const [latestData, setLatestData] = useState({
    status: null,
    error: null,
    dataKeys: null,
    dataValues: null,
    lastUpdatedTime: null,
    loading: false,
  });
  const location = useLocation();
  const [refetchLatestData, setRefetchLatestData] = useState(false);
  const { fetchLatestDeviceData, fetchHistoricalDeviceData, setBreadcrumbs } =
    useDevice();
  const { deviceId } = location.state;
  const [lastUpdatedTime, setLastUpdatedTime] = useState(null);
  const [showHistoricalData, setShowHistoricalData] = useState(false);
  const [historicalData, setHistoricalData] = useState({
    error: null,
    loading: false,
    data: null,
    // startDate: moment().format("YYYY-MM-DD"),
    // endDate: moment().format("YYYY-MM-DD"),
    interval: "12 hours",
  });
  const [graphData, setGraphData] = useState(null);
  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (location.state && localStorage.getItem("token")) {
      setLatestData((prevData) => ({ ...prevData, loading: true }));
      fetchLatestDeviceData(location.state.deviceId).then((response) => {
        // console.log(response);
        if (!response || response.status == 404) {
          setLatestData((prevData) => ({
            ...prevData,
            loading: false,
            status: 404,
            error: !Array.isArray(response?.msg)
              ? `No Live Data Available for ${location.state.deviceId}`
              : "Error occurred while fetching the data for " +
                location.state.deviceId,
          }));
          return;
        }

        if (response.status == 200) {
          const keys = Object.keys(response.msg.metaData);
          const values = Object.values(response.msg.metaData);
          setLastUpdatedTime(
            moment(response.msg.updateDateAndTime).format("h:mm A, D MMM"),
          );
          setLatestData((prevData) => ({
            ...prevData,
            loading: false,
            error: null,
            status: 200,
            dataKeys: ["Device ID", ...keys],
            dataValues: [deviceId, ...values],
            lastUpdatedTime: moment(response.msg.updateDateAndTime).format(
              "h:mm A, D MMM",
            ),
          }));
        }
      });
    }
  }, [refetchLatestData]);

  useEffect(() => {
    setBreadcrumbs([
      { path: "/device", breadcrumbName: "Device" },
      { path: "/device/details", breadcrumbName: "Details" },
      { path: "/device/data", breadcrumbName: "Latest & Historical Data" },
    ]);
  }, [location]);

  const getHistoricalDeviceData = () => {
    if (!localStorage.getItem("token")) return;
    setHistoricalData((prevData) => ({ ...prevData, loading: true }));
    fetchHistoricalDeviceData(
      location.state.deviceId,
      // historicalData.startDate,
      // historicalData.endDate,
      historicalData.interval,
    ).then((response) => {
      // console.log(response);
      if (!response) {
        setHistoricalData((prevData) => ({
          ...prevData,
          loading: false,
          error:
            "Error occurred while fetching the data for " +
            location.state.deviceId,
        }));
        return;
      }

      if (!response.msg.length) {
        setHistoricalData((prevData) => ({
          ...prevData,
          loading: false,
          error: "No Historical Data Available for " + location.state.deviceId,
        }));
        return;
      }

      const data = response.msg;

      // Initialize an object to store intermediate results
      const intermediateResult = {};

      // Iterate over the data and transform it
      data.forEach((item) => {
        Object.entries(item.metaData).forEach(([key, value]) => {
          if (!intermediateResult[key]) {
            intermediateResult[key] = {
              label: key,
              values: [],
              labels: [],
            };
          }

          intermediateResult[key].values.push(value);
          intermediateResult[key].labels.push(
            formatDate(item.updatedateandtime),
          );
        });
      });

      // Convert the intermediate result object to an array
      const formattedData = Object.values(intermediateResult);

      // console.log(formattedData);
      setGraphData(formattedData);

      setHistoricalData((prevData) => ({
        ...prevData,
        loading: false,
        data: response.msg,
        error: null,
      }));
    });
  };

  const renderLatestData = () => {
    if (latestData.loading) return <p>Loading...</p>;
    if (latestData.error) return <p>{latestData.error}</p>;
    if (latestData.status !== 200) {
      return (
        <Box sx={{ border: "1px solid #D4D4D8", borderRadius: "3px" }}>
          <h1 style={{ textAlign: "left", marginLeft: "20px" }}>Latest Data</h1>
          <Box
            sx={{
              height: "200px",
              maxHeight: "auto",
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <img
              src={require("../../../images/error.png")}
              style={{ height: "100px" }}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              No Data Found for {deviceId}
            </Typography>
            <Typography variant="subtitle2">
              We're sorry, but there was an error while fetching the data.
              Please try again later.
            </Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Box>
        <Stack direction="row" alignItems="center">
          <Typography sx={{ textAlign: "left", paddingRight: "1px" }}>
            Latest Data
          </Typography>
        </Stack>
        {latestData.lastUpdatedTime && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ mb: "8px" }}
          >
            <Typography sx={{ textAlign: "right", fontSize: "14px" }}>
              Last Updated - {latestData.lastUpdatedTime}
            </Typography>
            <Tooltip title="Refresh">
              <Refresh
                sx={{ cursor: "pointer", color: "#008675" }}
                onClick={() => setRefetchLatestData(!refetchLatestData)}
              />
            </Tooltip>
          </Stack>
        )}

        <div
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <table>
            <thead>
              <tr>
                {latestData.dataKeys.map((head, index) => (
                  <td key={index}>{head}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {latestData.dataValues.map((data, index) => (
                  <td key={index}>{data}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    );
  };

  const renderHistoricalData = () => {
    if (historicalData.loading) return <p>Loading...</p>;
    if (historicalData.error) return <p>{historicalData.error}</p>;
    if (graphData) {
      return (
        <div>
          <div className="date_picker_container">
            {/* <div className="date_picker">
              <label>Start Date</label>
              <input
                type="date"
                value={historicalData.startDate}
                max={getCurrentDate()}
                onChange={(e) => {
                  // console.log(historicalData);
                  setHistoricalData((prevData) => ({
                    ...prevData,
                    startDate: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="date_picker">
              <label>End Date</label>
              <input
                type="date"
                value={historicalData.endDate}
                min={historicalData.startDate}
                max={getCurrentDate()}
                onChange={(e) => {
                  // console.log(historicalData);
                  setHistoricalData((prevData) => ({
                    ...prevData,
                    endDate: e.target.value,
                  }));
                }}
              />
            </div> */}
            <div className="date_picker">
              <label>Select Time Period</label>
              <select
                value={historicalData.interval}
                onChange={(e) => {
                  // console.log(e.target.value);
                  setHistoricalData((prevData) => ({
                    ...prevData,
                    interval: e.target.value,
                  }));
                }}
              >
                <option value="12 hours">Last 12 hours</option>
                <option value="1 day">Last 24 hours</option>
                <option value="3 days">Last 3 days</option>
                <option value="7 days">Last 7 days</option>
              </select>
            </div>
            <div className="search_btn_container">
              <button className="search_btn" onClick={getHistoricalDeviceData}>
                Search
              </button>
            </div>
          </div>
          <Grid container spacing={3} sx={{ mt: "20px" }}>
            {graphData
              .filter((item) => item.label !== "mode")
              .map((item, index) => (
                <Grid item sm={6} xs={12} key={index}>
                  <DeviceGraph
                    label={item.label}
                    values={item.values}
                    labels={item.labels}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      );
    }
  };

  return (
    <Paper sx={{ p: "20px" }}>
      <CustomBreadcrumb />
      <Box sx={{ mt: "20px" }}>{renderLatestData()}</Box>
      <Box sx={{ mt: "20px" }}>
        {!showHistoricalData && (
          <button
            onClick={() => {
              setShowHistoricalData(true);
              getHistoricalDeviceData();
            }}
          >
            View Historical Data
          </button>
        )}
        {showHistoricalData && renderHistoricalData()}
      </Box>
    </Paper>
  );
};

export default DeviceHistory;
