import axios from "axios";
import config from "./config";

export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_PROD_BASEURL,
  baseURL: config.apiUrl,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "api-key": process.env.REACT_APP_API_KEY,
    };
    // config.timeout = 10000;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

/*******************  Admin Routes       *********************/

export const getCustomers = async () => {
  return axiosInstance({
    method: `POST`,
    url: "admin/getAllCustomers",
    data: {
      dataType: false,
    },
  });
};

export const getCustomerBySchema = async (schema) => {
  return axiosInstance({
    method: `POST`,
    url: "getCustomerBySchema",
    data: {
      identifier: schema,
    },
  });
};

export const getUsersBySchema = async (schema) => {
  return axiosInstance({
    method: `POST`,
    url: "admin/fetchUsers",
    data: {
      schema,
    },
  });
};

export const addUserToCustomer = async (payload) => {
  return axiosInstance({
    method: `POST`,
    url: "admin/insertUser",
    data: payload,
  });
};

export const addCustomer = async (payload) => {
  return axiosInstance({
    method: `POST`,
    url: "admin/createCustomer",
    data: payload,
  });
};

export const updateCustomerData = async (payload) => {
  return axiosInstance({
    method: `POST`,
    url: "admin/updateCustomer",
    data: {
      schema: payload["schema"],
      updateType: payload["type"],
      updateData: payload["data"],
    },
    timeout: 10000,
  });
};

/*******************  Agile Routes  ******************* */

export const getEnergyReport = async (branchId, schema, fromDate, toDate) => {
  try {
    const { data } = await axiosInstance({
      method: `POST`,
      url: "generateReport",
      data: {
        branchId,
        schema,
        fromDate,
        toDate,
        type: "energyReport",
      },
      responseType: "blob",
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getBtuData = async (payload) => {
  return axiosInstance({
    method: `POST`,
    url: "fetchBtuData",
    data: payload,
    timeout: 10000,
  });
};

export const getAhuOptimizationStatus = async (branchId) => {
  try {
    const { data } = await axiosInstance({
      method: `POST`,
      url: "fetchBranchOptimizationStatus",
      data: {
        branchId,
      },
      timeout: 5000,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getEnBtuData = async (branchId, deviceId, month) => {
  try {
    const { data } = await axiosInstance({
      method: `POST`,
      url: "fetchEnBtuData",
      data: {
        branchId,
        deviceId,
        enYear: "2024",
        enMonth: month,
      },
      timeout: 10000,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAhuData = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: `POST`,
      url: "fetchAhuData",
      data: payload,
      timeout: 30000,
    });
    return {
      branchId: payload?.branchId || "",
      deviceId: payload?.deviceId || "",
      data,
    };
  } catch (error) {
    return null;
  }
};

export const getIAQData = async (payload) => {
  return axiosInstance({
    method: "POST",
    url: "fetchIaqData",
    data: payload,
    timeout: 10000,
  });
};

/*****************  Schedules Routes **********************/

export const getSchedules = async (payload) => {
  return axiosInstance({
    method: `POST`,
    url: "schedule/fetch",
    data: payload,
  });
};

export const addSchedule = async (payload) => {
  return axiosInstance({
    method: `POST`,
    url: "schedule/insert",
    data: payload,
    timeout: 10000,
  });
};

/********************* Device Routes **********************/

export const getDevices = async (payload) => {
  return axiosInstance({
    method: `POST`,
    url: "device",
    data: payload,
  });
};

export const editDevice = async (payload) => {
  return axiosInstance({
    method: `POST`,
    url: "device/update",
    data: payload,
    timeout: 10000,
  });
};

export const getLatestDeviceData = async (deviceId) => {
  return axiosInstance({
    method: `POST`,
    url: "device/fetch",
    data: {
      deviceId,
      fetchType: "latest",
    },
    timeout: 10000,
  });
};

export const getHistoricalDeviceData = async (deviceId, interval) => {
  return axiosInstance({
    method: `POST`,
    url: "device/fetch",
    data: {
      deviceId,
      fetchType: "history",
      interval,
    },
    timeout: 30000,
  });
};

export const editMultiDeviceData = async (deviceId, queryCon) => {
  return axiosInstance({
    method: `POST`,
    url: "device/update",
    data: {
      flag: "multi",
      deviceId,
      queryCon,
    },
    timeout: 30000,
  });
};

export const controlDevice = async (payload) => {
  return axiosInstance({
    method: "POST",
    url: "device/control",
    data: payload,
    timeout: 20000,
  });
};

/******************** Microservice status *********/

export const getHeartBeatList = async (payload) => {
  return axiosInstance({
    method: `GET`,
    url: "admin/tech/microsevice",
    data: payload,
    timeout: 30000,
  });
};

/******************* User routes  *******************/

export const login = async (email, password, schema) => {
  try {
    const { data } = await axios.post(config.loginUrl, {
      email,
      password,
      schema,
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const changeUserPassword = async (
  id,
  schema,
  oldPassword,
  newPassword,
) => {
  return axiosInstance({
    method: `PUT`,
    url: "changePassword",
    data: {
      id,
      schema,
      oldPassword,
      newPassword,
    },
    timeout: 10000,
  });
};

export const verifyEmail = async (token) => {
  return axiosInstance({
    method: `POST`,
    url: "verifyUser",
    data: {
      token,
    },
    timeout: 10000,
  });
};

export const reIssueToken = async (token) => {
  return axiosInstance({
    method: `POST`,
    url: "reIssueToken",
    data: {
      token,
    },
    timeout: 10000,
  });
};

export const forgotPassword = async (email, schema) => {
  return axiosInstance({
    method: `POST`,
    url: "forgotPassword",
    data: {
      email,
      schema,
    },
    timeout: 10000,
  });
};

export const resetPassword = async (token, newPassword) => {
  return axiosInstance({
    method: `POST`,
    url: "resetPassword",
    data: {
      token,
      newPassword,
    },
    timeout: 10000,
  });
};

export const fetchAllRoles = async () => {
  return axiosInstance({
    method: `POST`,
    url: "fetchRole",
    data: {
      roleName: "ViewOnlyAccess",
      fetchType: "all",
    },
    timeout: 10000,
  });
};

export const updateUser = async (payload) => {
  return axiosInstance({
    method: `PUT`,
    url: "updateUser",
    data: payload,
    timeout: 10000,
  });
};

/**********   CHILLER ROUTES *****************/

export const fetchChillerData = async (payload) => {
  return axiosInstance({
    method: `POST`,
    url: "fetchChillerData",
    data: payload,
    timeout: 10000,
  });
};
