import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import errorImage from "../../images/error.png";
import { COLORS, FONT_FAMILIES } from "../../utils/constants";

const DataNotFound = () => {
  return (
    <Paper elevation={0}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent={"center"}
        sx={{ height: { xs: "80vh", sm: "" } }}
      >
        <img src={errorImage} style={{ width: "300px", display: "block" }} />
        <Typography
          sx={{
            fontSize: { md: "50px", xs: "18px" },
            color: COLORS.BLACK_SHADE,
            fontFamily: FONT_FAMILIES.BEBAS_NEUE,
          }}
        >
          Data Not Available!
        </Typography>
      </Stack>
    </Paper>
  );
};

export default DataNotFound;
