import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { DEVICE_TYPES } from "../../../../utils/constants";
import { Alert, Box, Button, Grid, Paper } from "@mui/material";
import { useDevice } from "../../../../context/DeviceContext";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import { toast } from "react-toastify";

const EditDeviceLimits = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitting },
    reset,
    watch,
    getValues,
  } = useForm();
  const location = useLocation();
  const [thresholdArray, setThresholdArray] = useState([]);
  const [thresholdObj, setThresholdObj] = useState(null);
  const {
    id,
    installation_details,
    installed_on,
    profile,
    provisoned_on,
    set_limits,
    set_schema,
    updated_at,
    metaData,
  } = location.state;
  const { updateDevice, fetchDevices, setFilteredItems, setBreadcrumbs } =
    useDevice();
  const [apiResponse, setApiResponse] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbs([
      { path: "/device", breadcrumbName: "Device" },
      { path: "/device/details", breadcrumbName: "Details" },
      { path: "/device/edit/limits", breadcrumbName: "Update Device Limits" },
    ]);
  }, [location]);

  useEffect(() => {
    try {
      const validJsonStrofSetLimits = location.state?.set_limits?.replace(
        /'/g,
        '"',
      );
      if (!validJsonStrofSetLimits) {
        throw new Error(
          "Invalid Set Limits or No  Set Limits provided for this device.",
        );
      }
      // console.log(validJsonStrofSetLimits);
      const parsedSetLimits = JSON.parse(validJsonStrofSetLimits);
      const setLimitsObj = Object.fromEntries(
        Object.entries(parsedSetLimits).map(([key, value]) => [
          key,
          JSON.parse(value),
        ]),
      );
      setThresholdObj(setLimitsObj);
      const setLimitsArr = Object.entries(setLimitsObj);
      setThresholdArray(setLimitsArr);
    } catch (error) {
      console.log(error);
      // console.log("error name ", error.name);
      toast.error(
        "Invalid Set Limits or No  Set Limits provided for this device",
      );
    }
  }, []);
  const validateMinMax = (label) => (value, values) => {
    const minLabel = `Min ${label}`;
    const minValue = values[minLabel];
    const maxValue = value;

    if (
      isSubmitted &&
      minValue &&
      maxValue &&
      parseInt(maxValue) < parseInt(minValue)
    ) {
      return `Max ${label} must be greater than Min ${label}`;
    }

    return true;
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      const pollutantList = Object.keys(thresholdObj);

      const formData = {
        deviceId: id,
      };

      let set_limits = {};

      const setPollutantLimits = (pollutant) => {
        if (pollutantList.includes(pollutant)) {
          let minValue = parseInt(data[`Min ${pollutant}`]);
          let maxValue = parseInt(data[`Max ${pollutant}`]);
          set_limits[pollutant] = [minValue, maxValue];
          formData.set_limits = set_limits;
        }
      };

      if (
        metaData.device_type !== DEVICE_TYPES.switch ||
        metaData.device_type !== DEVICE_TYPES.ahu
      ) {
        if (pollutantList.includes("CO2")) {
          setPollutantLimits("CO2");
        }
        if (pollutantList.includes("VOC")) {
          setPollutantLimits("VOC");
        }
        if (pollutantList.includes("PM2_5")) {
          setPollutantLimits("PM2_5");
        }
        if (pollutantList.includes("PM10")) {
          setPollutantLimits("PM10");
        }
        if (pollutantList.includes("HUM")) {
          setPollutantLimits("HUM");
        }
        if (pollutantList.includes("rssi")) {
          setPollutantLimits("rssi");
        }
        if (pollutantList.includes("dp")) {
          setPollutantLimits("dp");
        }
      }
      // console.log(formData);
      let updated_limits = formData.set_limits;
      let formattedString = "{";
      for (const key in updated_limits) {
        if (updated_limits.hasOwnProperty(key)) {
          formattedString += `'${key}':'[${updated_limits[key].join(",")}]',`;
        }
      }

      // Remove the trailing comma and close the string
      formattedString = formattedString.slice(0, -1) + "}";

      const response = await updateDevice({
        deviceId: formData.deviceId,
        queryCol: "set_limits",
        queryCon: formattedString,
      });

      // console.log(response);
      if (response) {
        setApiResponse("Device has been successfully updated");
        fetchDevices();
        setFilteredItems([]);
        setTimeout(() => {
          navigate("/device");
        }, 3000);
      } else {
        setApiResponse(
          "An error occurred while editing device. Please try again later",
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "An error occurred while updating the device limits. Please try again later.",
      );
    }
  };

  return (
    <Paper sx={{ p: "20px" }}>
      <CustomBreadcrumb />
      <Box component={"div"} mt={1}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ mt: "0px" }}>
            {thresholdArray.map(([label, values], index) => (
              <Grid item sm={3} xs={12} key={index}>
                <div key={label} style={{ width: "100%", border: "" }}>
                  <label>{`Min ${label} `}</label>
                  <input
                    type="number"
                    defaultValue={values[0]}
                    disabled={isSubmitting}
                    {...register(`Min ${label}`, {
                      required: true,
                      // validate: validateMinMax(label),
                    })}
                  />
                  {errors[`Min ${label}`] && (
                    <span style={{ fontSize: "10px" }}>
                      {errors[`Min ${label}`].message}
                    </span>
                  )}

                  <label>{`Max ${label} `}</label>
                  <input
                    type="number"
                    defaultValue={values[1]}
                    disabled={isSubmitting}
                    {...register(`Max ${label}`, {
                      required: true,
                      validate: validateMinMax(label),
                    })}
                  />
                  {errors[`Max ${label}`] && (
                    <span style={{ fontSize: "12px" }}>
                      {errors[`Max ${label}`].message}
                    </span>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
          {Array.isArray(thresholdArray) && thresholdArray?.length > 0 && (
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Updating...." : "Update Device Limits"}
            </Button>
          )}
        </form>
        {apiResponse && (
          <Alert severity="info" sx={{ mt: 2 }}>
            {apiResponse}
          </Alert>
        )}
      </Box>
    </Paper>
  );
};

export default EditDeviceLimits;
