import React, { createContext, useContext, useState } from "react";

export const AuthContext = createContext();

const getLocalStorageItem = (key, defaultValue) => {
  const storedItem = localStorage.getItem(key);
  return storedItem ? JSON.parse(storedItem) : defaultValue;
};

const AuthContextProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [isAdmin, setIsAdmin] = useState(() =>
    getLocalStorageItem("admin", false),
  );
  const [isLoggedIn, setIsLoggedIn] = useState(() =>
    getLocalStorageItem("logged_in", false),
  );

  const updateAdminStatus = (inp) => setIsAdmin(inp);
  const updateLoginStatus = (inp) => setIsLoggedIn(inp);

  return (
    <AuthContext.Provider
      value={{
        isAdmin,
        isLoggedIn,
        setIsAdmin,
        setIsLoggedIn,
        breadcrumbs,
        setBreadcrumbs,
        updateAdminStatus,
        updateLoginStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContextProvider;
