import React, { useState } from "react";
import "./ResetPassword.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { resetPassword } from "../../utils/Api";
const ResetPassword = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !password ||
      !confirmPassword ||
      !password.trim().length ||
      !confirmPassword.trim().length
    ) {
      return setMessage("All fields are required.");
    }
    if (password !== confirmPassword) {
      return setMessage("Passwords do not match");
    }
    const token = searchParams.get("token");
    if (!token) {
      return;
    }
    try {
      const { data: response } = await resetPassword(token, password);
      setMessage(
        "Password successfully updated! You can close this page now, or the page will close automatically in 5 seconds.",
      );
      setPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        window.close();
      }, 5000);
    } catch (error) {
      console.log(error);
      setMessage(
        "An error occurred while resetting the password. Please try again later",
      );
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <>
      <img src={require("../../images/Clairco.png")} className="company_logo" />
      <div className="reset-password-outer-container">
        <div className="reset-password-inner-container">
          <img src={require("../../images/lock.png")} className="lock-icon" />
          <form className="reset-password-form" onSubmit={handleSubmit}>
            <h2>Reset Password</h2>
            <div className="form-input-box">
              <label htmlFor="password" id="reset_password_label">
                New Password
              </label>
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                required
                onChange={handlePasswordChange}
              />
              <span onClick={togglePasswordVisibility} className="icon-btn">
                {showPassword ? (
                  <VisibilityIcon color="black" />
                ) : (
                  <VisibilityOffIcon color="black" />
                )}
              </span>
            </div>
            <div className="form-input-box">
              <label htmlFor="conform-password" id="reset_password_label">
                Confirm Password
              </label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirm-password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              <span
                onClick={toggleConfirmPasswordVisibility}
                className="icon-btn"
              >
                {showConfirmPassword ? (
                  <VisibilityIcon color="black" />
                ) : (
                  <VisibilityOffIcon color="black" />
                )}
              </span>
            </div>

            <button type="submit">Reset Password</button>
            {message && <p className="message">{message}</p>}
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
