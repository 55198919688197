import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { useAdmin } from "../../../context/AdminContext";
import { COLORS, FONT_FAMILIES } from "../../../utils/constants";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useAuthContext } from "../../../context/AuthContext";
import moment from "moment";
import GraphSkeleton from "../../../components/loading_skeletons/GraphSkeleton";
import { getUsersBySchema } from "../../../utils/Api";

const TableHeadCell = ({ cellName }) => {
  return (
    <TableCell
      sx={{
        color: "white",
        fontWeight: "bold",
        fontSize: "14px",
        textAlign: "center",
      }}
    >
      {cellName}
    </TableCell>
  );
};

const formatTime = (timeString) => {
  const date = moment(timeString);
  if (date.isValid()) {
    return date.format("h:mm A, D MMM");
  } else {
    return "Not Available";
  }
};

const ViewUsers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tableHeadRowCells = ["Name", "Email", "Last Login", "Roles", "Actions"];
  // const { fetchData, data, error, isLoading, isError } = useAxios();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { roles } = useAdmin();
  const { setBreadcrumbs } = useAuthContext();
  const findRoleById = (roleId) => {
    const foundRole = roles.find((r) => r.id === roleId);
    if (!foundRole) return "";
    return foundRole.roleName;
  };

  // useeffect to fetch users data of a particular schema
  useEffect(() => {
    // fetchData({
    //   method: "POST",
    //   url: "admin/fetchUsers",
    //   data: {
    //     schema: location.state.schema,
    //   },
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     "api-key": process.env.REACT_APP_API_KEY,
    //   },
    // });
    getUsersBySchema(location.state.schema)
      .then(({ data: apiResponse }) => {
        setData(apiResponse);
      })
      .catch((error) => {
        setIsError(true);
        setError("Something went wrong! Please try again");
      });
  }, []);

  // useeffect to set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: "/customers", breadcrumbName: "Customers" },
      {
        path: "/customer/users",
        breadcrumbName: "Users",
      },
    ]);
  }, []);

  if (isLoading) {
    return (
      <Box>
        <GraphSkeleton />
      </Box>
    );
  }

  if (isError) {
    return (
      <Paper elevation={0} sx={{ p: 2 }}>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Box>
            <img
              src={require("../../../images/error.png")}
              style={{ width: "300px", display: "block" }}
            />
          </Box>
          <Typography variant="body1">{error}</Typography>
        </Stack>
      </Paper>
    );
  }

  const renderUserData = () => {
    if (
      !data ||
      !data.response ||
      !data.response.users ||
      data.response.users.length === 0
    ) {
      return (
        <TableRow>
          <TableCell colSpan={5} align="center">
            No user data available
          </TableCell>
        </TableRow>
      );
    }

    return data.response.users.map((user, index) => {
      const firstName = user.first_name
        ? capitalizeFirstLetter(user.first_name)
        : "";
      const lastName = user.last_name
        ? capitalizeFirstLetter(user.last_name)
        : "";
      return (
        <TableRow key={index}>
          <TableCell align="center">{`${firstName} ${lastName}`}</TableCell>
          <TableCell align="center">{user?.email ?? "Not Available"}</TableCell>
          <TableCell align="center">
            {formatTime(user?.last_login) ?? "Not Available"}
          </TableCell>
          <TableCell align="center">
            <div>
              {user?.metadata?.roles
                ? user.metadata.roles.map((role, roleIndex) => (
                    <div key={roleIndex}>
                      {findRoleById(role) || "Role Not Found"}
                    </div>
                  ))
                : "Roles Not Found"}
            </div>
          </TableCell>
          <TableCell align="center">
            <button
              onClick={() =>
                navigate(`/customer/users/${user.id}`, {
                  state: {
                    user,
                    schema: location.state?.schema,
                  },
                })
              }
            >
              Update
            </button>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Paper elevation={0} sx={{ padding: "24px" }}>
      <CustomBreadcrumb />
      <br />
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mb: "10px" }}
        gap={2}
      >
        <Box>
          <img
            src={require("../../../images/office-building.png")}
            style={{ width: "40px" }}
          />
        </Box>
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: 700,
            color: COLORS.BLACK_SHADE,
            fontSize: "25px",
            fontFamily: FONT_FAMILIES.BEBAS_NEUE,
            letterSpacing: "1px",
          }}
        >
          {location.state?.Name ?? ""}
        </Typography>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadRowCells.map((cellName, index) => {
                return <TableHeadCell cellName={cellName} key={index} />;
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderUserData()}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ViewUsers;
