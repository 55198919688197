import {
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  Select,
  Chip,
  MenuItem,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COLORS, FONT_FAMILIES } from "../../../utils/constants";
import { useAuthContext } from "../../../context/AuthContext";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import useAxios from "../../../hooks/useAxios";
import { useAdmin } from "../../../context/AdminContext";
import { toast } from "react-toastify";
import { axiosInstance, updateUser } from "../../../utils/Api";

const Input = ({
  label,
  readOnly = false,
  register,
  name,
  isApiResponseLoading,
  ...rest
}) => {
  return (
    <Grid item sm={4} xs={12}>
      <label style={{ color: "GrayText" }}>{label}</label>
      <input
        {...rest}
        style={{
          width: "100%",
          background: readOnly || isApiResponseLoading ? "gainsboro" : "",
          cursor: readOnly || isApiResponseLoading ? "not-allowed" : "",
        }}
        readOnly={readOnly}
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        {...register(name, {})}
      />
    </Grid>
  );
};

const UpdateUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setBreadcrumbs } = useAuthContext();
  // const {
  //   fetchData,
  //   data: apiResponse,
  //   isLoading: isApiResponseLoading,
  //   isError,
  //   error,
  // } = useAxios();
  const [isChecked, setIsChecked] = useState(false);
  const { roles } = useAdmin();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isApiResponseLoading, setIsApiResponseLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();
  useEffect(() => {
    setBreadcrumbs([
      { path: "/customers", breadcrumbName: "Customers" },
      {
        path: "/customer/users",
        breadcrumbName: "Users",
      },
    ]);
  }, []);

  const findRoleById = (roleId) => {
    const foundRole = roles.find((r) => r.id === roleId);
    if (!foundRole) return "";
    return foundRole.roleName;
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    if (!location.state?.user?.metadata?.roles) return;
    const userRoles = location.state.user.metadata.roles.map((roleId) =>
      findRoleById(roleId),
    );
    setSelectedRoles(userRoles);
  }, [location]);

  const onSubmit = async (data) => {
    let payload;

    // without metadata

    if (!isChecked) {
      payload = {
        ...data,
        schema: location.state.schema,
        id: location.state.user.id,
      };
    }

    // with metadata

    if (isChecked) {
      const roleIds = selectedRoles
        .map((roleName) => {
          const role = roles.find((r) => r.roleName === roleName);
          return role ? role.id : null;
        })
        .filter(Boolean);

      if (!roleIds.length) {
        return toast.error("Please select at least one user role");
      }
      payload = {
        schema: location.state.schema,
        id: location.state.user.id,
        updateMetadata: true,
        ...data,
        updateData: {
          column: "roles",
          data: roleIds,
        },
      };
    }

    // fetchData({
    //   method: "PUT",
    //   url: "/updateUser",
    //   data: payload,
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     "api-key": process.env.REACT_APP_API_KEY,
    //   },
    // })
    //   .then((api) => {
    //     toast.success("User updated successfully");
    //   })
    //   .catch((error) => {
    //     toast.error(error);
    //   });

    setIsApiResponseLoading(true);

    updateUser(payload)
      .then(({ data: apiResponse }) => {
        console.log(apiResponse);
        setData(apiResponse);
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
        setError("Something went wrong! Please try again");
        toast.error("Something went wrong! Please try again later");
      })
      .finally(() => {
        setIsApiResponseLoading(false);
      });
  };

  return (
    <Paper sx={{ p: 3 }} elevation={0}>
      <CustomBreadcrumb />
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: "bold",
            color: "gray",
            fontFamily: FONT_FAMILIES.DMSANS,
          }}
          gutterBottom
        >
          Personal Info
        </Typography>
        <Grid container spacing={3}>
          <Input
            label="First Name"
            defaultValue={location.state?.user?.first_name ?? ""}
            name="first_name"
            register={register}
            disabled={isApiResponseLoading}
            isApiResponseLoading={isApiResponseLoading}
          />
          <Input
            label="Last Name"
            defaultValue={location.state?.user?.last_name ?? ""}
            name="last_name"
            register={register}
            isApiResponseLoading={isApiResponseLoading}
            disabled={isApiResponseLoading}
          />
          <Input
            label="Email"
            defaultValue={location.state?.user?.email ?? ""}
            readOnly={true}
            name="email"
            register={register}
            disabled={isApiResponseLoading}
            isApiResponseLoading={isApiResponseLoading}
          />
          <Input
            label="Phone Number"
            defaultValue={location.state?.user?.phone_number ?? ""}
            name="phone_number"
            register={register}
            required={true}
            disabled={isApiResponseLoading}
            isApiResponseLoading={isApiResponseLoading}
          />
        </Grid>
        <br />
        <Divider />
        <br />
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: "bold",
            color: "gray",
            fontFamily: FONT_FAMILIES.DMSANS,
          }}
          gutterBottom
        >
          Additional Info
        </Typography>

        <Grid container>
          <Grid item sm={8}>
            <div style={{ width: "100%" }}>
              <label style={{ color: "gray" }}>Roles</label>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selectedRoles}
                onChange={handleChange}
                renderValue={(selectedRoles) => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {selectedRoles.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Stack>
                )}
                MenuProps={MenuProps}
                sx={{
                  width: "100%",
                  cursor: isApiResponseLoading ? "not-allowed" : "",
                }}
                disabled={isApiResponseLoading}
              >
                {roles.map((role) => (
                  <MenuItem key={role.roleName} value={role.roleName}>
                    <Checkbox
                      checked={selectedRoles.indexOf(role.roleName) > -1}
                    />
                    <ListItemText primary={role.roleName} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
        </Grid>
        <br />
        <br />
        <Stack direction="row" alignItems="center" sx={{ mb: "5px" }}>
          <Checkbox
            sx={{
              color: COLORS.PRIMARY_COLOR,
              "&.Mui-checked": {
                color: COLORS.PRIMARY_COLOR,
              },
            }}
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <Typography sx={{ fontWeight: "bold", color: COLORS.BLACK_SHADE }}>
            Do you want to update additional info?
          </Typography>
        </Stack>
        <button
          type="submit"
          disabled={isApiResponseLoading ? true : false}
          style={{
            backgroundColor: isApiResponseLoading
              ? COLORS.GRAY_COLOR
              : COLORS.PRIMARY_COLOR,
            cursor: isApiResponseLoading ? "not-allowed" : "pointer",
            borderRadius: "4px",
          }}
        >
          {isApiResponseLoading ? "Updating User..." : "Update User"}
        </button>
      </form>
    </Paper>
  );
};

export default UpdateUser;
