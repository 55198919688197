import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DEVICE_TYPES } from "../../../utils/constants";
import TabularData from "../../../components/table/TabularData";
import { useSchedule } from "../../../context/ScheduleContext";
import { useDevice } from "../../../context/DeviceContext";
import "../devices/Device.css";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import UpdateAhu from "./UpdateAhu";
import CustomModal from "../../../components/modal/CustomModal";
import { toast } from "react-toastify";

const DeviceDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { scheduleList } = useSchedule();
  // console.log(scheduleList);
  const {
    updateDevice,
    fetchDevices,
    setFilteredItems,
    selectedDeviceDetails,
    setSelectedDeviceDetails,
    setBreadcrumbs,
  } = useDevice();
  const [id, setDeviceID] = useState(null);
  const [installation_details, setInstallationDetails] = useState(null);
  const [installed_on, setInstalled_on] = useState(null);
  const [profile, setProfile] = useState(null);
  const [provisoned_on, setProvisonedOn] = useState(null);
  const [set_limits, setSetLimits] = useState(null);
  const [set_schema, setSetSchema] = useState(null);
  const [updated_at, setUpdatedAt] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [deviceLimits, setDeviceLimits] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    setBreadcrumbs([
      { path: "/device", breadcrumbName: "Device" },
      { path: "/device/details", breadcrumbName: "Details" },
    ]);

    if (location?.state) {
      setDeviceID(location.state.id);
      setInstallationDetails(location.state.installation_details);
      setInstalled_on(location.state.installed_on);
      setProfile(location.state.profile);
      setProvisonedOn(location.state.provisoned_on);
      setSetLimits(location.state.set_limits);
      setSetSchema(location.state.set_schema);
      setUpdatedAt(location.state.updated_at);
      setMetaData(location.state.metaData);
      setSelectedDeviceDetails(location.state);
    }
    if (selectedDeviceDetails) {
      setDeviceID(selectedDeviceDetails.id);
      setInstallationDetails(selectedDeviceDetails.installation_details);
      setInstalled_on(selectedDeviceDetails.installed_on);
      setProfile(selectedDeviceDetails.profile);
      setProvisonedOn(selectedDeviceDetails.provisoned_on);
      setSetLimits(selectedDeviceDetails.set_limits);
      setSetSchema(selectedDeviceDetails.set_schema);
      setUpdatedAt(selectedDeviceDetails.updated_at);
      setMetaData(selectedDeviceDetails.metaData);
    }
  }, [location]);

  const handleDetachScheduler = async (scheduleId) => {
    // console.log(scheduleId);
    if (!metaData?.schedules?.length) return;
    const updatedscheduleList = metaData.schedules.filter(
      (id) => id !== scheduleId,
    );
    location.state.metaData.schedules = updatedscheduleList;
    const response = await updateDevice({
      deviceId: location.state.id,
      queryCol: "metaData",
      queryCon: location.state.metaData,
    });

    if (response) {
      // console.log(response);
      fetchDevices();
      setFilteredItems([]);
      toast.success("Schedule removed successfully!");
    } else {
      toast.error("Failed to remove the Schedule");
    }
  };

  const handleAttachScheduler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const selectedScheduleId = formData.get("scheduleId");
    const metaData = location.state?.metaData;
    if (!metaData) {
      return toast.error("Unable to add schedule to this device!");
    }
    let existingSchedules = metaData?.schedules ?? [];
    // console.log("Selected schedule ID:", selectedScheduleId);

    // console.log(existingSchedules.includes(selectedScheduleId));
    if (!existingSchedules.length) {
      existingSchedules = [selectedScheduleId];
    } else if (existingSchedules.includes(selectedScheduleId)) {
      return toast.warning("This Schedule is already attached to the Device.");
    } else {
      existingSchedules.push(selectedScheduleId);
    }
    location.state.metaData.schedules = existingSchedules;
    const response = await updateDevice({
      deviceId: location.state.id,
      queryCol: "metaData",
      queryCon: location.state.metaData,
    });
    if (response) {
      fetchDevices();
      setFilteredItems([]);
      handleCloseModal();
      toast.success("Schedule added successfully!");
    } else {
      toast.error("Unable to add schedule to this device. Please try again!");
    }
  };

  useEffect(() => {
    if (metaData?.device_type === "Switch") return;
    if (!set_limits || set_limits === "NA") return;
    if (set_limits) {
      const validJsonStrofSetLimits = set_limits.replace(/'/g, '"');
      const parsedSetLimits = JSON.parse(validJsonStrofSetLimits) || "";
      const formattedDeviceLimits = Object.entries(parsedSetLimits).map(
        ([key, value]) => {
          const [lowerLimit, upperLimit] = JSON.parse(value);
          return {
            key,
            lowerLimit,
            upperLimit,
          };
        },
      );
      setDeviceLimits(formattedDeviceLimits);
    }
  }, [set_limits]);

  const findScheduleName = (scheduleID) => {
    const schedule = scheduleList.find((schedule) => schedule.id == scheduleID);
    if (schedule) return schedule.name;
    return false;
  };

  const formatMetadata = () => {
    if (!metaData) return;
    if (metaData.device_type === DEVICE_TYPES.thSens) {
      if (!metaData.fetchDetails.length)
        return (
          <Box>
            <Typography
              variant="body2"
              gutterBottom
              color="darkgray"
              sx={{ textAlign: "left" }}
            >
              Device Type
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: 600, color: "#3F3F46", textAlign: "left" }}
            >
              {metaData.device_type}
            </Typography>
            {metaData?.schedules.length > 0 && (
              <Stack direction="column">
                <Typography
                  gutterBottom
                  color="darkgray"
                  sx={{ textAlign: "left" }}
                >
                  Schedule
                </Typography>
                {metaData.schedules.map((scheduleID) => (
                  <Stack>
                    <Typography
                      sx={{ fontWeight: 600, color: "#3F3F46", mr: "20px" }}
                    >
                      {findScheduleName(scheduleID)}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => handleDetachScheduler(scheduleID)}
                      sx={{
                        borderRadius: "25px",
                        padding: "5px 16px",
                        lineHeight: "1.5",
                        fontSize: "12px",
                      }}
                    >
                      Detach
                    </Button>
                  </Stack>
                ))}
              </Stack>
            )}
          </Box>
        );
      else {
        const { fetchDetails } = metaData;
        const keys = Object.keys(fetchDetails[0]);
        const values = fetchDetails.map((data) => Object.values(data));

        return (
          <>
            <Box sx={{ mt: "10px" }}>
              <Typography variant="body2" gutterBottom color="darkgray">
                Device Type
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: "#3F3F46" }}
              >
                {metaData.device_type}
              </Typography>
            </Box>
            <TabularData tableHeaders={keys} tableBodyData={values} />
            {metaData?.schedules?.length > 0 && (
              <Stack direction="column">
                <Typography
                  gutterBottom
                  color="darkgray"
                  sx={{ textAlign: "left" }}
                >
                  Schedule
                </Typography>
                {metaData.schedules.map((scheduleID) => (
                  <Stack direction="row" alignItems="center">
                    <Typography
                      sx={{ fontWeight: 600, color: "#3F3F46", mr: "20px" }}
                    >
                      {findScheduleName(scheduleID) ||
                        "Schedule Name Not Available"}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => handleDetachScheduler(scheduleID)}
                      sx={{
                        borderRadius: "25px",
                        padding: "5px 16px",
                        lineHeight: "1.5",
                        fontSize: "12px",
                      }}
                    >
                      Detach
                    </Button>
                  </Stack>
                ))}
              </Stack>
            )}
          </>
        );
      }
    }
    if (metaData.device_type === DEVICE_TYPES.dpt) {
      const data = Object.entries(metaData);
      if (!data.length) return;
      return (
        <>
          <Stack direction="row" width="100%" gap={5} flexWrap="wrap">
            {data.map((item, index) => (
              <Box key={index}>
                <Typography
                  variant="subtitle2"
                  color="darkgray"
                >{`${item[0]} `}</Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#3F3F46" }}
                >{`${item[1]}`}</Typography>
              </Box>
            ))}
          </Stack>
          {metaData?.schedules?.length > 0 && (
            <Stack direction="column">
              <Typography
                gutterBottom
                color="darkgray"
                sx={{ textAlign: "left" }}
              >
                Schedule
              </Typography>
              {metaData.schedules.map((scheduleID) => (
                <Stack direction="row" alignItems="center">
                  <Typography
                    sx={{ fontWeight: 600, color: "#3F3F46", mr: "20px" }}
                  >
                    {findScheduleName(scheduleID) ||
                      "Schedule Name Not Available"}{" "}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleDetachScheduler(scheduleID)}
                    sx={{
                      borderRadius: "25px",
                      padding: "5px 16px",
                      lineHeight: "1.5",
                      fontSize: "12px",
                    }}
                  >
                    Detach
                  </Button>
                </Stack>
              ))}
            </Stack>
          )}
        </>
      );
    }
    if (metaData.device_type === DEVICE_TYPES.iaq) {
      const { device_type } = metaData;
      return (
        <>
          <Stack direction="row" width="100%" gap={5} flexWrap="wrap">
            <Box>
              <Typography gutterBottom color="darkgray">
                Device Type
              </Typography>
              <Typography sx={{ fontWeight: 600, color: "#3F3F46" }}>
                {device_type}
              </Typography>
            </Box>
            <Box>
              <Typography gutterBottom color="darkgray">
                Fetch Type
              </Typography>
              <Typography sx={{ fontWeight: 600, color: "#3F3F46" }}>
                {metaData?.fetchType || "NA"}
              </Typography>
            </Box>
          </Stack>
          {metaData?.schedules?.length > 0 && (
            <Stack direction="column">
              <Typography
                gutterBottom
                color="darkgray"
                sx={{ textAlign: "left" }}
              >
                Schedule
              </Typography>
              {metaData.schedules.map((scheduleID, index) => (
                <Stack direction="row" alignItems="center" key={index}>
                  <Typography
                    sx={{ fontWeight: 600, color: "#3F3F46", mr: "20px" }}
                  >
                    {findScheduleName(scheduleID) ||
                      "Schedule Name Not Available"}{" "}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleDetachScheduler(scheduleID)}
                    sx={{
                      borderRadius: "25px",
                      padding: "5px 16px",
                      lineHeight: "1.5",
                      fontSize: "12px",
                    }}
                  >
                    Detach
                  </Button>
                </Stack>
              ))}
            </Stack>
          )}
        </>
      );
    }
    if (metaData.device_type === DEVICE_TYPES.switch) {
      const { name, fetchType, device_type } = metaData;
      return (
        <>
          <Stack direction="row" width="100%" gap={5} flexWrap="wrap">
            <Box>
              <Typography gutterBottom color="darkgray">
                Device Type
              </Typography>
              <Typography sx={{ fontWeight: 600, color: "#3F3F46" }}>
                {device_type}
              </Typography>
            </Box>
            <Box>
              <Typography gutterBottom color="darkgray">
                Fetch Type
              </Typography>
              <Typography sx={{ fontWeight: 600, color: "#3F3F46" }}>
                {metaData?.fetchType || "NA"}
              </Typography>
            </Box>
            <Box>
              <Typography gutterBottom color="darkgray">
                Name
              </Typography>
              <Typography sx={{ fontWeight: 600, color: "#3F3F46" }}>
                {metaData?.name || "NA"}
              </Typography>
            </Box>
          </Stack>
          {metaData?.schedules?.length > 0 && (
            <Stack direction="column">
              <Typography
                gutterBottom
                color="darkgray"
                sx={{ textAlign: "left" }}
              >
                Schedule
              </Typography>
              {metaData.schedules.map((scheduleID) => (
                <Stack direction="row" alignItems="center">
                  <Typography
                    sx={{ fontWeight: 600, color: "#3F3F46", mr: "20px" }}
                  >
                    {findScheduleName(scheduleID) ||
                      "Schedule Name Not Available"}{" "}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleDetachScheduler(scheduleID)}
                    sx={{
                      borderRadius: "25px",
                      padding: "5px 16px",
                      lineHeight: "1.5",
                      fontSize: "12px",
                    }}
                  >
                    Detach
                  </Button>
                </Stack>
              ))}
            </Stack>
          )}
        </>
      );
    }

    if (metaData.device_type === DEVICE_TYPES.ahu) {
      const { name, fetchType, device_type, gatewayId, vendor } = metaData;
      return (
        <>
          <Stack direction="row" width="100%" gap={5} flexWrap="wrap">
            <Box>
              <Typography gutterBottom color="darkgray">
                Device Type
              </Typography>
              <Typography sx={{ fontWeight: 600, color: "#3F3F46" }}>
                {device_type}
              </Typography>
            </Box>
            <Box>
              <Typography gutterBottom color="darkgray">
                Fetch Type
              </Typography>
              <Typography sx={{ fontWeight: 600, color: "#3F3F46" }}>
                {fetchType || "NA"}
              </Typography>
            </Box>
            <Box>
              <Typography gutterBottom color="darkgray">
                Name
              </Typography>
              <Typography sx={{ fontWeight: 600, color: "#3F3F46" }}>
                {name || "NA"}
              </Typography>
            </Box>
            <Box>
              <Typography gutterBottom color="darkgray">
                Gateway ID
              </Typography>
              <Typography sx={{ fontWeight: 600, color: "#3F3F46" }}>
                {gatewayId || "NA"}
              </Typography>
            </Box>
            <Box>
              <Typography gutterBottom color="darkgray">
                Vendor
              </Typography>
              <Typography sx={{ fontWeight: 600, color: "#3F3F46" }}>
                {vendor || "NA"}
              </Typography>
            </Box>
          </Stack>
          <br />
          {metaData?.schedules?.length > 0 && (
            <Stack direction="column">
              <Typography
                gutterBottom
                color="darkgray"
                sx={{ textAlign: "left" }}
              >
                Schedule
              </Typography>
              {metaData.schedules.map((scheduleID, index) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ marginBottom: "10px" }}
                  key={index}
                >
                  <Typography
                    sx={{ fontWeight: 600, color: "#3F3F46", mr: "20px" }}
                  >
                    {findScheduleName(scheduleID)}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleDetachScheduler(scheduleID)}
                    sx={{
                      borderRadius: "25px",
                      padding: "5px 16px",
                      lineHeight: "1.5",
                      fontSize: "12px",
                      width: "100px",
                    }}
                  >
                    Detach
                  </Button>
                </Stack>
              ))}
            </Stack>
          )}
        </>
      );
    }

    return false;
  };

  return (
    <Paper elevation={0} sx={{ padding: "20px" }}>
      {/* <ToastContainer /> */}
      <CustomModal open={openModal} handleClose={handleCloseModal} title="">
        {!scheduleList.length ? (
          <Typography>No Schedules Available</Typography>
        ) : (
          <form onSubmit={handleAttachScheduler}>
            <select name="scheduleId">
              {scheduleList.map((schedule) => (
                <option key={schedule.id} value={schedule.id}>
                  {schedule.name}
                </option>
              ))}
            </select>

            <button type="submit">Add Schedule</button>
          </form>
        )}
      </CustomModal>
      <div id="top_container">
        <CustomBreadcrumb />

        <div id="edit_btns_container">
          {metaData?.control && (
            <button
              onClick={() =>
                navigate("/device/control", {
                  state: {
                    device: location.state || selectedDeviceDetails,
                  },
                })
              }
            >
              Control Device
            </button>
          )}
          <button
            onClick={() =>
              navigate("/device/data", {
                state: {
                  deviceId: id,
                },
              })
            }
          >
            Live Data
          </button>
          <button
            onClick={() =>
              navigate("/device/edit/limits", {
                state: selectedDeviceDetails,
              })
            }
          >
            Update Device Limits
          </button>
          <button
            onClick={() =>
              navigate("/device/edit", {
                state: selectedDeviceDetails,
              })
            }
          >
            Edit
          </button>
        </div>
      </div>

      <div style={{ overflowX: "auto" }}>
        <table>
          <thead>
            <tr>
              <th>Device ID</th>
              <th>Installation</th>
              <th>Installation Details</th>
              <th>Status</th>
              <th>Provisioned On</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{id}</td>
              <td>{installed_on || "NA"}</td>
              <td>{installation_details || "NA"}</td>
              <td>{profile || "NA"}</td>
              <td>
                {provisoned_on
                  ? new Date(provisoned_on).toLocaleDateString("en-IN")
                  : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {!metaData?.device_type === "Switch" && (
        <>
          <Typography variant="h6" gutterBottom>
            Device Limits
          </Typography>
          <Grid container>
            <Grid item xs={12} md={6}>
              <table>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Lower Limit</th>
                    <th>Upper Limit</th>
                  </tr>
                </thead>
                <tbody>
                  {deviceLimits?.map((rowData, index) => (
                    <tr key={index}>
                      <td>{rowData.key}</td>
                      <td>{rowData.lowerLimit}</td>
                      <td>{rowData.upperLimit}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
          </Grid>
        </>
      )}

      <Typography sx={{ mt: "20px" }} gutterBottom color="primary" variant="h6">
        Metadata
      </Typography>
      {formatMetadata() || (
        <Typography variant="body2" gutterBottom>
          Not Available
        </Typography>
      )}
      <button
        style={{ marginTop: "20px" }}
        // onClick={() =>
        //   navigate("/schedule/add", {
        //     state: {
        //       id,
        //       metaData,
        //     },
        //   })
        // }
        onClick={handleOpenModal}
      >
        Add Schedule
      </button>
    </Paper>
  );
};

export default DeviceDetails;
