import { deleteAhuDatabase } from "../db/query";
import moment from "moment-timezone";
import {
  AGILE_INSTALLATION_TYPE,
  CLAIRCO_INSTALLATION_TYPE,
} from "./constants";

export function convertTo12HourFormat(time24) {
  // Split the time string into hours, minutes, and seconds
  var timeParts = time24.split(":");
  var hours = parseInt(timeParts[0], 10);
  var minutes = timeParts[1];

  // Determine AM/PM
  var period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Add leading zero to minutes if needed
  minutes = minutes.padStart(2, "0");

  // Construct the 12-hour time format
  var time12 = hours + ":" + minutes + " " + period;

  return time12;
}

export function fetchSchemaFromLocalStorage() {
  if (localStorage.getItem("schema")) {
    const schema =
      JSON.parse(localStorage.getItem("schema"))?.[0]?.schema || "";
    return schema;
  } else {
    return null;
  }
}

export async function logout(isAdmin, url) {
  localStorage.clear();
  sessionStorage.clear();
  await deleteAhuDatabase();
  if (isAdmin) {
    window.location.href = `/clairco/login`;
  } else {
    window.location.href = `/${url}/login`;
  }
}

export function tokenExistsInLocalstorage(tokenName) {
  return !!localStorage.getItem(tokenName);
}

export function checkIfKeyExistsInSessionStorage(key) {
  return !!sessionStorage.getItem(key);
}

export function checkIfKeyExistsInLocalStorage(key) {
  return !!localStorage.getItem(key);
}

export function getDataFromSessionStorage(key) {
  if (!checkIfKeyExistsInSessionStorage(key)) {
    return null;
  }
  return sessionStorage.getItem(key);
}

export function getTodayDate() {
  return moment().format("YYYY-MM-DD");
}

export function setItemInLocalstorage(itemName, itemValue) {
  return localStorage.setItem(itemName, itemValue);
}

export function getItemFromLocalstroage(key) {
  if (!checkIfKeyExistsInLocalStorage(key)) {
    return null;
  }
  return localStorage.getItem(key);
}

export function formatAhuDataBasedOnSavings(ahuResponse, installationType) {
  // console.log("ahu response ", ahuResponse);
  const ahu_list = ahuResponse.map((ahu) => {
    let result = [];
    if (installationType === AGILE_INSTALLATION_TYPE) {
      result = ahu?.data.map((item) => {
        const dataSet = item?.data?.data_set ?? [];
        const units = dataSet.filter((entry) => entry.unit_st == "1");
        const totalUnits = units.length;
        const totalFifteenMinutes = totalUnits * 15;
        const hours = totalFifteenMinutes / 60;
        const totalValue = Math.round(5.67 * 10 * hours);
        // let ahuStatus = dataSet[dataSet?.length - 2]?.unit_st ?? "";

        // console.log(item);
        let ahuStatus;
        if (moment().format("YYYY-MM-DD").toString() === item.index) {
          const todaysAhudata = item;

          ahuStatus =
            todaysAhudata?.data?.data_set[
              todaysAhudata?.data?.data_set?.length - 2
            ]?.unit_st;
          if (ahuStatus == "1") {
            ahuStatus = "ON";
          } else if (ahuStatus == "0") {
            ahuStatus = "OFF";
          } else {
            ahuStatus = "NA";
          }
          // console.log(
          //   "ahu status ",
          //   todaysAhudata?.data?.data_set[
          //     todaysAhudata?.data?.data_set?.length - 2
          //   ].unit_st,
          // );
          // console.log("todays Ahu data ", todaysAhudata);
        }
        // console.log("ahuStatus  ", ahuStatus);
        return {
          index: item.index,
          "number of unit st": totalUnits,
          "total fifteen minutes": totalFifteenMinutes,
          hours,
          savings: totalValue,
          ahuStatus,
          date: item.index,
        };
      });
    }

    if (installationType === CLAIRCO_INSTALLATION_TYPE) {
      let dataSet = ahu?.data?.msg.map((device) => device.metaData) ?? [];
      const units = dataSet.filter((entry) => entry.unit_st == "1");
      const totalUnits = units.length;
      const totalFifteenMinutes = totalUnits * 2;
      const hours = totalFifteenMinutes / 60;
      const totalValue = Math.round(5.67 * 10 * hours);
      let ahuStatus = dataSet[dataSet?.length - 1]?.unit_st ?? "";
      // console.log("last data in helper ", dataSet[dataSet.length - 1]);

      if (ahuStatus == "1") {
        ahuStatus = "ON";
      } else if (ahuStatus == "0") {
        ahuStatus = "OFF";
      } else {
        ahuStatus = "NA";
      }
      result.push({
        index: "",
        "number of unit st": totalUnits,
        "total fifteen minutes": totalFifteenMinutes,
        hours,
        savings: totalValue,
        ahuStatus,
      });
      // console.log("result => ", result);
    }

    const month_savings = result?.reduce((sum, item) => sum + item.savings, 0);

    const month_start_date = moment(
      moment(result?.[0].index).format("D MMM"),
    ).isValid()
      ? moment(result?.[0].index).format("D MMM")
      : "NA";
    const month_end_date = moment(
      moment(result?.[result.length - 1]?.index).format("D MMM"),
    ).isValid()
      ? moment(result?.[result.length - 1]?.index).format("D MMM")
      : "NA";

    const weekAgo = moment().subtract(7, "days").format("YYYY-MM-DD");

    const currentWeekValues = result?.filter((item) =>
      moment(item.index).isBetween(weekAgo, getTodayDate(), null, "[]"),
    );

    const weekly_savings = currentWeekValues?.reduce(
      (sum, item) => sum + item.savings,
      0,
    );

    const formattedCurrentWeekEnd = moment(getTodayDate()).format("D MMM");
    const formattedCurrentWeekStart = moment(weekAgo).format("D MMM");
    const today_savings = result?.[result?.length - 1].savings;
    // console.log(result);
    const ahus = {
      everyday_savings: result || [],
      month_savings: {
        value: month_savings || "NA",
        date_range: `${month_start_date} - ${month_end_date}`,
      },
      week_savings: {
        value: weekly_savings || [],
        date_range: `${formattedCurrentWeekStart} - ${formattedCurrentWeekEnd}`,
      },
      today_savings: {
        value: today_savings || [],
        date_range: `${getTodayDate()}`,
      },
    };

    return ahus;
  });
  return ahu_list;
}

export function calculateLastHourBtuConsumption(data_set) {
  if (!Array.isArray(data_set)) return "NA";
  const lastHourBtuValues = data_set.slice(-4);
  let btuLastHourValue = 0;
  let btuLastHourNonNullValuesCount = 0;

  lastHourBtuValues.forEach((obj) => {
    if (obj.btu_power) {
      btuLastHourValue += parseFloat(obj.btu_power);
      ++btuLastHourNonNullValuesCount;
    }
  });

  let todayAverageBtuValue =
    parseFloat(btuLastHourValue) / btuLastHourNonNullValuesCount;

  if (isNaN(todayAverageBtuValue)) {
    return "NA";
  } else {
    return todayAverageBtuValue.toFixed(2) + " Kwh";
  }
}

export const isValidJSON = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
};

export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
