import { Alert, Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./EditDevice.css";
import { useForm } from "react-hook-form";
import { DEVICE_TYPES } from "../../../../utils/constants";
import { useDevice } from "../../../../context/DeviceContext";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import { toast } from "react-toastify";

const EditDevice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    updateMultiDeviceData,
    fetchDevices,
    setFilteredItems,
    setBreadcrumbs,
  } = useDevice();
  const [apiResponse, setApiResponse] = useState(null);
  const { register, handleSubmit, reset, watch } = useForm();
  const { id, installation_details, installed_on, profile, metaData } =
    location.state;
  // console.log(location.state);

  useEffect(() => {
    setBreadcrumbs([
      { path: "/device", breadcrumbName: "Device" },
      { path: "/device/details", breadcrumbName: "Details" },
      { path: "/device/edit", breadcrumbName: "Edit" },
    ]);
  }, [location]);

  const formattedLocationData = [
    {
      name: "Device ID",
      value: id ? id : "NA",
      key: "id",
      readonly: true,
      type: "text",
      required: true,
      helperText: "",
    },
    {
      name: "Installation Details",
      value: installation_details || "NA",
      key: "installation_details",
      type: "text",
      required: true,
      helperText:
        "If you are altering the installation details, please update the installation date.",
    },
    {
      name: "Installation Date",
      value: installed_on || "NA",
      key: "installed_on",
      type: "date",
      required: true,
      helperText: "",
    },
    {
      name: "Profile",
      value: profile || "NA",
      options: ["PRODUCTION", "TESTING"],
      type: "select",
      required: true,
      helperText: "",
      key: "profile",
    },
  ];
  const metaDataFormat = [];
  if (metaData.device_type === DEVICE_TYPES.thSens) {
    metaDataFormat.push({
      name: "Device Type",
      type: "text",
      required: true,
      helperText: "",
      key: "device_type",
      value: metaData.device_type,
      // readonly: true,
    });
    metaDataFormat.push({
      name: "Route",
      type: "text",
      required: true,
      helperText: "",
      key: "route",
      value: metaData?.fetchDetails?.[0]?.route ?? "",
      // readonly: true,
    });
    metaDataFormat.push({
      name: "Attached Device",
      type: "text",
      required: true,
      helperText: "",
      key: "attachedDevice",
      value: metaData?.fetchDetails?.[0]?.attachedDevice ?? "",
      // readonly: true,
    });
    metaDataFormat.push({
      name: "Auth Type",
      type: "text",
      required: true,
      helperText: "",
      key: "authType",
      value: metaData?.fetchDetails?.[0]?.authType ?? "",
      // readonly: true,
    });
    metaDataFormat.push({
      name: "Body",
      type: "text",
      required: true,
      helperText: "",
      key: "body",
      value: metaData?.fetchDetails?.[0]?.body ?? "",
      // readonly: true,
    });
  }
  if (metaData.device_type === DEVICE_TYPES.dpt) {
    metaDataFormat.push({
      name: "Device Type",
      type: "text",
      required: true,
      helperText: "",
      key: "device_type",
      value: metaData.device_type,
      // readonly: true,
    });
    if (metaData?.name) {
      metaDataFormat.push({
        name: "Location",
        type: "text",
        required: true,
        helperText: "",
        key: "name",
        value: metaData.name,
      });
    }

    metaDataFormat.push({
      name: "Fetch Type",
      type: "text",
      required: true,
      helperText: "",
      key: "fetchType",
      value: metaData.fetchType,
      // readonly: true,
    });
    metaDataFormat.push({
      name: "Topic",
      type: "text",
      required: true,
      helperText: "",
      key: "topic",
      value: metaData.topic,
      // readonly: true,
    });
  }
  if (metaData.device_type === DEVICE_TYPES.iaq) {
    metaDataFormat.push({
      name: "Device Type",
      type: "text",
      required: true,
      helperText: "",
      key: "device_type",
      value: metaData.device_type,
      // readonly: true,
    });
    metaDataFormat.push({
      name: "Fetch Type",
      type: "text",
      required: true,
      helperText: "",
      key: "fetchType",
      value: metaData.fetchType,
      // readonly: true,
    });
    metaDataFormat.push({
      name: "Topic",
      type: "text",
      required: true,
      helperText: "",
      key: "topic",
      value: metaData.topic,
      // readonly: true,
    });
  }

  if (metaData.device_type === DEVICE_TYPES.switch) {
    metaDataFormat.push({
      name: "Name",
      type: "text",
      required: true,
      helperText: "",
      key: "name",
      value: metaData.name,
    });
    metaDataFormat.push({
      name: "Fetch Type",
      type: "text",
      required: true,
      helperText: "",
      key: "fetchType",
      value: metaData.fetchType,
      readonly: false,
    });
    metaDataFormat.push({
      name: "Device Type",
      type: "text",
      required: true,
      helperText: "",
      key: "device_type",
      value: metaData.device_type,
      readonly: false,
    });
  }

  if (metaData.device_type === DEVICE_TYPES.ahu) {
    metaDataFormat.push({
      name: "Name",
      type: "text",
      required: true,
      helperText: "",
      key: "name",
      value: metaData.name,
    });
    metaDataFormat.push({
      name: "Fetch Type",
      type: "text",
      required: true,
      helperText: "",
      key: "fetchType",
      value: metaData.fetchType,
      readonly: false,
    });
    metaDataFormat.push({
      name: "Device Type",
      type: "text",
      required: true,
      helperText: "",
      key: "device_type",
      value: metaData.device_type,
      readonly: false,
    });
    metaDataFormat.push({
      name: "Gateway ID",
      type: "text",
      required: true,
      helperText: "",
      key: "gatewayId",
      value: metaData.gatewayId,
      readonly: false,
    });
  }

  const renderInput = (field) => {
    const { name, value, type, options, required, key, readonly } = field;
    const updated_installation_details = watch("installation_details");

    switch (type) {
      case "text":
        return (
          <input
            type="text"
            name={name}
            {...register(key, {
              required,
            })}
            style={{ background: readonly ? "#e5e7eb" : "none" }}
            readOnly={readonly || false}
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
            defaultValue={value}
          />
        );

      case "date":
        return (
          <input
            type="date"
            name={name}
            required={required}
            {...register(key, {
              required,
            })}
            defaultValue={value}
            min={value}
            disabled={
              installation_details === updated_installation_details
                ? true
                : false
            }
          />
        );

      case "select":
        return (
          <select
            name={name}
            {...register(key, {
              required,
            })}
            defaultValue={value}
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );

      default:
        return null;
    }
  };

  // Handle device edit form ****************/

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const formData = {
      deviceId: id,
    };
    console.log(data);
    if (data.installation_details !== installation_details) {
      formData.installation_details = data.installation_details;
    }
    if (data.profile !== profile) {
      formData.profile = data.profile;
    }
    if (data.installed_on !== installed_on) {
      formData.installed_on = data.installed_on;
    }
    if (
      metaData.device_type === DEVICE_TYPES.switch &&
      data.name !== metaData.name
    ) {
      formData.metaData = metaData;
      formData.metaData.name = data.name;
    }
    if (metaData.device_type === DEVICE_TYPES.dpt) {
      formData.metaData = metaData;
      formData.metaData.topic = data.topic;
      formData.metaData.fetchType = data.fetchType;
      formData.metaData.device_type = data.device_type;
      if (metaData.hasOwnProperty("name") && data.name !== metaData.name) {
        formData.metaData.name = data.name;
      }
    }

    if (metaData.device_type === DEVICE_TYPES.iaq) {
      formData.metaData = metaData;
      formData.metaData.topic = data.topic;
      formData.metaData.fetchType = data.fetchType;
      formData.metaData.device_type = data.device_type;
    }

    if (metaData.device_type === DEVICE_TYPES.thSens) {
      formData.metaData = metaData;
      formData.metaData.fetchDetails[0].authType = data.authType;
      formData.metaData.fetchDetails[0].body = data.body;
      formData.metaData.fetchDetails[0].route = data.route;
      formData.metaData.fetchDetails[0].attachedDevice = data.attachedDevice;
    }

    if (metaData.device_type === DEVICE_TYPES.ahu) {
      formData.metaData = metaData;
      formData.metaData.fetchType = data.fetchType;
      formData.metaData.device_type = data.device_type;
      formData.metaData.gatewayId = data.gatewayId;
      formData.metaData.name = data.name;
    }

    console.log(formData);

    if (
      data.installation_details !== installation_details &&
      data.installed_on === installed_on
    ) {
      setApiResponse(
        "Since the installation details are changed, the installation date needs to be updated as well",
      );

      return;
    }

    const deviceId = formData.deviceId;
    delete formData.deviceId;
    console.log(formData);
    const jsonString = JSON.stringify(formData);
    const formattedString = jsonString.replace(/\\/g, "").replace(/"/g, "'");
    try {
      const response = await updateMultiDeviceData(deviceId, formattedString);
      if (response) {
        setApiResponse("Device has been successfully updated");
        fetchDevices();
        setFilteredItems([]);
        setTimeout(() => {
          navigate("/device");
        }, 3000);
      } else {
        setApiResponse(
          "An error occurred while editing device. Please try again later",
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper sx={{ p: "20px" }}>
      <CustomBreadcrumb />
      <Box component={"div"} mt={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formattedLocationData.map((item, index) => (
            <Box key={index}>
              <div>
                <Typography component="label">
                  {item.name !== "Set Limits" && item.name}
                  {item.name !== "Set Limits" && item?.required && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </Typography>
                {renderInput(item)}
                {item.helperText ? (
                  <p
                    className="input_helper_text"
                    style={{ textAlign: "left" }}
                  >
                    {item.helperText}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </Box>
          ))}

          <Typography variant="h6">Metadata</Typography>
          {metaDataFormat.map((item, index) => (
            <Box key={index}>
              <Typography component="label">{item.name}</Typography>
              <input
                type={item?.type}
                {...register(item.key, {
                  required: item?.required,
                })}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                defaultValue={item.value}
                style={{ background: item.readonly ? "#e5e7eb" : "none" }}
                readOnly={item.readonly || false}
              />
            </Box>
          ))}
          <Button type="submit" variant="contained" sx={{ mt: 3 }}>
            Edit
          </Button>
        </form>

        {apiResponse && (
          <Alert severity="info" sx={{ mt: 2 }}>
            {apiResponse}
          </Alert>
        )}
      </Box>
    </Paper>
  );
};

export default EditDevice;
