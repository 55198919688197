import { 
	Box,
	Button,
	CircularProgress,
	Modal,
	
	OutlinedInput,
	InputAdornment,
	IconButton,
	Paper, 
	Pagination,
	Snackbar,
	Stack,
	TableContainer, 
	Table, 
	TableHead, 
	TableBody, 
	TableRow, 
	TableCell,
	TextField,
	Typography, 
	Grid,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import React, {useEffect, useState} from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import moment from 'moment-timezone';

var config = {
    bucketName: 'heatsensorimages',
    region: 'ap-south-1',
    accessKeyId: 'AKIASZFTOMUY7E4TZV63',
    // dirName: 'max-okhla/',
    secretAccessKey: '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',
    Delimiter: '/',
};
var AWS = require('aws-sdk');

var s3;


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: 'relative',
  // top: '60%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const baseurl = 'https://heatsensorimages.s3.ap-south-1.amazonaws.com/';

const InputBox = (props) => {
  // console.log(props);
  return (
    <>
      <OutlinedInput
        size="small"
        placeholder={props.placeholder}
        fullWidth
        sx={{ mb: "10px", fontWeight: 400, fontSize: "15px" }}
        {...props.register(props.registerLabel, {
          required: props.required,
          pattern: {
            value: props.pattern,
            message: props.errorMessage,
          },
        })}
        // required
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="end">{props.icon}</IconButton>
          </InputAdornment>
        }
      />
    </>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const ImageAnnotation = () => {
	AWS.config.update(config);
  s3 = new AWS.S3();
	
	const [imageList, setimageList] = useState('');
	const [showItem, setshowItem] = useState(false);
	const [open, setOpen] = useState(false);
	const [snackStatus, setsnackStatus] = useState(false);
	const [selectedImage, setSelectedImage] = useState('');
	const [offset, setoffset] = useState(0);
	const [selectedImageId, setSelectedImageId] = useState('');

	const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm();

  const onSubmit = async (inp) => {
    // get schema from the uselocation state and send it along with the user creation details
    try {
      const { data } = await axios({
        method: "post",
        url: "https://api.claircoair.com/api/admin/updateImageData",
        data: {
          	metaData: inp,
			id: selectedImageId
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "api-key": "lcvhzec0ae4u20047s36mfexcjr2ebd3cpk28b9yca9c9f95d17dgeda",
        },
      });
      if(data.msg.indexOf('added')>-1){
		const updatedImageList = imageList.filter(function (images) {
			return images.id !== selectedImageId;
		});
		setimageList(updatedImageList)
		setsnackStatus(true);
	  }
      reset();
    } catch (error) {
      console.error(error);
    }
  };

	
	const getObjects = async (startAfter) => {
		try {
			const { data } = await axios({
			  method: "post",
			  url: "https://api.claircoair.com/api/admin/fetchImageData",
			  data: {
					interval: '15 min'
			  },
			  headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
				"api-key": "lcvhzec0ae4u20047s36mfexcjr2ebd3cpk28b9yca9c9f95d17dgeda",
			  },
			});
			if(data['msg']['msg'].length>0){
				setimageList(data['msg']['msg']);
			}
		  } catch (error) {
			console.error(error);
		  }
	}
  
	const handleOpen = async (id, rawData) => {
		try {
      let array = [rawData];
			const  {data}  = await axios({
			  method: "post",
			  url: "http://35.244.16.234:5000/process_image",
			  data: {
					tempArray: array.toString(),
					image: 'image'
			  },
			});
			setSelectedImage(data['image'])
			
		  } catch (error) {
			console.error(error);
		  }
		setSelectedImageId(id);
		setOpen(true);

	};
  	const handleClose = () => setOpen(false);	

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
		return;
		}

		setsnackStatus(false);
	};

	useEffect(() => {	
		getObjects(offset);
	}, [])

	if(imageList.length>0){
		return(
			<div style={style2}>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell><b>Image Name</b></TableCell>
								<TableCell align="right"><b>Annotated</b></TableCell>
								<TableCell align="right"><b>MetaData</b></TableCell>
								<TableCell align="right"><b>Date & Time</b></TableCell>
							</TableRow>
						</TableHead>
              <TableBody>
              {imageList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell onClick={() => handleOpen(row['id'], row['rawdata'])} >
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.annotated == true ? 'true' : 'false'}</TableCell>
                  <TableCell align="right">{row.metaData != null ? row.metaData : 'NA'}</TableCell>
                  <TableCell align="right">{row.date + ' - ' + row.time}</TableCell>
                </TableRow>
              )
              )}
              </TableBody>
						</Table>
						</TableContainer>
						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
					<Grid  sx={style} container spacing={2}>
						<Grid item xs={6} md={8}>
							<Item>
							<div>
								<img className="slide_image" src={"data:image/png;base64,"+selectedImage}/>							</div>
							</Item>
						</Grid>
						<Grid item xs={6} md={4}>
							<Stack spacing={2}>
								<Item>
									<div>
									<Typography id="modal-modal-title" variant="h6" component="h2">
											Add Image Data
									</Typography>
									</div>
								</Item>
								<Item>
									<Box sx= {{width: 250}}>
										<form onSubmit={handleSubmit(onSubmit)}>
											<div>
												<InputBox
													placeholder="Number of People"
													// icon={<Apartment />}
													registerLabel="people_number"
													register={register}
													required={true}
													pattern={null}
													errorMessage="Required Field"
												/>
												<InputBox
													placeholder="Angle"
													// icon={<Apartment />}
													registerLabel="angle"
													register={register}
													required={false}
													pattern={null}
													errorMessage="Required Field"
												/>
												<InputBox
													placeholder="Tags (Comma Seprated)"
													// icon={<Apartment />}
													registerLabel="tags"
													register={register}
													required={true}
													pattern={null}
													errorMessage="Required Field"
												/>
												<Button variant="contained" type="submit">
													Submit Data
												</Button>
												<Button sx={{marginLeft: 5}} variant="contained" onClick={() => handleClose()}>
													Close
												</Button>
												<Snackbar open={snackStatus} autoHideDuration={2000} onClose={handleSnackClose}>
													<Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
													Annotations Added
													</Alert>
												</Snackbar>
											</div>
										</form>
									</Box>
								</Item>
							</Stack>
						</Grid>
					</Grid>
				</Modal>
			</div>
		)
	} else {
		return(
			<CircularProgress />
		)
	}
}

export default ImageAnnotation;