import { useContext, createContext, useState, useEffect } from "react";
import { getSchedules } from "../utils/Api";
import { useAuthContext } from "./AuthContext";
import { DataArrayOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";

export const ScheduleContext = createContext();

const ScheduleContextProvider = ({ children }) => {
  const [scheduleList, setScheduleList] = useState(
    JSON.parse(localStorage.getItem("schedule_list")) || [],
  );

  const [scheduleListLoading, setScheduleListLoading] = useState(true);
  const [selectedSchedule, setselectedSchedule] = useState("");
  const { isAdmin } = useAuthContext();
  const [isSchduleViewRestricted, setIsSchduleViewRestricted] = useState(false);
  const fetchLatestScheduleList = async () => {
    setScheduleListLoading(true);
    try {
      const { data } = await getSchedules({ queryCol: "all" });
      localStorage.setItem("schedule_list", JSON.stringify(data.msg));
      setScheduleList(data.msg);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        setIsSchduleViewRestricted(true);
      }
    } finally {
      setScheduleListLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("schedule_list") && isAdmin) {
      setScheduleListLoading(true);
      const data = JSON.parse(localStorage.getItem("schedule_list"));
      setScheduleList(data);
      setScheduleListLoading(false);
    } else if (!localStorage.getItem("schedule_list") && isAdmin) {
      getSchedules({ queryCol: "all" })
        .then(({ data }) => {
          localStorage.setItem("schedule_list", JSON.stringify(data.msg));
          setScheduleList(data.msg);
          setScheduleListLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setScheduleListLoading(false);
          if (error?.response?.status === 403) {
            setIsSchduleViewRestricted(true);
          }
        });
    }
  }, [isAdmin]);

  return (
    <ScheduleContext.Provider
      value={{
        scheduleList,
        scheduleListLoading,
        selectedSchedule,
        setselectedSchedule,
        fetchLatestScheduleList,
        isSchduleViewRestricted,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export const useSchedule = () => useContext(ScheduleContext);
export default ScheduleContextProvider;
