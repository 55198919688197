import {
  Box,
  Grid,
  Typography,
  Button,
  Snackbar,
  IconButton,
  Paper,
  Stack,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import AreaChart from "../../components/charts/AreaChart";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment-timezone";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import "./Ahu.css";
import { getItemFromLocalstroage, getTodayDate } from "../../utils/helper";

import {
  AGILE_INSTALLATION_TYPE,
  CLAIRCO_CLIENT_HISTORY_TYPES,
  CLAIRCO_INSTALLATION_TYPE,
} from "../../utils/constants";
import { getAhuData } from "../../utils/Api";
import GraphSkeleton from "../../components/loading_skeletons/GraphSkeleton";
import { toast } from "react-toastify";
import { axisClasses } from "@mui/x-charts";
import { BarChart } from "@mui/x-charts";
import { LineChart } from "@mui/x-charts/LineChart";
import { useAuthContext } from "../../context/AuthContext";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const amtData = [2400, 2210, 0, 2000, 2181, 2500, 2100];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

const NoAHUData = () => {
  return (
    <Box
      sx={{
        height: "300px",
        width: "100%",
        display: "grid",
        placeItems: "center",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DataThresholdingIcon color="primary" sx={{ fontSize: "150px" }} />
        <Typography variant="h5">No AHU Data Available</Typography>
      </Box>
    </Box>
  );
};

const Ahu = () => {
  const todayDate = moment().format("YYYY-MM-DD");
  const oneMonthAgo = dayjs().subtract(1, "month");
  const [graphData, setGraphData] = useState([]);
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [chilledWaterPos, setChilledWaterPos] = useState("");
  const [ahuStatus, setAhuStatus] = useState("");
  const [showAhuAlert, setShowAhuAlert] = useState(false);
  const [lastFetchedAhuData, setLastFetchedAhuData] = useState(null);
  let ahuData = location.state?.ahu?.data;
  const [ahuAlertStatus, setAhuAlertStatus] = useState("");
  const params = useParams();
  const schema = params.schema;
  const navigate = useNavigate();
  const installationType = getItemFromLocalstroage("installationType");
  const [graphLoading, setGraphLoading] = useState(false);
  const { setBreadcrumbs } = useAuthContext();
  useEffect(() => {
    setBreadcrumbs([
      { path: `/${params.schema}/ahu/list`, breadcrumbName: "AHU List" },
      // { path: `/ahu/list`, breadcrumbName: "AHU List" },

      { path: `/${params.schema}/ahu`, breadcrumbName: "AHU Details" },
    ]);

    // console.log(location.state);
  }, [location]);

  // interval is for  clairco installation type
  const [interval, setInterval] = useState(
    CLAIRCO_CLIENT_HISTORY_TYPES.LAST_24_HOURS,
  );
  const fetchGraphDataset = (labels, stemp, rtemp, cWP) => {
    let xAxisLabels = labels;

    if (installationType === AGILE_INSTALLATION_TYPE) {
      xAxisLabels = labels;
    }

    if (installationType === CLAIRCO_INSTALLATION_TYPE) {
      const dateTimeString = labels;
      const format = "HH:mm:ss, YYYY-MM-DD";
      const formattedDateTime = dateTimeString.map((timestamp) =>
        moment(timestamp, format).format("h:mm A, DD MMM").toString(),
      );
      xAxisLabels = formattedDateTime;
      stemp = stemp.map((temp) => parseInt(temp / 10));
      rtemp = rtemp.map((temp) => parseInt(temp / 10));
    }

    const data2 = {
      labels: xAxisLabels || [],
      datasets: [
        {
          label: "Stemp",
          data: stemp,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          fill: true,
          pointRadius: 0.6,
          tension: 0.5,
          yAxisID: "temp",
        },
        {
          label: "Rtemp",
          data: rtemp,
          borderColor: "rgb(157, 131, 235)",
          backgroundColor: "rgba(157, 131, 235,0.5)",
          pointRadius: 0.6,
          fill: true,
          yAxisID: "temp",
          tension: 0.5,
        },
        {
          label: "Chiller Water Position",
          data: cWP,
          borderColor: "rgb(0, 201, 183)",
          backgroundColor: "rgba(0, 201, 183,0.5)",
          tension: 0.5,
          pointRadius: 0.6,
          fill: true,
          yAxisID: "ChWP",
        },
      ],
    };
    // setGraphData(data2);

    // Area chart
    const chillerData = cWP;
    const setTempData = stemp;
    const rTempData = rtemp;
    const xLabels = labels;

    setGraphData({
      chillerData,
      setTempData,
      rTempData,
      xLabels,
    });

    localStorage.setItem("ahu_graph", JSON.stringify(data2));

    // Bar Chart

    const dataset = labels.map((label, index) => {
      return {
        time: label,
        chillerData: cWP[index],
        setTempData: stemp[index],
        rTempData: rtemp[index],
      };
    });

    // console.log(dataset);

    // localStorage.setItem("ahu_graph", JSON.stringify(dataset));
  };

  const handleDateChange = (newValue) => {
    if (installationType == AGILE_INSTALLATION_TYPE) {
      const date = moment(newValue["$d"]).format("YYYY-MM-DD");
      if (ahuData && Array.isArray(ahuData) && ahuData.length > 0) {
        const ahuDayData = ahuData.filter(
          (ahu) => ahu.index === date.toString(),
        );
        // console.log(ahuDayData[0]);
        // console.log(ahuDayData[0]?.data.data_set);
        let labels = ahuDayData[0]?.data?.data_set.map(
          (data) => data.time_slot,
        );
        let stemp = ahuDayData[0]?.data?.data_set.map((data) => data.stemp);
        let rtemp = ahuDayData[0]?.data.data_set.map((data) => data.rtemp);
        let cWP = ahuDayData[0]?.data.data_set.map((data) => data.chw_p);
        setSelectedDate(date);
        fetchGraphDataset(labels, stemp, rtemp, cWP);
      } else {
        alert("No AHU Data available");
      }
    }
  };

  // this useeffect is for fetching current day's data

  useEffect(() => {
    if (!installationType) return;
    if (
      installationType == AGILE_INSTALLATION_TYPE &&
      ahuData &&
      Array.isArray(ahuData) &&
      ahuData.length > 0
    ) {
      let today_data = ahuData[ahuData?.length - 1];
      let labels =
        today_data?.data?.data_set?.map((data) => data.time_slot) ?? [];
      let stemp = today_data?.data?.data_set?.map((data) => data.stemp) ?? [];
      let rtemp = today_data?.data?.data_set?.map((data) => data.rtemp) ?? [];
      let cWP = today_data?.data?.data_set?.map((data) => data.chw_p) ?? [];
      if (
        labels.length > 0 &&
        stemp.length > 0 &&
        rtemp.length > 0 &&
        cWP.length > 0
      ) {
        fetchGraphDataset(labels, stemp, rtemp, cWP);
      }
    }
  }, []);

  useEffect(() => {
    if (installationType == AGILE_INSTALLATION_TYPE) {
      const ahuDayData = Array.isArray(ahuData)
        ? ahuData.filter((ahu) => ahu.index === selectedDate.toString())
        : [];
      if (!ahuDayData?.length) return;
      const last10ahuDayValues =
        ahuDayData[0]?.data["data_set"]?.slice(-10) ?? [];
      for (let i = last10ahuDayValues.length - 1; i >= 0; i--) {
        if (last10ahuDayValues[i].alerts) {
          setAhuAlertStatus(last10ahuDayValues[i].alerts);
          setShowAhuAlert(true);
          break;
        }
      }
      const data = ahuDayData[0]?.data["data_set"] || [];
      const ahuLatestData = data[data.length - 2] || [];
      // console.log(ahuLatestData.time_slot);
      // console.log(ahuLatestData);
      setLastFetchedAhuData({
        time_slot: ahuLatestData?.time_slot ?? "",
        date: getTodayDate().toString(),
      });
      if (ahuLatestData?.chw_p) {
        const chwp = Number(ahuLatestData.chw_p).toFixed(2) + "%";
        setChilledWaterPos(chwp);
      } else {
        setChilledWaterPos("NA");
      }

      // If selected date is less than current date, set ahu status NA
      if (selectedDate < todayDate) {
        setAhuStatus("NA");
      } else {
        if (ahuLatestData.unit_st == "1") {
          setAhuStatus("ON");
        } else if (ahuLatestData.unit_st == "0") {
          setAhuStatus("OFF");
        } else {
          setAhuStatus("NA");
        }
      }
    }
    if (installationType == CLAIRCO_INSTALLATION_TYPE) {
      // console.log("the useeffect for clairco is running");
      if (!location.state?.deviceId) return;
      setGraphLoading(true);
      const payload = {
        deviceId: location.state.deviceId,
        fetchType: "history",
        flag_details: {
          interval,
          installation_type: CLAIRCO_INSTALLATION_TYPE,
        },
      };

      getAhuData(payload)
        .then(({ data }) => {
          // console.log(data)
          const dataset = data?.msg ?? [];
          if (!dataset.length) {
            setGraphLoading(false);
            fetchGraphDataset([], [], [], []);
            return toast("Data not available");
          }
          const labels =
            dataset.map((data) => `${data?.time},${data?.date || ""}`) || [];
          const stemp = dataset.map((data) => data.metaData.stemp) || [];
          const rtemp = dataset.map((data) => data.metaData.rtemp) || [];
          const cWP = dataset.map((data) => data.metaData.chw_p) || [];
          const { chw_p = null, unit_st = null } = dataset.length
            ? dataset[dataset.length - 1].metaData
            : null;

          // console.log("last data ", dataset[dataset.length - 1].metaData);
          const AhuStatus =
            unit_st !== null
              ? unit_st == "1"
                ? "ON"
                : "OFF"
              : "Not Available";
          // console.log("ahu status ", AhuStatus);
          const lastFetchedTime = dataset[dataset.length - 1].time || "";
          const lastFetchedDate = dataset[dataset.length - 1].date;

          setLastFetchedAhuData({
            time: lastFetchedTime,
            date: lastFetchedDate,
          });
          setSelectedDate(lastFetchedDate);

          setAhuStatus(AhuStatus);
          setChilledWaterPos(
            chw_p !== null ? Number(chw_p).toFixed(2) + "%" : "Not Available",
          );
          fetchGraphDataset(labels, stemp, rtemp, cWP);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setGraphLoading(false);
        });
    }
  }, [location.state, interval]);

  return (
    <Box sx={{ padding: { sm: "0px", xs: "0px" } }} className="ahu_container">
      <Paper sx={{ padding: 2, marginBottom: 3 }} elevation={0}>
        <CustomBreadcrumb />
        <br />

        <Divider sx={{ mb: 3 }} />
        <Stack direction="row" gap={5} sx={{ flexWrap: "wrap" }}>
          <Stack>
            <Typography
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
              gutterBottom
            >
              {location.state?.ahuDetails?.device_id ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              DEVICE ID
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
              gutterBottom
            >
              {location.state?.ahuDetails?.friendly_name ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              AHU NAME
            </Typography>
          </Stack>
          <Stack>
            <Typography
              gutterBottom
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
            >
              {location.state?.ahuDetails?.installation_date ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              INSTALLATION DATE
            </Typography>
          </Stack>
          <Stack>
            <Typography
              gutterBottom
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
            >
              {location.state?.ahuDetails?.location ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              LOCATION
            </Typography>
          </Stack>
        </Stack>
      </Paper>

      {ahuAlertStatus && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          message={ahuAlertStatus}
          open={showAhuAlert}
          action={
            <IconButton
              size="small"
              color="inherit"
              onClick={(event, reason) => {
                setShowAhuAlert(false);
                setAhuAlertStatus("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          onClose={() => {
            setShowAhuAlert(false);
            setAhuAlertStatus("");
          }}
        />
      )}
      <Grid container spacing={3}>
        <Grid container item md={8} sm={12}>
          {localStorage.getItem("ahu_graph") ? (
            <div className="ahu_graph_container">
              {installationType === AGILE_INSTALLATION_TYPE ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Select Date"
                    format="DD/MM/YYYY"
                    disableFuture={true}
                    onChange={handleDateChange}
                    minDate={oneMonthAgo}
                  />
                </LocalizationProvider>
              ) : (
                <select
                  value={interval}
                  onChange={(e) => setInterval(e.target.value)}
                  style={{ width: "300px" }}
                >
                  <option value="12 hours">12 Hours</option>
                  <option value="24 hours" selected>
                    24 Hours
                  </option>
                  <option value="3 days">3 Days</option>
                  <option value="7 days">7 Days</option>
                </select>
              )}

              {graphLoading ? (
                <div style={{ padding: "2rem" }}>
                  <GraphSkeleton />
                </div>
              ) : (
                <AreaChart
                  data={JSON.parse(localStorage.getItem(`ahu_graph`))}
                  temp_flow={false}
                  cooling_energy={false}
                  temp={true}
                  ChWP={true}
                  heading={
                    installationType == AGILE_INSTALLATION_TYPE
                      ? `AHU Consumption on ${selectedDate} `
                      : "AHU Consumption"
                  }
                />
                // <LineChart
                //   width={500}
                //   height={300}
                //   series={[
                //     {
                //       data: graphData.setTempData,
                //       label: "stemp",
                //       area: true,
                //       stack: "total",
                //       showMark: false,
                //     },
                //     {
                //       data: graphData.rTempData,
                //       label: "rtemp",
                //       area: true,
                //       stack: "total",
                //       showMark: false,
                //     },
                //     {
                //       data: graphData.chillerData,
                //       label: "chiller",
                //       area: true,
                //       stack: "total",
                //       showMark: false,
                //     },
                //   ]}
                //   xAxis={[{ scaleType: "point", data: graphData.xLabels }]}
                //   sx={{
                //     ".MuiLineElement-root": {
                //       display: "none",
                //     },
                //   }}
                // />

                // <LineChart
                //   height={300}
                //   series={[
                //     {
                //       data: JSON.parse(localStorage.getItem(`ahu_graph`))
                //         .setTempData,
                //       label: "stemp",
                //       area: true,
                //       stack: "total",
                //       showMark: false,
                //       color: "#008675",
                //     },
                //     {
                //       data: JSON.parse(localStorage.getItem(`ahu_graph`))
                //         .rTempData,
                //       label: "rtemp",
                //       area: true,
                //       stack: "total",
                //       showMark: false,
                //       color: "#22C55E",
                //     },
                //     {
                //       data: JSON.parse(localStorage.getItem(`ahu_graph`))
                //         .chillerData,
                //       label: "chw",
                //       area: true,
                //       stack: "total",
                //       showMark: false,
                //       color: "#A7F3D0",
                //     },
                //   ]}
                //   xAxis={[
                //     {
                //       scaleType: "point",
                //       data: JSON.parse(localStorage.getItem(`ahu_graph`))
                //         .xLabels,
                //     },
                //   ]}
                //   // yAxis={[
                //   //   {
                //   //     scaleType: "linear",
                //   //     data: [100, 75, 50, 25],
                //   //   },
                //   // ]}
                //   sx={{
                //     ".MuiLineElement-root": {
                //       display: "none",
                //     },
                //   }}
                // />

                // <BarChart
                //   dataset={JSON.parse(localStorage.getItem("ahu_graph"))}
                //   xAxis={[{ scaleType: "band", dataKey: "time" }]}
                //   series={[
                //     { dataKey: "chillerData", label: "Chiller Position" },
                //     { dataKey: "setTempData", label: "Set Temp" },
                //     { dataKey: "rTempData", label: "Return Temp" },
                //   ]}
                //   height={300}
                //   sx={{
                //     [`.${axisClasses.left} .${axisClasses.label}`]: {
                //       transform: "translate(-20px, 0)",
                //     },
                //   }}
                // />
              )}
            </div>
          ) : (
            <NoAHUData />
          )}
        </Grid>
        <Grid container item md={4} sm={12} xs={12} rowGap={3}>
          {[
            {
              title: "AHU Status",
              data: ahuStatus,
              lastUpdatedTimeSlot:
                lastFetchedAhuData?.time_slot || lastFetchedAhuData?.time
                  ? `Last data fetched at
                  ${moment(
                    lastFetchedAhuData?.time_slot || lastFetchedAhuData?.time,
                    "HH:mm",
                  ).format("h:mm A")}`
                  : "Last data fetched time : NA",

              date: lastFetchedAhuData?.date,
            },

            {
              title: "Chiller water position",
              data: chilledWaterPos,
              lastUpdatedTimeSlot:
                lastFetchedAhuData?.time_slot || lastFetchedAhuData?.time
                  ? `Last data fetched at
                ${moment(
                  lastFetchedAhuData?.time_slot || lastFetchedAhuData?.time,
                  "HH:mm",
                ).format("h:mm A")}`
                  : "Last data fetched time : NA",
              date: lastFetchedAhuData?.date,
            },
          ].map(({ title, data, lastUpdatedTimeSlot, date }) => (
            <Box className="ahu_cards" key={title}>
              <Box>
                <Typography
                  variant="h4"
                  gutterBottom
                  className="ahu_card_title"
                >
                  {data}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className="ahu_card_description"
                >
                  {!data ? title + " Loading..." : title}
                </Typography>
                {lastUpdatedTimeSlot && date && (
                  <Typography variant="subtitle2" className="ahu_card_time">
                    {lastUpdatedTimeSlot}, {date}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Ahu;
