import {
  Grid,
  Paper,
  Typography,
  TextField,
  Box,
  Divider,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment-timezone";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import LineGraph from "../../components/charts/LineGraph";
import GraphSkeleton from "../../components/loading_skeletons/GraphSkeleton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CardSkeleton from "../../components/loading_skeletons/CardSkeleton";
import {
  AGILE_INSTALLATION_TYPE,
  CLAIRCO_CLIENT_HISTORY_TYPES,
  CLAIRCO_INSTALLATION_TYPE,
} from "../../utils/constants";
import { getIAQData } from "../../utils/Api";
import {
  convertTo12HourFormat,
  getItemFromLocalstroage,
  getTodayDate,
} from "../../utils/helper";
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import "./Iaq.css";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { useAuthContext } from "../../context/AuthContext";
import Occupancy from "./occuopancy/Occupancy";
const Iaq = () => {
  // selected date is for agile installation type
  const [selectedDate, setselectedDate] = useState(
    moment().format("YYYY-MM-DD"),
  );
  const [currentTab, setCurrentTab] = useState("Iaq");
  // interval is for  clairco installation type
  const [interval, setInterval] = useState(
    CLAIRCO_CLIENT_HISTORY_TYPES.LAST_12_HOURS,
  );
  const branchId =
    JSON.parse(localStorage.getItem("schema"))?.[0]?.metaData?.branch_id || "";
  const location = useLocation();
  const { setBreadcrumbs } = useAuthContext();
  const deviceId = location.state?.deviceInfo?.id || "  ";
  // console.log("device info :>> ", location.state.deviceInfo);
  const [temp, setTemp] = useState("NA");
  const [humidity, setHumidity] = useState("NA");
  const [occupancy, setOccupancy] = useState("NA");
  const [lastUpdatedIaqTime, setLastUpdatedIaqTime] = useState("");
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [],
  });

  const [occupancyGraphData, setOccupancyGraphData] = useState({
    labels: [],
    datasets: [],
  });
  const [graphLoading, setGraphLoading] = useState(false);
  const [avgTempandHumLoading, setAvgTempandHumLoading] = useState(true);
  const params = useParams();
  const schema = params.schema;
  const navigate = useNavigate();
  const installationType = getItemFromLocalstroage("installationType");

  // rbac state restriction
  const [isIaqDataViewRestricted, setIsIaqDataViewRestricted] = useState(false);

  const fetchIaqData = async () => {
    if (!installationType) return;
    setGraphLoading(true);
    try {
      let payload;
      if (installationType == AGILE_INSTALLATION_TYPE) {
        payload = {
          branchId,
          deviceId,
          day: selectedDate,
          fromTime: "00:00",
          toTime: "23:59",
          flag_details: {
            installation_type: AGILE_INSTALLATION_TYPE,
          },
        };
      }

      const { data: response } = await getIAQData(payload);
      // console.log(response);
      const dataset = Array.isArray(response)
        ? response.map((device) => device.metaData)
        : response?.data_set || [];

      const labels = dataset.map((data) => data.time_slot);
      const tempValues = dataset.map((data) => data.ambient_temperature);
      const humidityValues = dataset.map((data) => data.humidity);
      setGraphData({
        labels,
        datasets: [
          {
            label: "Temp",
            data: tempValues,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            fill: true,
            pointRadius: 0.6,
            tension: 0.5,
            yAxisID: "iaq_temp",
          },
          {
            label: "Humidity",
            data: humidityValues,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            fill: true,
            pointRadius: 0.6,
            tension: 0.5,
            yAxisID: "iaq_hum",
          },
        ],
      });
      if (selectedDate == getTodayDate()) {
        setAvgTempandHumLoading(true);
        const lastHourValues = dataset.slice(-4);
        // console.log("last hour agile values ", lastHourValues);
        let latestTimeSlot =
          lastHourValues[lastHourValues.length - 1]["time_slot"];
        // console.log("latest time slot ", latestTimeSlot);
        setLastUpdatedIaqTime(convertTo12HourFormat(latestTimeSlot));
        calculateLastHourIAQAvg(lastHourValues);
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        setIsIaqDataViewRestricted(true);
      }
    } finally {
      setGraphLoading(false);
      setAvgTempandHumLoading(false);
    }
  };

  const calculateLastHourIAQAvg = (lastHourValues) => {
    // Temp Calculation
    let countNonNullTempValues = 0;
    const totalTempValueInLastHour = lastHourValues.reduce((acc, data) => {
      if (data?.ambient_temperature || data?.metaData?.ambient_temperature) {
        countNonNullTempValues++;
        return (
          acc + Number(data.ambient_temperature) ||
          acc + Number(data.metaData.ambient_temperature)
        );
      }
      return acc;
    }, 0);
    if (totalTempValueInLastHour) {
      let avgTemp = totalTempValueInLastHour / countNonNullTempValues;
      setTemp(parseInt(avgTemp));
    }

    // Humidity Calculation
    let countNonNullHumValues = 0;
    const totalHumValueInLastHour = lastHourValues.reduce((acc, data) => {
      if (data?.humidity || data?.metaData?.humidity) {
        countNonNullHumValues++;
        return (
          acc + Number(data.humidity) || acc + Number(data.metaData.humidity)
        );
      }
      return acc;
    }, 0);

    if (totalHumValueInLastHour) {
      let avgHum = totalHumValueInLastHour / countNonNullHumValues;
      setHumidity(parseInt(avgHum));
    }

    // Occupancy Calculation
    let countNonNullOccupancyValues = 0;
    const totalOccupancyValueInLastHour = lastHourValues.reduce((acc, data) => {
      if (data?.occupancy_data || data?.metaData?.occupancy_data) {
        countNonNullOccupancyValues++;
        return (
          acc + Number(data.occupancy_data) ||
          acc + Number(data.metaData.occupancy_data)
        );
      }
      return acc;
    }, 0);

    if (totalOccupancyValueInLastHour) {
      let avgOccupancy =
        totalOccupancyValueInLastHour / countNonNullOccupancyValues;
      setOccupancy(parseInt(avgOccupancy));
    }

    return;
  };

  // this useeffect is for agile installation type

  useEffect(() => {
    if (installationType !== AGILE_INSTALLATION_TYPE) return;
    fetchIaqData();
  }, [selectedDate]);

  useEffect(() => {
    setBreadcrumbs([
      { path: `/${params.schema}/iaq/list`, breadcrumbName: "IAQ List" },
      { path: `/${params.schema}/iaq`, breadcrumbName: "IAQ Details" },
    ]);
  }, [location]);

  // this useeffect is for clairco installation type

  useEffect(() => {
    if (installationType !== CLAIRCO_INSTALLATION_TYPE) return;
    const payload = {
      branchId,
      deviceId,
      fetchType: "history",
      flag_details: {
        installation_type: CLAIRCO_INSTALLATION_TYPE,
        interval: interval,
      },
    };
    setGraphLoading(true);
    setAvgTempandHumLoading(true);
    getIAQData(payload)
      .then(({ data }) => {
        const dataset = data?.msg ?? [];
        // console.log(data);
        const labels =
          dataset.map((data) => `${data?.time},${data?.date || ""}`) || [];
        const tempValues =
          dataset.map((data) => data.metaData.ambient_temperature) || [];
        const humidityValues =
          dataset.map((data) => data.metaData.humidity) || [];

        setGraphData({
          labels,
          datasets: [
            {
              label: "Temp",
              data: tempValues,
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
              fill: true,
              pointRadius: 0.6,
              tension: 0.5,
              yAxisID: "iaq_temp",
            },
            {
              label: "Humidity",
              data: humidityValues,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
              fill: true,
              pointRadius: 0.6,
              tension: 0.5,
              yAxisID: "iaq_hum",
            },
          ],
        });

        const lastHourValues = dataset.slice(-30);
        if (!lastHourValues?.length) return;
        calculateLastHourIAQAvg(lastHourValues);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setIsIaqDataViewRestricted(true);
        }
      })
      .finally(() => {
        setGraphLoading(false);
        setAvgTempandHumLoading(false);
      });
  }, [interval]);

  const handleChange = (newValue) => {
    let date =
      newValue["$y"] + "-" + (newValue["$M"] + 1) + "-" + newValue["$D"];
    // console.log(date);
    setselectedDate(date);
  };

  return (
    <>
      <Paper sx={{ padding: 2, marginBottom: 3 }} elevation={0}>
        <CustomBreadcrumb />
        <br />
        <Divider sx={{ mb: 3 }} />
        <Stack direction="row" gap={5} sx={{ flexWrap: "wrap" }}>
          <Stack>
            <Typography
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
              gutterBottom
            >
              {location.state?.deviceInfo?.id ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              DEVICE ID
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
              gutterBottom
            >
              {location.state?.deviceInfo?.friendly_name ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              DEVICE NAME
            </Typography>
          </Stack>

          <Stack>
            <Typography
              gutterBottom
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
            >
              {location.state?.deviceInfo?.location ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              LOCATION
            </Typography>
          </Stack>
        </Stack>
      </Paper>
      {/* <Paper elevation={0}> */}
      <Box
        sx={{
          borderBottom: 3,
          borderColor: "divider",
          background: "white",
          marginBottom: 3,
          boxShadow: "none",
          padding: 2,
        }}
      >
        <Tabs
          value={currentTab}
          onChange={(e, tabIndex) => {
            setCurrentTab(tabIndex);
          }}
          sx={{
            boxShadow: "none",
            "&:active": {
              boxShadow: "none",
            },
          }}
        >
          <Tab label="IAQ" value="Iaq" />
          <Tab label="Occupancy" value="Occupancy" />
        </Tabs>
      </Box>
      {isIaqDataViewRestricted ? (
        <AccessDenied />
      ) : currentTab === "Iaq" ? (
        <Grid container spacing={3}>
          <Grid item md={8} xs={12}>
            <Paper sx={{ p: { sm: "32px", xs: "16px" } }} elevation={0}>
              {installationType == AGILE_INSTALLATION_TYPE ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Select Date"
                    inputFormat="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                    disableFuture={true}
                  />
                </LocalizationProvider>
              ) : (
                <select
                  value={interval}
                  onChange={(e) => setInterval(e.target.value)}
                  style={{ width: "300px" }}
                >
                  <option value="12 hours">12 Hours</option>
                  <option value="1 day">1 Day</option>
                  <option value="3 days">3 Days</option>
                  <option value="7 days">7 Days</option>
                </select>
              )}

              {graphLoading ? (
                <div style={{ padding: "2rem" }}>
                  <GraphSkeleton />
                </div>
              ) : (
                <LineGraph
                  title="Current Consumption"
                  data={graphData}
                  setDate={setselectedDate}
                  temp_flow={false}
                  cooling_energy={false}
                  temp={false}
                  ChWP={false}
                  iaq_temp={true}
                  iaq_hum={true}
                />
              )}
            </Paper>
          </Grid>
          <Grid container item md={4} xs={12} rowGap={3}>
            {avgTempandHumLoading ? (
              <>
                <CardSkeleton />
                <br />
                <CardSkeleton />
              </>
            ) : (
              <>
                <Box className="iaq_cards">
                  <Box>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className="iaq_card_title"
                    >
                      {temp}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className="iaq_card_description"
                    >
                      Temperature (°C){" "}
                    </Typography>
                    {lastUpdatedIaqTime && (
                      <Typography variant="subtitle2" className="iaq_card_time">
                        Last fetched time - {lastUpdatedIaqTime}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box className="iaq_cards">
                  <Box>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className="iaq_card_title"
                    >
                      {humidity}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className="iaq_card_description"
                    >
                      Humidity (%){" "}
                    </Typography>
                    {lastUpdatedIaqTime && (
                      <Typography variant="subtitle2" className="iaq_card_time">
                        Last fetched time - {lastUpdatedIaqTime}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      ) : (
        <Occupancy />
      )}
      {/* </Paper> */}
    </>
  );
};

export default Iaq;
