import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import "./customers.css";
import { ArrowBack, Close } from "@mui/icons-material";
import { addCustomer } from "../../../utils/Api";
import { useAdmin } from "../../../context/AdminContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddCustomer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { setIsCustomerAdded } = useAdmin();

  const onSubmit = async (data, e) => {
    e.preventDefault();
    // console.log(data);
    // TODO add api call to create new customer here
    // const cleanedString = data["metaData"]
    //   .replace(/\n/g, "")
    //   .replace(/\s/g, "");

    const payload = {
      identifier: data["identifier"],
      Name: data["Name"],
      city: data["city"],
      region: data["region"],
      Address: data["Address"],
      pocName: data["poc_name"],
      pocEmail: data["poc_email"],
      pocContact: data["poc_contact"],
      link: data["website"],
      metaData: null,
      logo: null,
    };
    try {
      const { data: response } = await addCustomer(payload);
      if (response) {
        toast("Customer is created successfully");
        setIsCustomerAdded(true);
      } else {
        toast("Failed to Create New Customer");
      }
      reset();
    } catch (error) {
      console.log(`Error while adding customer: ${error}`);
      if (error.response.status === 403) {
        return toast.info(
          "You do not have enough permissions to perform this action",
        );
      }
      toast("An error occurred while creating the customer");
    }
  };

  const customerFormInputs = [
    {
      label: "Customer Name",
      type: "text",
      name: "Name",
      required: true,
      helperText: "",
    },
    {
      label: "Schema",
      type: "text",
      name: "identifier",
      required: true,
      helperText:
        "Enter a three or four letters that would be synonymous with the customer's name",
    },
    {
      label: "City",
      type: "text",
      name: "city",
      required: true,
      helperText: "",
    },
    {
      label: "Address",
      type: "text",
      name: "Address",
      required: true,
      helperText: "",
    },
    {
      label: "Customer's Website",
      type: "text",
      name: "website",
      required: false,
      helperText: "",
    },
    {
      label: "Region",
      type: "text",
      name: "region",
      required: true,
      helperText: "",
    },
    {
      label: "POC Name",
      type: "text",
      name: "poc_name",
      required: false,
      helperText: "",
    },
    {
      label: "POC Email",
      type: "text",
      name: "poc_email",
      required: false,
      helperText: "",
    },
    {
      label: "POC Contact",
      type: "text",
      name: "poc_contact",
      required: false,
      helperText: "",
    },
  ];
  const navigate = useNavigate();
  return (
    <Paper sx={{ padding: "20px" }}>
      <Typography variant="h6" color="primary" gutterBottom>
        <Stack direction="row" alignItems="center">
          <IconButton
            onClick={() => navigate("/customers")}
            sx={{
              background: "#1fa984",
              mr: "20px",
              color: "#fff",
              "&:hover": {
                color: "#fff",
                background: "#1fa984",
              },
            }}
          >
            <ArrowBack />
          </IconButton>
          <span>Add Customer</span>
        </Stack>
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {customerFormInputs.map((input, i) => (
            <Grid item md={4} key={i} xs={12} sm={12}>
              <Box>
                <div>
                  <Typography component="label">
                    {input.label}
                    {input.required && <span style={{ color: "red" }}>*</span>}
                  </Typography>
                  <input
                    className="customer_input"
                    type={input.type}
                    {...register(input.name, {
                      required: input.required,
                    })}
                    style={{ border: "1px solid gainsboro" }}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />

                  {!input.helperText ? null : (
                    <Typography variant="caption">
                      {input.helperText}
                    </Typography>
                  )}
                </div>
              </Box>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box>
              <Typography component="label">Metadata</Typography>
              <textarea
                className="customer_input"
                rows={7}
                {...register("metaData")}
                style={{ border: "1px solid gainsboro" }}
              ></textarea>
            </Box>
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </form>
    </Paper>
  );
};

export default AddCustomer;
