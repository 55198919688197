import React, { useEffect, useState } from "react";
import "./Searchbar.css";
import { Search } from "@mui/icons-material";
import {
  Button,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  ListItemIcon,
  Checkbox,
} from "@mui/material";
import { useDevice } from "../../context/DeviceContext";

const Searchbar = ({
  onApply,
  onReset,
  onSearch,
  searchTerm,
  setSearchTerm,
}) => {
  const [showListItems, setShowListItems] = useState(false);
  const [showFilterList, setShowFilterList] = useState(false);
  const { deviceTypes } = useDevice();
  const [filterList, setFilterList] = useState(["PRODUCTION", "TESTING"]);

  useEffect(() => {
    if (!deviceTypes?.length) return;
    setFilterList(["PRODUCTION", "TESTING", ...deviceTypes]);
  }, [deviceTypes]);

  // Handle search button click
  const handleSearch = () => {
    if (searchTerm.trim() === "") return alert("Please provide text to search");
    // console.log("Searching for:", searchTerm, "in category:", selectedOption);
    setShowFilterList(false);
    onSearch(searchTerm);
  };

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  const handleApplyFilters = () => {
    setShowFilterList(false);
    onApply(selectedFilters);
  };

  const handleResetFilters = () => {
    setShowFilterList(false);
    setSelectedFilters([]);
    onReset();
  };

  const handleClearAllFilters = () => {
    setSelectedFilters([]);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (searchTerm.trim() === "")
        return alert("Please provide text to search");
      setShowFilterList(false);
      onSearch(searchTerm);
    }
  };

  return (
    <div className="input_container">
      <div className="search_box_container">
        <input
          placeholder="Search.."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
          onKeyDown={handleKeyPress}
        />
        <span onClick={handleSearch} className="search_icon">
          <Search />
        </span>
        <div className="filter_container">
          <button onClick={() => setShowFilterList(!showFilterList)}>
            Filter
          </button>
          {showFilterList && (
            <Box
              sx={{
                width: "auto",
                position: "absolute",
                ml: "10px",
                zIndex: 2,
              }}
            >
              <List sx={{ background: "#F5F5F5", width: "200px" }}>
                {filterList.map((filter, index) => (
                  <ListItemButton
                    key={index}
                    onClick={() => {
                      setShowListItems(false);
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        disableRipple
                        onChange={() => handleFilterChange(filter)}
                        checked={selectedFilters.includes(filter)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={filter} />
                  </ListItemButton>
                ))}
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ px: "10px" }}
                >
                  <Button variant="contained" onClick={handleClearAllFilters}>
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ borderRadius: "25px", fontSize: "14px", p: "2px" }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </Button>
                </Stack>
              </List>
            </Box>
          )}
        </div>
        <button onClick={handleResetFilters} className="reset_btn">
          Reset
        </button>
      </div>
    </div>
  );
};

export default Searchbar;
