import { useNavigate } from "react-router-dom";
import { useHeartBeat } from "../../../context/HeatBeatContext";
import { useEffect, useState, memo } from "react";
import { Button, Paper, Box } from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";

const Microservice = () => {
  const navigate = useNavigate();
  const { heartBeatList, heartBeatListLoading, isHeartBeatViewRestricted } =
    useHeartBeat();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (heartBeatList?.length > 0) {
      let columns = [
        { field: "id", headerName: "ID", flex: 1 },
        { field: "name", headerName: "Microservice Name", flex: 1 },
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          renderCell: (params) => {
            if (params.status) {
              <CheckCircle style={{ color: "green" }} />;
            } else {
              <Cancel style={{ color: "red" }} />;
            }
          },
        },
        { field: "lastUpdated", headerName: "Last Updated", flex: 1 },
        {
          field: "action",
          headerName: "Logs",
          flex: 1,
          renderCell: (params) => {
            return (
              <Button variant="contained" disabled>
                View Logs
              </Button>
            );
          },
        },
      ];
      let index = 0;
      const rowFormat = heartBeatList.map((response, index) => {
        index++;
        return {
          id: index,
          name: response.name,
          status: response.status,
          lastUpdated: response.lastUpdated,
        };
      });

      setRows(rowFormat);
      setColumns(columns);
    }
  }, [heartBeatList]);
  if (heartBeatListLoading) return "Microservices List Loading";
  if (isHeartBeatViewRestricted) {
    return <p>You do not have enough permissions to view this page</p>;
  }
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          p: "10px",
          width: "100%",
          overflowX: "auto",
        }}
      >
        <Box sx={{ width: { xs: "900px", md: "100%" }, overflowX: "auto" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 7 },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  name: true,
                  status: true,
                  lastUpdated: true,
                },
              },
            }}
            pageSizeOptions={[7, 10]}
            disableRowSelectionOnClick
            disableColumnSelector
            sx={{
              "& :focus": {
                border: "none",
              },
              "& :active": {
                border: "none",
              },
            }}
          />
        </Box>
      </Paper>
    </>
  );
};

export default memo(Microservice);
