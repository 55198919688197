import React, { useEffect, useState } from "react";
import { Menu } from "@mui/icons-material";
import "./layout.css";
import { Outlet, useNavigate } from "react-router-dom";
import AdminSidebar from "../components/sidebar/AdminSidebar";
import { useAuthContext } from "../context/AuthContext";
import SessionTimeout from "../components/session_timeout/SessionTimeout";
import { axiosInstance } from "../utils/Api";
import { ToastContainer } from "react-toastify";
import {
  capitalizeFirstLetter,
  getItemFromLocalstroage,
} from "../utils/helper";
const Admin = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const { isAdmin, isLoggedIn } = useAuthContext();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [username, setUsername] = useState("");
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (!isLoggedIn) return navigate("/clairco/login", { replace: true });
    if (!isAdmin) return navigate("/clairco/login", { replace: true });
    if (!getItemFromLocalstroage("username")) return;
    setUsername(capitalizeFirstLetter(getItemFromLocalstroage("username")));
  }, [isAdmin, isLoggedIn]);

  useEffect(() => {
    if (!isAdmin || !isLoggedIn) return;
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        if (
          response.data.type == "error" &&
          response.data.title == "Token is invalid"
        ) {
          setSessionExpired(true);
          // console.log(response);
        }
        // console.log(response);
        return response;
      },
    );

    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [isLoggedIn, isAdmin]);

  if (sessionExpired) {
    return <SessionTimeout />;
  }

  return (
    <div className="admin_container">
      <ToastContainer />
      <div
        className={`overlay ${isSidebarOpen ? "open" : ""}`}
        onClick={toggleSidebar}
      ></div>
      <AdminSidebar
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className="admin_content_container">
        <div className="admin_appbar">
          <div className="menu_icon_btn" onClick={toggleSidebar}>
            <Menu />
          </div>
          <p className="admin_appbar_title">
            Welcome, <span>{username || "Admin"} </span>
          </p>
        </div>
        <div className="admin_content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Admin;
