import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance, login } from "../../utils/Api";
import CryptoJS from "crypto-js";
import {
  Box,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  useTheme,
  Alert,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Email, Password } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import jwtDecode from "jwt-decode";
import "./Login.css";
import { useAuthContext } from "../../context/AuthContext";
import { CRYPTO_SECRET_KEY } from "../../utils/constants";
import { fetchSchemaFromLocalStorage, logout } from "../../utils/helper";

const Login = () => {
  const { isLoggedIn, isAdmin, updateAdminStatus, updateLoginStatus } =
    useAuthContext();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [showAuthenticationError, setShowAuthenticationError] = useState(false);
  const params = useParams();
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loginResponseLoading, setLoginResponseLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoginResponseLoading(true);
    const hashedPassword = CryptoJS.AES.encrypt(
      data.password,
      CRYPTO_SECRET_KEY,
    ).toString();

    const response = await login(data.email, hashedPassword, params.schema);
    if (response) {
      if (response.msg && !response.msg.token) {
        setLoginResponseLoading(false);
        setShowAuthenticationError(true);
        setErrorMessage(response.msg.message);
      } else {
        const decodedToken = jwtDecode(response.msg.token);
        updateLoginStatus(true);
        localStorage.setItem(`token`, response.msg.token);
        localStorage.setItem(`logged_in`, true);
        localStorage.setItem(`username`, response.msg.name);
        axiosInstance.defaults.headers[
          "Authorization"
        ] = `Bearer ${response.msg.token}`;

        if (decodedToken.domain == "clairco") {
          localStorage.setItem(`admin`, true);
          updateAdminStatus(true);

          setLoginResponseLoading(false);
          navigate(`/customers`);
        } else {
          localStorage.setItem(`identifier`, params.schema);
          localStorage.setItem(`admin`, false);
          updateAdminStatus(false);
          setLoginResponseLoading(false);
          navigate(`/${params.schema}/overview`);
        }
      }
    } else {
      setLoginResponseLoading(false);
      setErrorMessage(
        "Something went wrong while logging in. Please try again",
      );
      updateAdminStatus(false);
      updateLoginStatus(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn && isAdmin) return navigate("/customers");

    if (isLoggedIn && !isAdmin) {
      const schema = fetchSchemaFromLocalStorage();
      const urlSchema = params.schema || "";
      if (schema !== urlSchema) {
        logout(isAdmin, urlSchema);
      } else {
        navigate(`/${schema}/overview`);
      }
    }
  }, []);

  const navigateToForgotPwd = () => {
    navigate("/forgotpassword", {
      state: params.schema,
    });
  };

  return (
    <Grid container className="login_container">
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <Box className="left_side">
          <img
            src="https://static.wixstatic.com/media/840069_a293e3debec44af58ef18f0305740397~mv2.png/v1/fill/w_188,h_85,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/White%20without%20Clean%20Air%20G.png"
            style={{ margin: "0 auto", position: "absolute", top: 10, left: 0 }}
          />
          <Box>
            <span
              style={{
                fontSize: 50,
                fontWeight: 600,
                letterSpacing: "2px",
              }}
            >
              Clairco
            </span>{" "}
            is committed to helping you save money on energy consumption. By
            logging in, you can access your personalized energy dashboard and
            track your energy usage.
          </Box>
        </Box>
      </Grid>
      <Grid item md={6} xs={12} sm={12}>
        <Box className="right_side">
          <Box
            sx={{
              width: { md: "70%", xs: "100%" },
              margin: "0 auto",
            }}
          >
            <Box
              sx={{
                width: { md: "70%", xs: "100%" },
                margin: "0px auto 20px auto",
              }}
            >
              <Box
                sx={{
                  fontSize: 18,
                  color: theme.palette.secondary.main,
                }}
              >
                <span
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                  }}
                >
                  Good to have you back!
                </span>{" "}
                Let's get you logged in so you can start using our services.
              </Box>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  width: { md: "70%", xs: "100%", sm: "100%" },
                  margin: "0px auto 20px auto",
                }}
              >
                <OutlinedInput
                  size="small"
                  placeholder="johndoe@gmail.com"
                  fullWidth
                  {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Email />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.email && (
                  <span style={{ fontSize: "14px" }}>
                    {errors.email.type === "required"
                      ? "Email is required"
                      : "Invalid email address"}
                  </span>
                )}
              </Box>
              <Box
                sx={{
                  width: { md: "70%", xs: "100%" },
                  margin: "0px auto 20px auto",
                }}
              >
                <OutlinedInput
                  size="small"
                  placeholder="Enter your password"
                  type="password"
                  fullWidth
                  {...register("password", {
                    required: true,
                    pattern: /^\S+$/,
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Password />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.password && (
                  <span style={{ fontSize: "14px" }}>Password is required</span>
                )}
              </Box>

              <Box sx={{ width: { md: "70%", xs: "100%" }, margin: "0 auto" }}>
                {loginResponseLoading ? (
                  <Button
                    sx={{
                      width: "100%",
                      fontSize: 18,
                      fontWeight: 600,
                      background: "#11765b",
                      color: "white",
                      letterSpacing: 2,
                      marginBottom: 5,
                      ":hover": {
                        bgcolor: "#08cbbb",
                        color: "white",
                      },
                    }}
                    disabled
                  >
                    <CircularProgress /> Logging In
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "100%",
                      fontSize: 18,
                      fontWeight: 600,
                      background: "#11765b",
                      color: "white",
                      letterSpacing: 2,
                      marginBottom: 5,
                      ":hover": {
                        bgcolor: "#08cbbb",
                        color: "white",
                      },
                    }}
                    type="submit"
                    className="submit_btn"
                  >
                    Login
                  </Button>
                )}

                {showAuthenticationError && (
                  <Alert severity="error" variant="filled">
                    {errorMessage}
                  </Alert>
                )}
              </Box>
              <Typography
                onClick={navigateToForgotPwd}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  marginBottom: "10px",
                }}
              >
                Forgot Password?
              </Typography>
            </form>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
