import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSchedule } from "../../../context/ScheduleContext";
import { useLocation } from "react-router-dom";
import { useDevice } from "../../../context/DeviceContext";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import "./schedule.css";
import { ToastContainer, toast } from "react-toastify";
import { isValidJSON } from "../../../utils/helper";
import { addSchedule } from "../../../utils/Api";

const Input = ({
  labelName,
  register,
  registerName,
  required,
  placeholder,
  errors,
  type,
  helperText,
}) => {
  return (
    <div>
      <label>
        {required && (
          <span style={{ color: "red", paddingRight: "3px" }}>*</span>
        )}
        {labelName}
      </label>
      <input
        {...register(registerName, {
          required: required ? `${labelName} is required` : false,
        })}
        type={type}
        placeholder={placeholder}
      />
      {helperText && <p id="add_schedule_error">{helperText}</p>}
      {errors[registerName] && (
        <p id="add_schedule_error">{errors[registerName].message}</p>
      )}
    </div>
  );
};

const Select = ({ labelName, register, registerName, required, options }) => {
  return (
    <div>
      <label>
        {required && (
          <span style={{ color: "red", paddingRight: "3px" }}>*</span>
        )}
        {labelName}
      </label>
      <select {...register(registerName)} defaultValue={options[0].value}>
        {options.map((option, index) => (
          <option value={option.name} key={index}>
            {option.title}
          </option>
        ))}
      </select>
    </div>
  );
};
const AddSchedule = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();
  const { setBreadcrumbs } = useDevice();
  const location = useLocation();
  const { fetchLatestScheduleList } = useSchedule();

  useEffect(() => {
    setBreadcrumbs([
      { path: "/schedule", breadcrumbName: "Schedule" },
      { path: "/schedule/add", breadcrumbName: "Add Schedule" },
    ]);
  }, [location]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    let cleanedJsonString = data.schedule.replace(/\s/g, "");
    let cleandedPublishPayload = data.publishPayload.replace(/\s/g, "");
    // if (!isValidJSON(cleandedPublishPayload)) {
    //   setError("publishPayload", {
    //     type: "manual",
    //     message: "Invalid JSON format",
    //   });
    //   return;
    // }
    // data.publishPayload = JSON.parse(cleandedPublishPayload);
    // if (!isValidJSON(cleanedJsonString)) {
    //   setError("schedule", {
    //     type: "manual",
    //     message: "Invalid JSON format",
    //   });
    //   return;
    // }
    data.schedule = JSON.parse(cleanedJsonString);

    const payload = {
      name: data.name,
      scheduleConfig: {
        taskType: data.taskType,
        frequency: data.frequency,
        taskConfig: {
          publishTopic: data.publishTopic,
          publishPayload: data.publishPayload,
        },
        runningDays: data.runningDays,
      },
      schedule: data.schedule,
    };

    // console.log(payload);

    try {
      const { data: apiRespone } = await addSchedule(payload);
      toast.success("Schedule successfully added");
      reset();
      fetchLatestScheduleList();
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        return toast("You do not have enough permissions to add schedule");
      }
      toast("Unable to add schedule. Please try again");
    }
  };

  return (
    <Paper sx={{ p: "20px" }}>
      {/* <ToastContainer /> */}
      <CustomBreadcrumb />
      <Box sx={{ mt: 1 }}>
        <p id="add_schedule_alert_box">
          Fill all the details that are marked with *
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            labelName="Name"
            register={register}
            required={true}
            registerName="name"
            placeholder="Fractal FFU"
            errors={errors}
            type="text"
          />
          <Select
            labelName="Task Type"
            register={register}
            required={true}
            registerName="taskType"
            placeholder="MQTT"
            options={[{ name: "MQTT", title: "MQTT" }]}
            errors={errors}
          />
          <Input
            labelName="Frequency (in minutes)"
            register={register}
            required={true}
            registerName="frequency"
            placeholder="30"
            errors={errors}
            type="number"
          />
          <Input
            labelName="Publish Topic"
            register={register}
            required={true}
            registerName="publishTopic"
            placeholder="/Clairco/ffc/setmode/"
            errors={errors}
            type="text"
          />
          <Input
            labelName="Publish Payload"
            register={register}
            required={true}
            registerName="publishPayload"
            placeholder="{'FCCVALUE': number,'RELAY_SET': number}"
            errors={errors}
            type="text"
            helperText=""
          />
          <Input
            labelName="Running Days"
            register={register}
            required={true}
            registerName="runningDays"
            placeholder="Mon/Tue/Wed/Thu/Fri"
            errors={errors}
            type="text"
          />
          <div>
            <label>
              <span style={{ color: "red", paddingRight: "3px" }}>*</span>
              Schedule
            </label>
            <textarea
              {...register("schedule")}
              id="add_schedule_textarea"
              placeholder="place  your schedule here in json format"
            ></textarea>
            {errors.schedule && (
              <p id="add_schedule_error">{errors.schedule.message}</p>
            )}
          </div>

          <br />
          <button
            type="submit"
            disabled={isSubmitting ? true : false}
            id={isSubmitting ? "add_schedule_btn_disabled" : "add_schedule_btn"}
          >
            {isSubmitting ? "Adding Schedule..." : "Add Schedule"}
          </button>
        </form>
      </Box>
    </Paper>
  );
};

export default AddSchedule;
