import React from "react";
import { useBtuContext } from "../../context/BtuContext";
import { Grid, Typography, Paper, Stack, Box } from "@mui/material";
import { btuTableHeads } from "../../utils/constants";
import TabularData from "../../components/table/TabularData";
import CardSkeleton from "../../components/loading_skeletons/CardSkeleton";
import { Hvac } from "@mui/icons-material";

const BtuConsumption = () => {
  const {
    btuLastHourConsumptionList,
    btuLastHourConsumptionListLoading,
    isBtuLastHourConsumptionViewRestricted,
  } = useBtuContext();
  const btuArray = btuLastHourConsumptionList.map((data) =>
    Object.values(data),
  );

  return (
    <Grid item lg={12} xs={12} xl={12} sx={{ mb: "24px" }}>
      {/* <Typography variant="h6">BTU</Typography>
      <Typography variant="subtitle2" gutterBottom>
        Last Hour Consumption
      </Typography> */}
      <div
        style={{
          display: btuLastHourConsumptionListLoading ? "block" : "none",
        }}
      >
        <CardSkeleton />
      </div>

      {isBtuLastHourConsumptionViewRestricted ? (
        <Paper sx={{ padding: "20px" }} elevation={0}>
          <Typography variant="body1" color="error">
            View is restricted.
          </Typography>
        </Paper>
      ) : (
        <div
          style={{
            display: btuLastHourConsumptionListLoading ? "none" : "block",
          }}
        >
          {/* <TabularData tableHeaders={btuTableHeads} tableBodyData={btuArray} /> */}
          <Paper sx={{ padding: "20px" }} elevation={0}>
            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              sx={{ marginBottom: "12px" }}
            >
              <Box>
                <Hvac sx={{ color: "#344853", fontSize: "30px" }} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#344a53",
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  BTU
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{ color: "gray" }}
                >
                  Last Hour Consumption
                </Typography>
              </Box>
            </Stack>
            <table className="ahu_table">
              <thead>
                <tr>
                  {btuTableHeads.map((item, index) => (
                    <th key={index} className="ahu_table_head">
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {btuArray.length ? (
                  btuArray.map((row) => (
                    <tr>
                      {row.map((cell) => (
                        <td className="ahu_table_cell">{cell}</td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="100%" align="center">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Paper>
        </div>
      )}
    </Grid>
  );
};

export default BtuConsumption;
