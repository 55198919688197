import { useContext, createContext, useState, useEffect } from "react";
import {
  formatAhuDataBasedOnSavings,
  getItemFromLocalstroage,
  getTodayDate,
  setItemInLocalstorage,
} from "../utils/helper";
import {
  addAhuMonthlyData,
  checkIfAhuDatabaseExists,
  openOrCreateAhuDatabase,
} from "../db/query";
import { getAhuData } from "../utils/Api";
import { useAuthContext } from "./AuthContext";
import {
  AGILE_INSTALLATION_TYPE,
  CLAIRCO_INSTALLATION_TYPE,
} from "../utils/constants";

export const AhuContext = createContext();

const AhuContextProvider = ({ children }) => {
  const [schema, setSchema] = useState(null);
  const { isLoggedIn } = useAuthContext();
  const [pilotMode, setPilotMode] = useState(false);
  const [ahuNames, setAhuNames] = useState([]);
  // ahuSavings state is being used in Overview page
  const [ahuSavings, setAhuSavings] = useState([
    {
      title: "Today savings",
      subtitle: "NA",
      description: "NA",
    },
    {
      title: "Weekly savings",
      subtitle: "NA",
      description: "NA",
    },
    {
      title: "Monthly savings",
      subtitle: "NA",
      description: "NA",
    },
  ]);
  const [ahuSavingsLoading, setAhuSavingsLoading] = useState(true);
  const [ahusCurrentDayStatus, setAhusCurrentDayStatus] = useState([]);
  const [ahusCurrentDayStatusLoading, setAhusCurrentDayStatusLoading] =
    useState(true);

  const [isAhuOverviewRestricted, setIsAhuOverviewRestricted] = useState(false);

  const updateStatesOnLogin = () => {
    let schema = getItemFromLocalstroage("schema");
    if (!schema) return;
    schema = JSON.parse(schema);
    if (!Array.isArray(schema)) return;
    let pilotModeStatus = schema?.[0]?.metaData?.pilotMode ?? false;
    setSchema(schema);
    setPilotMode(pilotModeStatus);
    const rawAhuList = schema?.[0].metaData?.devices?.ahus ?? [];
    const ahuNames = rawAhuList?.map((ahu) => ahu?.friendly_name) ?? [];
    setItemInLocalstorage("ahuNames", JSON.stringify(ahuNames));
    setAhuNames(ahuNames);
    return;
  };

  useEffect(() => {
    if (!isLoggedIn) return;

    updateStatesOnLogin();
  }, [isLoggedIn]);

  const populateAhuSavingsCards = (ahu_list) => {
    // console.log(ahu_list);
    if (!ahu_list) return;
    // if (!schema) return;
    let ahus_month_savings = ahu_list.reduce((accumulator, item) => {
      return accumulator + item.month_savings.value;
    }, 0);
    let ahus_week_savings = ahu_list.reduce((accumulator, item) => {
      return accumulator + item.week_savings.value;
    }, 0);
    let ahus_day_savings = ahu_list.reduce((accumulator, item) => {
      return accumulator + item.today_savings.value;
    }, 0);
    const currentDayRunningHours = ahu_list.map(
      (response) =>
        response.everyday_savings[response.everyday_savings.length - 1].hours,
    );

    // let ahu_status_list = ahu_list.map((item) => )
    const ahu_status_list = ahu_list.map((item) => {
      // Get the last savings object within each object
      const lastSavingsObj =
        item.everyday_savings[item.everyday_savings.length - 1];
      return lastSavingsObj.ahuStatus; // Retrieve the val property
    });
    // console.log("status list ", ahu_status_list);
    let currentDayStatusArray = [];
    // setAhusCurrentDayStatus(currentDayStatusArray);
    let ahuNameList = getItemFromLocalstroage("ahuNames")
      ? JSON.parse(getItemFromLocalstroage("ahuNames"))
      : [];

    ahuNameList.forEach((ahu_name, i) => {
      currentDayStatusArray.push({
        name: ahu_name || "NA",
        status: ahu_status_list[i] ?? "NA",
        hours:
          ahu_list[i]?.everyday_savings[
            ahu_list[i]?.everyday_savings.length - 1
          ]?.hours?.toFixed(1) ?? "NA",
      });
    });

    // console.log(currentDayStatusArray);

    setAhusCurrentDayStatus(currentDayStatusArray);
    setAhuSavings([]);
    setAhuSavings([
      updateAhuSavingsCards(
        "Today Savings",
        ahu_list?.[0]?.today_savings?.date_range,
        ahus_day_savings,
        pilotMode,
      ),
      updateAhuSavingsCards(
        "Weekly Savings",
        ahu_list?.[0]?.week_savings?.date_range,
        ahus_week_savings,
        pilotMode,
      ),
      updateAhuSavingsCards(
        "Monthly Savings",
        ahu_list?.[0]?.month_savings?.date_range,
        ahus_month_savings,
        pilotMode,
      ),
    ]);
    return;
  };
  const calculateAhuSavings = async (formattedAhuList) => {
    // console.log("formatted ahu list => ", formattedAhuList);
    const installationType = getItemFromLocalstroage("installationType");
    setAhusCurrentDayStatusLoading(true);
    setAhuSavingsLoading(true);
    const dbExists = await checkIfAhuDatabaseExists();

    if (!dbExists) {
      try {
        await openOrCreateAhuDatabase();
        const requests = formattedAhuList.map((ahu) => {
          if (installationType == AGILE_INSTALLATION_TYPE) {
            return getAhuData({
              branchId: ahu.branchId,
              deviceId: ahu.deviceId,
              day: getTodayDate(),
              flag_details: {
                installation_type: AGILE_INSTALLATION_TYPE,
              },
            });
          }
          if (installationType == CLAIRCO_INSTALLATION_TYPE) {
            return getAhuData({
              deviceId: ahu.deviceId,
              fetchType: "history",
              flag_details: {
                interval: "24 hours",
                installation_type: CLAIRCO_INSTALLATION_TYPE,
              },
            });
          }
        });
        const ahuResponse = await Promise.allSettled(requests);
        // console.log("AHU RESPONSE => ", ahuResponse);
        let responses = ahuResponse.map((response) => response.value);
        // const unit_sts = responses.map((response) => {
        //   return response.data.msg.filter((item) => item.metaData.unit_st == 1);
        // });
        // console.log(responses);
        // console.log("unit st ", unit_sts);
        await addAhuMonthlyData({ data: responses });
        if (!responses.length) return;
        // console.log("responses before formatting ", responses);
        responses = responses.filter((response) => response !== null);
        // console.log("filtered responses ", responses);
        const formattedAhuDataBasedOnSavings = formatAhuDataBasedOnSavings(
          responses,
          installationType,
        );

        setItemInLocalstorage(
          "ahus",
          JSON.stringify(formattedAhuDataBasedOnSavings),
        );
        updateStatesOnLogin();

        populateAhuSavingsCards(formattedAhuDataBasedOnSavings);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 403) {
          setIsAhuOverviewRestricted(true);
        }
      } finally {
        setAhusCurrentDayStatusLoading(false);
        setAhuSavingsLoading(false);
      }
    } else {
      // console.log("else block executed");
      const ahus = getItemFromLocalstroage("ahus")
        ? JSON.parse(getItemFromLocalstroage("ahus"))
        : [];
      // console.log("Ahus => ", ahus);
      if (!ahus?.length) {
        setAhusCurrentDayStatusLoading(false);
        setAhuSavingsLoading(false);
        return;
      }
      populateAhuSavingsCards(ahus);
      setAhusCurrentDayStatusLoading(false);
      setAhuSavingsLoading(false);
    }
  };

  return (
    <AhuContext.Provider
      value={{
        calculateAhuSavings,
        ahusCurrentDayStatus,
        ahuSavings,
        ahusCurrentDayStatusLoading,
        ahuSavingsLoading,
        isAhuOverviewRestricted,
      }}
    >
      {children}
    </AhuContext.Provider>
  );
};

const updateAhuSavingsCards = (title, subtitle, ahus_savings, pilotMode) => {
  return {
    title,
    subtitle,
    description:
      pilotMode == "true" || true
        ? "NA"
        : ahus_savings.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          }),
  };
};

export const useAhuContext = () => useContext(AhuContext);

export default AhuContextProvider;

const data = [
  {
    savings: [
      {
        day: "23 Mar",
        val: "255",
      },
      {
        day: "24 Mar",
        val: "256",
      },
    ],
  },
  {
    savings: [
      {
        day: "23 Mar",
        val: "285",
      },
      {
        day: "24 Mar",
        val: "278",
      },
    ],
  },
];
