import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  ArcElement,
} from "chart.js";
import { Box, Card } from "@mui/material";
import moment from "moment-timezone";
import { getItemFromLocalstroage } from "../../utils/helper";
import { CLAIRCO_INSTALLATION_TYPE } from "../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
    },
    tooltip: {
      callbacks: {
        label: (data) => {
          if (data.dataset.label === "Chiller Water Position") {
            return data.formattedValue + "%";
          }
          if (
            data.dataset.label === "Stemp" ||
            data.dataset.label === "Rtemp" ||
            data.dataset.label === "Temp In" ||
            data.dataset.label === "Temp Out"
          ) {
            return data.formattedValue + "°C";
          }
        },
        title: (tooltipItem) => {
          // console.log(tooltipItem);
          let val = tooltipItem[0].label;
          let twentyFourHourFormatTime = moment(val, "HH:mm");
          const formattedTime = twentyFourHourFormatTime.format("h:mm A");
          // console.log({ formattedTime });
          return formattedTime;
        },
      },
    },
    subtitle: {
      display: true,
      text: "Current Consumption",
      font: {
        size: 18,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 20,
        display: true,
      },
    },
    temp: {
      type: "linear",
      position: "left",
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 3,
        display: true,
      },

      title: {
        display: true,
        text: "Rtemp & Stemp (°C)",
      },
    },
    temp_flow: {
      type: "linear",
      position: "left",
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 3,
        display: true,
      },

      title: {
        display: true,
        text: "Temp In & Temp Out (°C)",
      },
    },
    ChWP: {
      type: "linear",
      position: "right",
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 20,
        display: true,
      },
      title: {
        display: true,
        text: "Chilled Water Position (%)",
      },
    },
    cooling_energy: {
      type: "linear",
      position: "right",
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 22000,
        display: true,
      },
      title: {
        display: true,
        text: "Cooling Energy",
      },
    },
  },
};
const LineGraph = ({
  data,
  title,
  setDate,
  temp_flow,
  cooling_energy,
  temp,
  ChWP,
  iaq_temp,
  iaq_hum,
  iaq_occupancy,
}) => {
  const [value, setValue] = useState(dayjs());
  const installationType = getItemFromLocalstroage("installationType");
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (data) => {
            if (data.dataset.label === "Chiller Water Position") {
              return data.formattedValue + "%";
            }
            if (
              data.dataset.label === "Stemp" ||
              data.dataset.label === "Rtemp" ||
              data.dataset.label === "Temp In" ||
              data.dataset.label === "Temp Out"
            ) {
              return data.formattedValue + "°C";
            }
          },
          title: (tooltipItem) => {
            if (installationType === CLAIRCO_INSTALLATION_TYPE) {
              const dateTime = moment(
                tooltipItem[0].label,
                "HH:mm:ss,YYYY-MM-DD",
              );

              // Format time (hours and minutes) with AM/PM indicator
              const formattedTime = dateTime.format("hh:mm A");

              // Format date to show only day and month
              const formattedDate = dateTime.format("DD MMM");
              const result = `${formattedTime}, ${formattedDate}`;

              return result;
            }
            let val = tooltipItem[0].label;
            let twentyFourHourFormatTime = moment(val, "HH:mm");
            const formattedTime = twentyFourHourFormatTime.format("h:mm A");
            return formattedTime;
          },
        },
      },
      subtitle: {
        display: true,
        text: "Current Consumption",
        font: {
          size: 18,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: 10,
          // stepSize: 0.2,
          stepSize: 5,
        },
      },
      temp: {
        type: "linear",
        position: "left",
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 3,
          display: true,
        },

        title: {
          display: true,
          text: "Rtemp & Stemp (°C)",
        },
        display: temp ? true : false,
      },
      temp_flow: {
        type: "linear",
        position: "left",
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 3,
          display: true,
        },

        title: {
          display: true,
          text: "Temp In & Temp Out (°C)",
        },
        display: temp_flow ? true : false,
      },
      ChWP: {
        type: "linear",
        position: "right",
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 20,
          display: true,
        },
        title: {
          display: true,
          text: "Chilled Water Position (%)",
        },
        display: ChWP ? true : false,
      },
      cooling_energy: {
        type: "linear",
        position: "right",
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 5000,
          display: true,
        },
        title: {
          display: true,
          text: "Cooling Energy (KWh)",
        },
        display: cooling_energy ? true : false,
      },
      iaq_temp: {
        type: "linear",
        position: "right",
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 5000,
          display: true,
        },
        title: {
          display: true,
          text: "Temperature (°C)",
        },
        display: iaq_temp ? true : false,
      },
      iaq_hum: {
        type: "linear",
        position: "left",
        suggestMin: 0,
        suggestMax: 100,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 5,
          display: true,
        },
        title: {
          display: true,
          text: "Humidity (%)",
        },
        display: iaq_hum ? true : false,
      },
      iaq_occupancy: {
        type: "linear",
        position: "left",
        suggestMin: 0,
        suggestMax: 100,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 5,
          display: true,
        },
        title: {
          display: true,
          text: "Occupancy",
        },
        display: iaq_occupancy ? true : false,
      },
    },
  };

  return (
    <Box sx={{ overflowX: "auto" }} elevation={0}>
      <Box
        sx={{
          width: {
            md: "100%",
            sm: "100%",
            xs: "500px",
          },
        }}
      >
        <Line options={options} data={data} />
      </Box>
    </Box>
  );
};

export default LineGraph;
