import React from "react";
import { useAuthContext } from "../../context/AuthContext";
import "./sessiontimeout.css";
import { useParams } from "react-router-dom";
import { logout } from "../../utils/helper";
const SessionTimeout = () => {
  const { isAdmin } = useAuthContext();
  const params = useParams();

  const handleLogout = async () => {
    const schema = params.schema;
    logout(isAdmin, schema);
  };

  return (
    <div className="session_timeout_container">
      <img src={require("../../images/Clairco.png")} className="logo" />
      <div>
        <h1>Session Expired</h1>
        <img src={require("../../images/session-timeout.png")} />
        <p>Your session has expired. Please log in to continue.</p>
        <button onClick={handleLogout}>LOGIN</button>
      </div>
    </div>
  );
};

export default SessionTimeout;
