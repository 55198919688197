import React from "react";
import "./AccessDenied.css";

const AccessDenied = () => {
  return (
    <div className="access-denied-container">
      <img
        src={require("../../images/403.png")}
        className="access-denied-image"
      />
      <p className="access-denied-message">
        <b style={{ color: "red" }}>Access Denied!</b> You do not have enough
        permissions to access this page. If you believe you should have access,
        please email at <b>ashish.kumar@clairco.in </b>
      </p>
    </div>
  );
};

export default AccessDenied;
