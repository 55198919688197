import Dexie from "dexie";
import db from "./ahu";

export const getAhuMonthlyData = async () => {
  return db.monthlyData.toArray();
};

export const addAhuMonthlyData = async (data) => {
  try {
    await clearAhu();
    return db.monthlyData.add(data);
  } catch (error) {
    console.log("Error in adding data: ", error);
    throw error;
  }
};

export const clearAhu = async () => {
  try {
    await db.monthlyData.clear();
  } catch (error) {
    console.error("Error clearing ahu:", error);
  }
};

export const openOrCreateAhuDatabase = async () => {
  try {
    // Use Dexie's open() method to open or create the database
    await db.open();
  } catch (error) {
    console.error("Error opening or creating database:", error);
    throw error;
  }
};

export const checkIfAhuDatabaseExists = async () => {
  try {
    const exists = await Dexie.exists("ahu");

    if (exists) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking database existence:", error);
    throw error;
  }
};

export const deleteAhuDatabase = async () => {
  try {
    // Use Dexie's delete() method to delete the specific database
    await Dexie.delete("ahu");
  } catch (error) {
    console.error("Error deleting database:", error);
  }
};
