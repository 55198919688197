export const SCHEMA_STATUS = ["loading", "successful", "failed"];

export const ahuTableHeads = ["Name", "Status", "Duration"];

export const btuTableHeads = ["Name", "Consumption"];

export const INITIAL_IAQ_STATE = [
  {
    pollutant: "Temp",
    value: "NA",
    units: "(°C)",
  },
  {
    pollutant: "Hum",
    value: "NA",
    units: "(%)",
  },
  {
    pollutant: "PM 2.5",
    value: "NA",
    units: "(µg/m³)",
  },
  {
    pollutant: "PM 10",
    value: "NA",
    units: "(µg/m³)",
  },
  {
    pollutant: "CO₂",
    value: "NA",
    units: "(ppm)",
  },
  {
    pollutant: "VOC",
    value: "NA",
    units: "(ppb)",
  },
  {
    pollutant: "AQI",
    value: "NA",
    units: "No units",
  },
];

export const DEVICE_TYPES = {
  thSens: "Thsens",
  dpt: "DPT",
  iaq: "iaq",
  switch: "Switch",
  ahu: "ahu",
};

export const CRYPTO_SECRET_KEY = "erlk@65$}*!F7EO%-#+C^=Qi$P/^";

export const AGILE_INSTALLATION_TYPE = "agile";

export const CLAIRCO_INSTALLATION_TYPE = "clairco";

export const CLAIRCO_CLIENT_HISTORY_TYPES = {
  LAST_HOUR: "1 hour",
  LAST_12_HOURS: "12 hours",
  LAST_24_HOURS: "24 hours",
};

export const CLAIRCO_SCHEMA = "clairco";

export const COLORS = {
  BLACK_SHADE: "#282C35",
  PRIMARY_COLOR: "#008675",
  GRAY_COLOR: "#E3E3E3",
};

export const FONT_FAMILIES = {
  BEBAS_NEUE: "Bebas Neue, sans-serif",
  DMSANS: "DMSans",
};
