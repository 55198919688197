import React from "react";
import { useIaqContext } from "../../context/IaqContext";
import { Box, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import CardSkeleton from "../../components/loading_skeletons/CardSkeleton";
import { RoomPreferences } from "@mui/icons-material";
const IaqDisplay = () => {
  const { iaqData, hourlyIaqDataLoading, isIaqHourlyAvgViewRestricted } =
    useIaqContext();
  const theme = useTheme();

  return (
    <Grid item xl={12} md={12} xs={12}>
      {isIaqHourlyAvgViewRestricted ? (
        <Paper sx={{ padding: "20px" }} elevation={0}>
          <Box>
            <Typography variant="h6" sx={{ color: theme.palette.primary.dark }}>
              Air Quality
            </Typography>
            <Typography variant="body1" color="error">
              View is restricted.
            </Typography>
          </Box>
        </Paper>
      ) : (
        <Paper sx={{ padding: "20px" }} elevation={0}>
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            sx={{ marginBottom: "12px" }}
          >
            <Box>
              <RoomPreferences sx={{ color: "#344853", fontSize: "30px" }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#344a53",
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Air Quality
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ color: "gray" }}
              >
                Displayed values are based on the calculated average
              </Typography>
            </Box>
          </Stack>
          <div
            style={{
              display: hourlyIaqDataLoading ? "block" : "none",
            }}
          >
            <CardSkeleton />
          </div>
          <Grid
            container
            columnSpacing={2}
            rowSpacing={2}
            sx={{
              display: !hourlyIaqDataLoading ? "" : "none",
            }}
          >
            {iaqData?.length > 0 ? (
              iaqData.map((iaq, index) => (
                <Grid item md={3} sm={3} xs={6} key={index}>
                  <Stack
                    sx={{
                      width: "100%",
                      borderRadius: "5px",
                      background: iaq.value !== "NA" ? "#008675" : "#f0f0f0",
                      padding: "10px",
                      color: iaq.value !== "NA" ? "white" : "black",
                    }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      gutterBottom
                      sx={{
                        color: iaq.value !== "NA" ? "whitesmoke" : "gray",
                      }}
                    >
                      <b>{iaq.value}</b>
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: iaq.value !== "NA" ? "whitesmoke" : "gray",
                      }}
                    >
                      {iaq.pollutant}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: iaq.value !== "NA" ? "whitesmoke" : "gray",
                      }}
                    >
                      {iaq.units}
                    </Typography>
                  </Stack>
                </Grid>
              ))
            ) : (
              <Box sx={{ padding: "20px", width: "100%" }}>
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  Not Available
                </Typography>
              </Box>
            )}
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

export default IaqDisplay;
