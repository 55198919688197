import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./DeviceDetails.css";
import { Grid } from "@mui/material";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ToastContainer, toast } from "react-toastify";
import { controlDevice, getLatestDeviceData } from "../../../utils/Api";
const Input = ({ label, register, name, type, isSubmitting, defaultValue }) => {
  // console.log(defaultValue);
  return (
    <div>
      <label>{label}</label>
      <input
        {...register(name, {})}
        type={type}
        disabled={isSubmitting}
        step="any"
        defaultValue={defaultValue}
      />
    </div>
  );
};

const Select = ({
  label,
  register,
  name,
  options,
  isSubmitting,
  defaultValue,
}) => {
  return (
    <div>
      <label>{label}</label>
      <select
        {...register(name)}
        defaultValue={defaultValue}
        disabled={isSubmitting}
      >
        {options.map((option, index) => (
          <option value={option.name} key={index}>
            {option.title}
          </option>
        ))}
      </select>{" "}
    </div>
  );
};

const UpdateAhu = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm();
  const device = useLocation().state.device;
  const [ahuStatus, setAhuStatus] = useState("");
  const [temp, setTemp] = useState("");
  const [isError, setIsError] = useState(false);
  const onSubmit = async (data, e) => {
    e.preventDefault();

    if (
      !device?.metaData?.actions?.power?.commandType ||
      !device?.metaData?.actions?.setTemp?.commandType
    ) {
      // console.log("This device does not have enought control access");
      toast("This device does not have enough control access");
      return;
    }

    // console.log(device);
    data.setTemp = data.setTemp ? data.setTemp * 10 : 0;
    // console.log(data);

    const deviceONOFFPayload = {
      deviceId: device.id,
      commandType: device.metaData.actions.power.commandType,
      command: {
        value: data.power,
      },
    };

    try {
      const { data } = await controlDevice(deviceONOFFPayload);
    } catch (error) {
      console.log(error);
      if (error.code === "ECONNABORTED") {
        toast("Problem! Cannot connect to the device");
      } else if (error.request) {
        console.log("Request error:", error.request);
        toast("Request error, please check your network.");
      } else if (error.response) {
        console.log("Response error:", error.response.data);
        console.log("Status:", error.response.status);
        toast(error.response?.data?.msg || error.response.data);
      } else {
        toast("An unexpected error occurred. Please try again later");
      }
    }
  };

  useEffect(() => {
    getLatestDeviceData(device.id).then((response) => {
      if (!response) {
        return toast("Unable to fetch live data for this device");
      }
      // setIsError(true);
      // console.log(response.msg.metaData);
      setAhuStatus(response.msg.metaData?.unit_st);
      setTemp(response.msg.metaData?.stemp);
    });
  }, [useLocation().state.device]);

  return (
    <div className="ahu_details">
      {/* <ToastContainer /> */}
      <Link to={"/device/details"} className="back_btn">
        <span>
          <ArrowBackIosIcon />
        </span>
        <span>Previous</span>
      </Link>
      {isError ? (
        <div>
          <p>There is some issue with the device</p>
        </div>
      ) : (
        <>
          <h4>Update Device Values</h4>
          {/* <h5>{device?.metaData?.name ?? ""}</h5> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={5}>
                {device?.metaData?.actions?.power?.value && (
                  <Select
                    label="Power"
                    register={register}
                    name="power"
                    options={[
                      { name: "1", title: "ON" },
                      { name: "0", title: "OFF" },
                    ]}
                    isSubmitting={isSubmitting}
                    defaultValue={0}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} lg={5}>
                {device?.metaData?.actions?.setTemp?.value && (
                  <Input
                    label="Set Temperature"
                    register={register}
                    name="setTemp"
                    type="number"
                    isSubmitting={isSubmitting}
                    defaultValue={temp}
                  />
                )}
              </Grid>
            </Grid>
            <button
              type="submit"
              className={
                isSubmitting ? "update_ahu_btn_disabled" : "update_ahu_btn"
              }
            >
              {isSubmitting ? "Saving Changes..." : "Save Changes"}
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default UpdateAhu;
