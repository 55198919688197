import { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "./AuthContext";
import {
  editDevice,
  editMultiDeviceData,
  getDevices,
  getHistoricalDeviceData,
  getLatestDeviceData,
} from "../utils/Api";

export const DeviceContext = createContext();

const DeviceContextProvider = ({ children }) => {
  const [deviceList, setDeviceList] = useState(
    JSON.parse(localStorage.getItem("device_list")) || [],
  );

  const [deviceListLoading, setDeviceListLoading] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState("");
  const { isAdmin } = useAuthContext();
  const [deviceTypes, setDeviceTypes] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [tableHead, setTableHead] = useState([]);
  const { breadcrumbs, setBreadcrumbs } = useAuthContext();
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState(null);

  // Restriction states
  const [isDeviceListViewRestricted, setIsDeviceListViewRestricted] =
    useState(false);

  const fetchDevices = () => {
    getDevices({ queryCol: "all" })
      .then(({ data }) => {
        localStorage.setItem("device_list", JSON.stringify(data.msg));
        setDeviceList(data.msg);
        setDeviceListLoading(false);
        if (!data || !data?.msg) return;
        let deviceTypeList = [
          ...new Set(data.msg.map((item) => item?.metaData?.device_type)),
        ];
        deviceTypeList = deviceTypeList.filter(
          (device) => device !== null && device !== undefined,
        );
        // console.log(deviceTypeList);
        setDeviceTypes(deviceTypeList);
      })
      .catch((error) => {
        console.log(error);
        setDeviceListLoading(false);
        if (error?.response?.status === 403) {
          setIsDeviceListViewRestricted(true);
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("device_list") && isAdmin) {
      setDeviceListLoading(true);
      const data = JSON.parse(localStorage.getItem("device_list"));
      // console.log(data);
      const deviceTypeList = [
        ...new Set(data.map((item) => item?.metaData?.device_type)),
      ];
      // console.log(deviceTypeList);
      setDeviceList(data);
      setDeviceTypes(deviceTypeList.filter((type) => type !== undefined));
      setDeviceListLoading(false);
    } else if (!localStorage.getItem("device_list") && isAdmin) {
      fetchDevices();
    }
  }, [isAdmin]);

  const updateDevice = async ({ deviceId, queryCol, queryCon }) => {
    try {
      const { data: response } = await editDevice({
        deviceId,
        queryCol,
        queryCon,
      });
      if (response) return true;
      return false;
    } catch (error) {
      console.log(error);
      // if (error.response.status === 403) {
      //   alert("You do not have enough permissions to perform this action");
      // }
      return false;
    }
  };

  const fetchLatestDeviceData = async (deviceId) => {
    try {
      const { data: response } = await getLatestDeviceData(deviceId);
      if (response) return response;
      return null;
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        alert("You do not have enough permissions to perform this action");
      }
      return null;
    }
  };

  const fetchHistoricalDeviceData = async (deviceId, interval) => {
    const { data: response } = await getHistoricalDeviceData(
      deviceId,
      interval,
    );
    if (response) return response;
    return null;
  };

  const updateMultiDeviceData = async (deviceId, queryCon) => {
    try {
      const { data: response } = await editMultiDeviceData(deviceId, queryCon);
      if (response) return response;
      return null;
    } catch (error) {
      throw error;
    }
  };

  return (
    <DeviceContext.Provider
      value={{
        deviceList,
        deviceListLoading,
        selectedDevice,
        setSelectedDevice,
        deviceTypes,
        filteredItems,
        setFilteredItems,
        tableHead,
        setTableHead,
        updateDevice,
        fetchDevices,
        fetchLatestDeviceData,
        fetchHistoricalDeviceData,
        updateMultiDeviceData,
        breadcrumbs,
        setBreadcrumbs,
        selectedDeviceDetails,
        setSelectedDeviceDetails,
        isDeviceListViewRestricted,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => useContext(DeviceContext);
export default DeviceContextProvider;
