import { useContext, createContext, useState, useEffect } from "react";
import { fetchAllRoles, getCustomers } from "../utils/Api";
import { useAuthContext } from "./AuthContext";

export const AdminContext = createContext();

const AdminContextProvider = ({ children }) => {
  const [customerList, setCustomerList] = useState(
    JSON.parse(localStorage.getItem("customer_list")) || [],
  );
  const [customerListLoading, setCustomerListLoading] = useState(true);
  const { isAdmin } = useAuthContext();
  const [isCustomerAdded, setIsCustomerAdded] = useState(false);

  // adminView is used to view the client dashboard as an Admin
  const [adminView, setAdminView] = useState(false);

  const [selectedClient, setSelectedClient] = useState(
    localStorage.getItem("selectedClient") || "",
  );

  // state that determines the user's restriction
  const [isRestricted, setIsRestricted] = useState(false);

  // state to fetch roles
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    if (!isAdmin) return;

    fetchAllRoles()
      .then(({ data }) => {
        // console.log(data);
        // console.log(data.role);
        setRoles(data.response.role);
      })
      .catch((err) => console.log(err));
  }, [isAdmin]);

  useEffect(() => {
    if (
      localStorage.getItem("customer_list") &&
      isAdmin &&
      isCustomerAdded == false
    ) {
      setCustomerListLoading(true);
      const data = JSON.parse(localStorage.getItem("customer_list"));
      setCustomerList(data);
      setCustomerListLoading(false);
    }
    if (
      isAdmin &&
      isCustomerAdded == true &&
      localStorage.getItem("customer_list")
    ) {
      getCustomers()
        .then(({ data }) => {
          localStorage.setItem("customer_list", JSON.stringify(data));
          setCustomerList(data);
          setCustomerListLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setCustomerListLoading(false);
          if (error.response.status === 403) {
            setIsRestricted(true);
          }
        });
    }
    if (!localStorage.getItem("customer_list") && isAdmin) {
      getCustomers()
        .then(({ data }) => {
          localStorage.setItem("customer_list", JSON.stringify(data));
          setCustomerList(data);
          setCustomerListLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setCustomerListLoading(false);
          if (error.response.status === 403) {
            setIsRestricted(true);
          }
        });
    }
  }, [isAdmin, isCustomerAdded]);

  const changePilotMode = async (schema, metaData) => {};
  // console.log(customerList);

  return (
    <AdminContext.Provider
      value={{
        customerList,
        customerListLoading,
        adminView,
        setAdminView,
        setSelectedClient,
        selectedClient,
        setIsCustomerAdded,
        isRestricted,
        roles,
        setCustomerListLoading,
        setCustomerList,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);

export default AdminContextProvider;
