import { Grid } from "@mui/material";
import AhuSavings from "./AhuSavings";
import AhuStatus from "./AhuStatus";
import BtuConsumption from "./BtuConsumption";
import EnBtu from "./EnBtu";
import IaqDisplay from "./IaqDisplay";

const Overview = () => {
  return (
    <>
      <AhuSavings />
      {/* <Grid container spacing={3} sx={{ mb: "20px" }}>
        <AhuStatus />
        <BtuConsumption />
      </Grid>
      <Grid container spacing={3}>
        <EnBtu />
        <IaqDisplay />
      </Grid> */}
      <Grid container spacing={3} sx={{ mb: "20px" }}>
        <Grid item lg={6} xs={12}>
          <AhuStatus />
          <EnBtu />
        </Grid>
        <Grid item lg={6} xs={12}>
          <BtuConsumption />
          <IaqDisplay />
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
