import React from "react";
import { useDevice } from "../context/DeviceContext";
import {
  Navigate,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

const CustomBreadcrumb = () => {
  //   const { breadcrumbs } = useDevice();
  const { breadcrumbs, isAdmin } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  if (!isAdmin) {
    return (
      <div>
        {breadcrumbs.map((breadcrumb, index) => (
          <span
            key={index}
            onClick={() =>
              navigate(`${breadcrumb.path}`, {
                state: location.state,
              })
            }
            style={{
              fontWeight: index === breadcrumbs.length - 1 ? "bold" : "normal",
              cursor: "pointer",
              color: index == breadcrumbs.length - 1 ? "#008675" : "",
              fontSize: "14px",
            }}
          >
            {breadcrumb.breadcrumbName}

            {index < breadcrumbs.length - 1 && " / "}
          </span>
        ))}
      </div>
    );
  }

  return (
    <div>
      {breadcrumbs.map((breadcrumb, index) => (
        <span key={index}>
          <RouterLink
            to={`${breadcrumb.path}`}
            style={{
              fontWeight: index === breadcrumbs.length - 1 ? "bold" : "normal",
            }}
            state={location.state}
          >
            {breadcrumb.breadcrumbName}
          </RouterLink>
          {index < breadcrumbs.length - 1 && " / "}
        </span>
      ))}
    </div>
  );
};

export default CustomBreadcrumb;
