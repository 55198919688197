import { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "./AuthContext";
import { getHeartBeatList } from "../utils/Api";

export const HeartBeatContext = createContext();

const HeartBeatContextProvider = ({ children }) => {
  const [heartBeatList, setHeartBeatList] = useState([]);

  const [heartBeatListLoading, setHeartBeatListLoading] = useState(true);
  const { isAdmin } = useAuthContext();

  // role based restriction

  const [isHeartBeatViewRestricted, setIsHeartBeatViewRestricted] =
    useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) return;
    if (localStorage.getItem("heartbeat_list") && isAdmin) {
      setHeartBeatListLoading(true);
      const data = JSON.parse(localStorage.getItem("heartbeat_list")) || [];
      setHeartBeatList(data);
      setHeartBeatListLoading(false);
    } else if (!localStorage.getItem("heartbeat_list") && isAdmin) {
      getHeartBeatList()
        .then(({ data }) => {
          localStorage.setItem("heartbeat_list", JSON.stringify(data.message));
          setHeartBeatList(data.message);
          setHeartBeatListLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setHeartBeatListLoading(false);
          if (error.response.status === 403) {
            setIsHeartBeatViewRestricted(true);
          }
        });
    }
  }, [isAdmin]);

  return (
    <HeartBeatContext.Provider
      value={{
        heartBeatList,
        heartBeatListLoading,
        isHeartBeatViewRestricted,
      }}
    >
      {children}
    </HeartBeatContext.Provider>
  );
};

export const useHeartBeat = () => useContext(HeartBeatContext);
export default HeartBeatContextProvider;
