import "./App.css";
import { Routes, Route } from "react-router-dom";
import Client from "./layout/Client";
import Admin from "./layout/Admin";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import React from "react";
import { useUserContext } from "./context/UserContext";
import Overview from "./pages/overview/Overview";
import AhuList from "./pages/ahu/AhuList";
import BtuList from "./pages/btu/BtuList";
import Ahu from "./pages/ahu/Ahu";
import Btu from "./pages/btu/Btu";
import "./fonts.css";
import IaqList from "./pages/IAQ/IaqList";
import Iaq from "./pages/IAQ/Iaq";
import Customers from "./pages/admin/customers/Customers";
import ImageAnnotation from "./pages/admin/imageAnnotations/ImageAnnotation";
import Device from "./pages/admin/devices/Device";
import Schedule from "./pages/admin/schedule/Schedule";
import ScheduleDetails from "./pages/admin/schedule/ScheduleDetails";
import CustomerDetails from "./pages/admin/customerDetails/CustomerDetails";
import AddCustomer from "./pages/admin/customers/AddCustomer";
import DeviceDetails from "./pages/admin/deviceDetails/DeviceDetails";
import EditDevice from "./pages/admin/forms/device/EditDevice";
import AddSchedule from "./pages/admin/schedule/AddSchedule";
import EditDeviceLimits from "./pages/admin/forms/device/EditDeviceLimits";
import DeviceHistory from "./pages/admin/devices/DeviceHistory";
import Microservice from "./pages/admin/microservice/Microservice";
import ChangePassword from "./pages/customer/changePassword/ChangePassword";
import PageNotFound from "./pages/404/PageNotFound";
import VerifyToken from "./pages/Email Verification/VerifyToken";
import ForgotPassword from "./pages/forgot password/ForgotPassword";
import ResetPassword from "./pages/reset password/ResetPassword";
import Login from "./pages/login/Login";
import UpdateAhu from "./pages/admin/deviceDetails/UpdateAhu";
import SessionTimeout from "./components/session_timeout/SessionTimeout";
import DeviceControl from "./pages/admin/deviceDetails/DeviceControl";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewUsers from "./pages/admin/customerDetails/ViewUsers";
import UpdateUser from "./pages/admin/customerDetails/UpdateUser";
import Chillers from "./pages/chiller management/Chillers";
import ChillerDetails from "./pages/chiller management/ChillerDetails";
import Occupancy from "./pages/IAQ/occuopancy/Occupancy";
import UpdateCustomer from "./pages/admin/customers/UpdateCustomer";

const App = () => {
  const { mode } = useUserContext();
  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: "#069A8E",
          },
          secondary: {
            main: "#334B48",
          },
        },
        typography: {
          fontFamily: ["DMSans"].join(","),
        },
      }),
    [mode],
  );
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Routes>
        <Route element={<Client />}>
          <Route path="/:schema/overview" element={<Overview />} />
          <Route path="/:schema/ahu/list" element={<AhuList />} />
          <Route path="/:schema/ahu" element={<Ahu />} />
          <Route path="/:schema/btu" element={<Btu />} />
          <Route path="/:schema/btu/list" element={<BtuList />} />
          <Route path="/:schema/iaq/list" element={<IaqList />} />
          <Route path="/:schema/iaq" element={<Iaq />} />
          <Route path="/:schema/changepassword" element={<ChangePassword />} />
          <Route path="/:schema/chiller/list" element={<Chillers />} />
          <Route path="/:schema/chiller/details" element={<ChillerDetails />} />
          <Route path="/:schema/occupancy" element={<Occupancy />} />
        </Route>
        <Route element={<Admin />}>
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/add" element={<AddCustomer />} />
          <Route path="/customers/update" element={<UpdateCustomer />} />
          <Route path="/customer" element={<CustomerDetails />} />
          <Route path="/customer/users" element={<ViewUsers />} />
          <Route path="/customer/users/:userId" element={<UpdateUser />} />
          <Route path="/images" element={<ImageAnnotation />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/scheduleDetails" element={<ScheduleDetails />} />
          <Route path="/device" element={<Device />} />
          <Route path="/device/details" element={<DeviceDetails />} />
          <Route path="/device/edit" element={<EditDevice />} />
          <Route path="/device/edit/limits" element={<EditDeviceLimits />} />
          <Route path="/device/update/ahu" element={<UpdateAhu />} />
          <Route path="/device/control" element={<DeviceControl />} />
          <Route path="/schedule/add" element={<AddSchedule />} />
          <Route path="/device/data" element={<DeviceHistory />} />
          <Route path="/microservice" element={<Microservice />} />
        </Route>
        <Route path="/verify/user" element={<VerifyToken />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/:schema/login" element={<Login />} />
        <Route path="/:schema/sessiontimeout" element={<SessionTimeout />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
