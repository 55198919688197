import { Box, Paper, Button, Typography, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Device.css";
import Searchbar from "../../../components/searchbar/Searchbar";
import { useDevice } from "../../../context/DeviceContext";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import AccessDenied from "../../../components/AccessDenied/AccessDenied";

const Device = () => {
  const {
    deviceList,
    filteredItems,
    setFilteredItems,
    tableHead,
    setTableHead,
    setBreadcrumbs,
    setSelectedDeviceDetails,
    isDeviceListViewRestricted,
  } = useDevice();

  const navigate = useNavigate();
  const [showNoDeviceFoundMsg, setShowNoDeviceFoundMsg] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();

  const addActionBtn = () => {
    return {
      field: "action",
      headerName: "Action",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const getParams = () => {
          navigate("/device/details", {
            state: {
              ...params.row,
            },
          });
        };
        return (
          <Button variant="contained" onClick={getParams}>
            View Details
          </Button>
        );
      },
    };
  };

  const addDeviceTableHeaders = (headers) => {
    return headers.map((key) => ({
      field: key === "Status" ? "profile" : key,
      headerName: key.charAt(0).toUpperCase() + key.slice(1),
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
    }));
  };

  const handleApplyFilters = (selectedFilters) => {
    setShowNoDeviceFoundMsg(false);
    // console.log("selected filters ", selectedFilters);
    if (!selectedFilters?.length) return;
    const filtered = deviceList.filter((item) =>
      selectedFilters.some((filter) => {
        return (
          item?.profile === filter || item.metaData?.device_type === filter
        );
      }),
    );

    setFilteredItems(filtered);
    if (!filtered.length) return;
    const firstRow = filtered[0];
    let headers = Object.keys(firstRow);
    headers = headers.map((header) =>
      header === "profile" ? "Status" : header,
    );
    let columns = addDeviceTableHeaders(headers);
    columns.push(addActionBtn());
    setTableHead(columns);
  };

  const handleTextSearch = (searchTerm) => {
    const filteredData = Object.values(deviceList).filter((item) => {
      // Check if any value in the object includes the search term
      return Object.values(item).some((value) =>
        value
          .toString()
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase()),
      );
    });
    if (!filteredData.length) {
      setShowNoDeviceFoundMsg(true);
      setFilteredItems([]);
      return;
    }
    setFilteredItems(filteredData);
    const firstRow = filteredData[0];
    const headers = Object.keys(firstRow);
    let columns = addDeviceTableHeaders(headers);
    columns.push(addActionBtn());
    setTableHead(columns);
    setShowNoDeviceFoundMsg(false);
  };

  const handleResetFilters = () => {
    setSearchTerm("");
    setFilteredItems([]);
    setShowNoDeviceFoundMsg(false);
  };

  useEffect(() => {
    setBreadcrumbs([{ path: "/device", breadcrumbName: "Device" }]);
    setSelectedDeviceDetails(null);
    setShowNoDeviceFoundMsg(false);
    const filtered = deviceList.filter((item) => item);
    setFilteredItems(filtered);
    if (!filtered.length) return;
    const firstRow = filtered[0];
    let headers = Object.keys(firstRow);
    headers = headers.map((header) =>
      header === "profile" ? "Status" : header,
    );
    let columns = addDeviceTableHeaders(headers);
    columns.push(addActionBtn());
    setTableHead(columns);
  }, [location]);

  if (isDeviceListViewRestricted) {
    return <AccessDenied />;
  }

  if (!deviceList.length)
    return (
      <Paper sx={{ p: "20px" }}>
        <Typography sx={{ textAlign: "left" }}>
          Device list not available!
        </Typography>
      </Paper>
    );

  return (
    <>
      <div>
        <Searchbar
          onApply={handleApplyFilters}
          onReset={handleResetFilters}
          onSearch={handleTextSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
      <Box sx={{ mt: "20px" }}>
        {showNoDeviceFoundMsg && (
          <Paper sx={{ p: "20px" }}>
            <Alert severity="info">No Device Found!</Alert>
          </Paper>
        )}
        {filteredItems.length > 0 && (
          <Paper
            sx={{
              p: "10px",
              width: "100%",
              overflowX: "auto",
            }}
          >
            <Box sx={{ width: { xs: "700px", md: "100%" }, overflowX: "auto" }}>
              <DataGrid
                rows={filteredItems}
                columns={tableHead}
                disableRowSelectionOnClick
                disableColumnSelector
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                  columns: {
                    columnVisibilityModel: {
                      updated_at: false,
                      installation_details: true,
                      created_at: false,
                      metaData: false,
                      set_schema: false,
                      set_limits: false,
                      provisoned_on: false,
                    },
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
            </Box>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default Device;
