import React, { useEffect, useState } from "react";
import { reIssueToken, verifyEmail } from "../../utils/Api";
import jwtDecode from "jwt-decode";
import "./VerifyToken.css";
import { useNavigate } from "react-router-dom";

const EMAIL_VERIFICATION_STATUS_SUCCESS = "success";
const EMAIL_VERIFICATION_STATUS_VERIFYING = "verifying";
const EMAIL_VERIFICATION_STATUS_ERROR = "error";
const EMAIL_VERIFICATION_STATUS_INVALID = "invalid";
const EMAIL_VERIFICATION_STATUS_VERIFIED_AGAIN = "reverified";
const EMAIL_VERIFICATION_STATUS_REISSUE_TOKEN = "token reissued";

const VerifyToken = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const [isVerifying, setIsVerifying] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(5);
  const [verificationStatus, setVerificationStatus] = useState(
    EMAIL_VERIFICATION_STATUS_VERIFYING,
  );
  const navigate = useNavigate();
  const token = searchParams.get("token");

  const handleEmailVerification = () => {
    // console.log("handling email verification");
    const token = searchParams.get("token");

    setIsVerifying(true);

    verifyEmail(token)
      .then(({ data }) => {
        // console.log(data);
        const message = data?.response?.message || false;
        if (message && message == "user updated successfully") {
          setVerificationStatus(EMAIL_VERIFICATION_STATUS_SUCCESS);
        }
        if (message && message == "Email has alreay been verified") {
          setVerificationStatus(EMAIL_VERIFICATION_STATUS_VERIFIED_AGAIN);
        }
      })
      .catch((error) => {
        // console.log(error?.response?.data?.msg || error.message);
        // console.log(error);
        if (error.response) {
          // The request was made, but the server responded with an error status code
          setVerificationStatus(EMAIL_VERIFICATION_STATUS_INVALID);
        } else if (error.request) {
          // The request was made, but no response was received
          // console.error("No response received from the server");
          setVerificationStatus(EMAIL_VERIFICATION_STATUS_ERROR);
        } else {
          // Something happened in setting up the request that triggered an error
          setVerificationStatus(EMAIL_VERIFICATION_STATUS_ERROR);
        }
      })
      .finally(() => {
        setIsVerifying(false);
      });
  };

  const handleResendVerificationEmail = () => {
    const token = searchParams.get("token");
    setIsVerifying(true);
    reIssueToken(token)
      .then(({ data }) => {
        // console.log(data);
        setVerificationStatus(EMAIL_VERIFICATION_STATUS_REISSUE_TOKEN);
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        setIsVerifying(false);
      });
  };

  useEffect(() => {
    // Redirect to login page after verification
    if (
      verificationStatus === EMAIL_VERIFICATION_STATUS_SUCCESS ||
      verificationStatus === EMAIL_VERIFICATION_STATUS_VERIFIED_AGAIN
    ) {
      const schema = jwtDecode(token).data.schema;
      const redirectInterval = setInterval(() => {
        setRedirectTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(redirectInterval);
            navigate(`/${schema}/login`, { replace: true });
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(redirectInterval);
    }
  }, [verificationStatus]);

  return (
    <>
      <img src={require("../../images/Clairco.png")} className="company_logo" />

      <div className="verification-container">
        <img
          src={require("../../images/email.png")}
          alt="Email icon"
          className="email_icon"
        />
        <div>
          {verificationStatus === EMAIL_VERIFICATION_STATUS_VERIFYING && (
            <>
              <h1>Verify your Email address</h1>
              <button
                onClick={handleEmailVerification}
                style={{ cursor: isVerifying ? "not-allowed" : "pointer" }}
                disabled={isVerifying ? true : false}
                className="email_verify_button"
              >
                {isVerifying
                  ? "Verifying your email..."
                  : "Click here to verify"}
              </button>
            </>
          )}

          {verificationStatus === EMAIL_VERIFICATION_STATUS_SUCCESS && (
            <>
              <h1>Your account is successfully verified.</h1>
              <p>You can login now.</p>
              <p>Redirecting to login page in {redirectTimer} seconds...</p>
            </>
          )}

          {verificationStatus === EMAIL_VERIFICATION_STATUS_VERIFIED_AGAIN && (
            <>
              <h1>Your account has already been successfully verified..</h1>
              <p>Redirecting to login page in {redirectTimer} seconds...</p>
            </>
          )}

          {verificationStatus === EMAIL_VERIFICATION_STATUS_REISSUE_TOKEN && (
            <>
              <p>An email has been sent with a new link. Please check</p>
            </>
          )}

          {verificationStatus === EMAIL_VERIFICATION_STATUS_INVALID && (
            <>
              <h1>Invalid or expired verification link</h1>
              <p>
                Please check your email for a valid verification link. Else,
                click on the below button to resend the verification mail.
              </p>
              <button
                onClick={handleResendVerificationEmail}
                className="email_verify_button"
                style={{ cursor: isVerifying ? "not-allowed" : "pointer" }}
                disabled={isVerifying ? true : false}
              >
                {isVerifying
                  ? "sending verification mail"
                  : "Resend Verification Mail"}
              </button>
            </>
          )}

          {verificationStatus === EMAIL_VERIFICATION_STATUS_ERROR && (
            <>
              <h1>Error verifying your email</h1>
              <p>
                There was an error in processing your request. Please try again
                later.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VerifyToken;
