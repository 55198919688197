import React from "react";
import { useAhuContext } from "../../context/AhuContext";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  CardMedia,
  Box,
  Stack,
} from "@mui/material";
import CardSkeleton from "../../components/loading_skeletons/CardSkeleton";
import { getItemFromLocalstroage } from "../../utils/helper";
import moment from "moment";

const AhuSavings = () => {
  const { ahuSavingsLoading, ahuSavings } = useAhuContext();
  const totalAhus =
    JSON.parse(getItemFromLocalstroage("schema"))?.[0].metaData?.devices?.ahus
      ?.length ?? "NA";
  const totalBtus =
    JSON.parse(getItemFromLocalstroage("schema"))?.[0].metaData?.devices
      ?.btu_meters?.length ?? "NA";
  const totalIaqs =
    JSON.parse(getItemFromLocalstroage("schema"))?.[0].metaData?.devices
      ?.iaq_sensors?.length ?? "NA";
  // console.log(ahuSavings);
  if (ahuSavingsLoading)
    return (
      <Grid container spacing={3} sx={{ mb: "24px" }}>
        <Grid item xs={12} lg={4}>
          <CardSkeleton />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CardSkeleton />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CardSkeleton />
        </Grid>
      </Grid>
    );
  return (
    <Grid container spacing={3} sx={{ mb: "24px" }}>
      {ahuSavings?.map((item, index) => (
        <Grid item md={4} lg={4} xs={12} sm={6} key={index}>
          <Card
            sx={{
              width: "100%",
            }}
            elevation={0}
          >
            <CardContent>
              <Typography
                sx={{ color: "#344a53", textAlign: "left", fontWeight: "bold" }}
                gutterBottom
              >
                {item.title}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-around"
              >
                <CardMedia
                  component="img"
                  sx={{ width: { lg: 96, xs: 64 } }}
                  image={require("../../images/financial-profit.png")}
                />
                <Box>
                  <Typography
                    sx={{
                      color: "#344a53",
                      fontWeight: 700,
                      fontSize: { lg: "50px", xs: "35px" },
                      letterSpacing: "2px",
                    }}
                  >
                    {item.description}
                  </Typography>

                  <Typography
                    sx={{
                      color: "grey",
                      textAlign: "center",
                      fontSize: "13px",
                    }}
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {/* <Grid item md={12} lg={3} xs={12} sm={12}>
        <Card
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
          elevation={0}
        >
          <CardContent>
            <Stack direction="row" alignItems="center" sx={{ mb: "10px" }}>
              <CardMedia
                component="img"
                sx={{ width: 30 }}
                image={require("../../images/coins.png")}
                alt="Live from space album cover"
              />
              <Box>
                <Typography
                  color="text.secondary"
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "2px",
                    marginLeft: "10px",
                  }}
                >
                  savings
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-around">
              <Box>
                <Typography gutterBottom>
                  {ahuSavings?.[0]?.description ?? "NA"}
                </Typography>
                <Typography gutterBottom sx={{ fontSize: "12px" }}>
                  {moment(ahuSavings?.[0]?.subtitle).format("D MMM") || "NA"}
                </Typography>
              </Box>
              <Box>
                <Typography gutterBottom>
                  {ahuSavings?.[1]?.description ?? "NA"}
                </Typography>
                <Typography gutterBottom sx={{ fontSize: "12px" }}>
                  {ahuSavings?.[1]?.subtitle || "NA"}
                </Typography>
              </Box>{" "}
              <Box>
                <Typography gutterBottom>
                  {ahuSavings?.[2]?.description ?? "NA"}
                </Typography>
                <Typography gutterBottom sx={{ fontSize: "12px" }}>
                  {ahuSavings?.[2]?.subtitle || "NA"}
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} lg={3} xs={12} sm={12}>
        <Card
          sx={{
            width: "100%",
            height: "100%",
            // background: "#1CAC78"
            // background: "#14b8a6",
            // background:
            //   index === 0 ? "#0096B9" : index === 1 ? "#344A53" : "#7A5880",

            // background:
            //   index === 0 ? "#00695c" : index === 1 ? "#00796b" : "#009988",
          }}
          elevation={0}
        >
          <CardContent>
            <Box>
              <Air sx={{ fontSize: "25px", color: "#008675" }} />
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Box>
                <Typography
                  color="text.secondary"
                  gutte
                  sx={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: "25px",
                    letterSpacing: "2px",
                  }}
                >
                  {totalAhus}
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                >
                  AHU
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} lg={3} xs={12} sm={12}>
        <Card
          sx={{
            width: "100%",
          }}
          elevation={0}
        >
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Box>
                <Hvac sx={{ fontSize: "92px", color: "#008675" }} />
              </Box>
              <Box>
                <Typography
                  color="text.secondary"
                  gutte
                  sx={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: "25px",
                    letterSpacing: "2px",
                  }}
                >
                  {totalBtus}
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                >
                  BTU
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} lg={3} xs={12} sm={12}>
        <Card
          sx={{
            width: "100%",
          }}
          elevation={0}
        >
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Box>
                <RoomPreferences sx={{ fontSize: "92px", color: "#008675" }} />
              </Box>
              <Box>
                <Typography
                  color="text.secondary"
                  gutte
                  sx={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: "25px",
                    letterSpacing: "2px",
                  }}
                >
                  {totalIaqs}
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                >
                  IAQ
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Grid> */}
    </Grid>
  );
};

export default AhuSavings;
