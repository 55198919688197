import React from "react";
import "./pageNotFound.css";

function NotFound() {
  return (
    <div className="not-found-container">
      <img src={require("../../images/Clairco.png")} className="clairco_logo" />

      <img src={require("../../images/404page.png")} className="image" />
      <h2 className="error-code"></h2>
      <h1>Something went wrong!</h1>
      <p>
        The page you're looking for doesn't seem to exist. Maybe you mistyped
        the URL or the page has been removed.
      </p>
    </div>
  );
}

export default NotFound;
