import { Alert, Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import jwtDecode from "jwt-decode";
import { useParams } from "react-router-dom";
import { changeUserPassword } from "../../../utils/Api";
import { useAuthContext } from "../../../context/AuthContext";
import { logout } from "../../../utils/helper";
const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = useForm();
  const { isAdmin } = useAuthContext();

  const oldPassword = watch("oldPassword");
  const [passwordError, setPasswordError] = useState({ type: "", message: "" });
  const params = useParams();

  const handleLogout = async () => {
    logout(isAdmin, params.schema);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      const decodedToken = jwtDecode(localStorage.getItem("token"));
      const userId = decodedToken.id.split(".")[0].slice(8);
      if (!userId) throw new Error("Invalid User ID!");
      const { data: apiResponse } = await changeUserPassword(
        userId,
        params.schema,
        data.oldPassword,
        data.newPassword,
      );
      setPasswordError({
        type: "info",
        message: apiResponse?.response?.message || "",
      });
      if (apiResponse.response.message === "Password changed Successfully!") {
        setTimeout(() => {
          handleLogout();
        }, 2000);
      }
    } catch (error) {
      //   console.log(error);
      setPasswordError({
        type: "error",
        message:
          error?.response?.data.msg ||
          error?.message ||
          "Please try again. Password updation failed",
      });
    }
  };
  return (
    <Paper
      elevation={0}
      sx={{
        padding: {
          md: "20px",
          xs: "12px",
        },
      }}
    >
      <Typography variant="h4" color="primary">
        Change Password
      </Typography>
      <Box sx={{ mt: 3 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <label htmlFor="oldPassword">Current Password</label>
              <input
                type="password"
                {...register("oldPassword", {
                  required: "Current password is required",
                })}
              />
              {errors.oldPassword && (
                <Typography component="span" sx={{ color: "#DC2626" }}>
                  {errors.oldPassword.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                {...register("newPassword", {
                  required: "New password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                  maxLength: {
                    value: 32,
                    message: "Password must not exceed 32 characters",
                  },
                  validate: (value) => {
                    return (
                      value !== oldPassword || "New password cannot be the same"
                    );
                  },
                })}
              />
              {errors.newPassword && (
                <Typography component="span" sx={{ color: "#DC2626" }}>
                  {errors.newPassword.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3 }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
        {passwordError.message && (
          <Alert severity={passwordError.type} sx={{ m: "20px 0" }}>
            {passwordError.message}
          </Alert>
        )}
      </Box>
    </Paper>
  );
};

export default ChangePassword;
