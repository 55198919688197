import {
  CameraAltOutlined,
  EventRepeatOutlined,
  LanOutlined,
  Logout,
  PeopleOutline,
  SettingsRemoteOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { deleteAhuDatabase } from "../../db/query";
const AdminSidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const { setIsAdmin, setIsLoggedIn } = useAuthContext();

  const menuItemsOne = [
    {
      icon: <PeopleOutline />,
      text: "Customers",
      path: `/customers`,
      index: 0,
    },
    {
      icon: <CameraAltOutlined />,
      text: "Image Training",
      path: `/images`,
      index: 1,
    },
    {
      text: "Device",
      path: `/device`,
      icon: <SettingsRemoteOutlined />,
      index: 2,
    },
    {
      text: "Schedule",
      path: `/schedule`,
      icon: <EventRepeatOutlined />,
      index: 3,
    },
    {
      text: "Microservices",
      path: `/microservice`,
      icon: <LanOutlined />,
      index: 4,
    },

  ];
  const menuItemsTwo = [
    {
      icon: <Logout />,
      text: "Logout",
    },
  ];

  useEffect(() => {
    // let pathname = window.location.href.toString().split("/")[3];
    // if (pathname === "customers") setSelectedMenuIndex(0);
    // if (pathname === "images") setSelectedMenuIndex(1);
    // if (pathname === "device") setSelectedMenuIndex(2);
    // if (pathname === "schedule") setSelectedMenuIndex(3);
    // if (pathname === "microservice") setSelectedMenuIndex(4);
    // if (pathname === "chiller") setSelectedMenuIndex(5);
    if (window.location.pathname !== "/customers") {
      navigate("/customers");
    }
  }, []);

  const handleAdminLogout = async () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
    localStorage.clear();
    await deleteAhuDatabase();
    navigate(`/clairco/login`);
    window.location.reload();
  };

  return (
    <div className={`admin_sidebar ${isSidebarOpen ? "open" : ""}`}>
      <div className="admin_menu_items">
        <img
          src="https://static.wixstatic.com/media/840069_a293e3debec44af58ef18f0305740397~mv2.png/v1/fill/w_188,h_85,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/White%20without%20Clean%20Air%20G.png"
          style={{ margin: "0 auto 20px auto" }}
        />
        {menuItemsOne.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              navigate(item.path);
              setSelectedMenuIndex(index);
              toggleSidebar();
            }}
            className={
              selectedMenuIndex !== index
                ? "admin_menu_item"
                : "admin_menu_item_active"
            }
          >
            {item.icon}
            <span className="admin_menu_text">{item.text}</span>
          </div>
        ))}
      </div>
      <div className="admin_menu_items">
        {menuItemsTwo.map((item, index) => (
          <div
            key={index}
            className={"admin_menu_item"}
            onClick={handleAdminLogout}
          >
            {item.icon}
            <span className="client_menu_text">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminSidebar;
