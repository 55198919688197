import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./skeleton.css";
const CardSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#ffffff" highlightColor="#f0f0f0">
      <Skeleton className="skeleton" />
    </SkeletonTheme>
  );
};

export default CardSkeleton;
