import { Alert, Box, Typography } from "@mui/material";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import { getItemFromLocalstroage } from "../../utils/helper";
const BtuList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [schema, setSchema] = useState(
    JSON.parse(localStorage.getItem("schema")),
  );
  const btuList = schema?.[0]?.metaData?.devices?.btu_meters || [];
  const branchId = schema?.[0]?.metaData?.branch_id || "";
  const navigatePage = (btu) => {
    const deviceId = btu.device_id;
    navigate(`/${params.schema}/btu`, {
      state: {
        branchId,
        deviceId,
        btuDetails: btu,
      },
    });
  };

  if (!btuList || (btuList && btuList.length == 0)) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            textAlign: "center",
            paddingBottom: "20px",
            margin: "0 auto",
          }}
        >
          <DataThresholdingIcon
            color="primary"
            sx={{ fontSize: "150px", display: "block", margin: "0 auto" }}
          />
          <Typography variant="h5">No BTU Data Available</Typography>
        </div>
      </div>
    );
  }
  return (
    <Box>
      <Alert severity="info" sx={{ mb: "20px", background: "#D9FBF7" }}>
        This is a list of all the BTUs in this building
      </Alert>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                BTU
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Location
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Installation
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {btuList?.map((ahu, index) => (
              <TableRow key={index}>
                <TableCell sx={{ padding: "8px", textAlign: "center" }}>
                  {ahu?.device_id}
                </TableCell>
                <TableCell sx={{ padding: "8px", textAlign: "center" }}>
                  {ahu?.friendly_name}
                </TableCell>
                <TableCell sx={{ padding: "8px", textAlign: "center" }}>
                  {ahu?.location}
                </TableCell>
                <TableCell sx={{ padding: "8px", textAlign: "center" }}>
                  {ahu?.installation_date}
                </TableCell>
                <TableCell sx={{ padding: "8px", textAlign: "center" }}>
                  <Button variant="contained" onClick={() => navigatePage(ahu)}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BtuList;
