import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const GraphSkeleton = () => {
  return (
    <div>
      <Skeleton style={{ width: "100%", height: "330px" }} />
    </div>
  );
};

export default GraphSkeleton;
