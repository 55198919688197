import { Box } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

const AreaChart = ({
  data,
  temp_flow,
  cooling_energy,
  temp,
  ChWP,
  enBtu,
  heading,
  showDailyConsumption,
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "top",
      },

      title: {
        display: true,
        text: heading,
      },
    },
    scales: {
      x: {
        ticks: {
          // callback: function (value, index, values) {
          //   return parseFloat(value).toFixed(2);
          // },
          autoSkip: true,
          maxTicksLimit: 5,
          // stepSize: 0.2,
          stepSize: 30,
        },
        grid: {
          display: false,
        },
        display: true,
      },
      enBtu: {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "BTU consumption (KWh)",
        },
        ticks: {
          stepSize: 3,
          display: false,
        },
        grid: {
          display: false,
        },
        display: enBtu ? true : false,
      },

      temp: {
        type: "linear",
        position: "left",
        suggestedMin: 0,
        suggestedMax: 35,
        ticks: {
          // stepSize: 10,
          display: true,
        },
        grid: {
          display: false,
        },

        title: {
          display: true,
          text: "Rtemp & Stemp (°C)",
        },
        display: temp ? true : false,
      },
      temp_flow: {
        type: "linear",
        position: "left",

        ticks: {
          stepSize: 3,
          display: true,
        },

        title: {
          display: true,
          text: "Temp In & Temp Out (°C)",
        },
        grid: {
          display: false,
        },
        display: temp_flow ? true : false,
      },
      ChWP: {
        type: "linear",
        position: "right",
        ticks: {
          stepSize: 20,
          display: true,
        },
        title: {
          display: true,
          text: "Chilled Water Position (%)",
        },
        suggestedMin: 0,
        suggestedMax: 100,
        display: ChWP ? true : false,
        grid: {
          display: false,
        },
      },
      cooling_energy: {
        type: "linear",
        position: "right",

        ticks: {
          stepSize: 5000,
          display: true,
        },
        title: {
          display: true,
          text: "Cooling Energy (KWh)",
        },
        display: cooling_energy ? true : false,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Box
        sx={{
          width: {
            md: "100%",
            sm: "100%",
            xs: "800px",
          },
        }}
      >
        <Line options={options} data={data} />
      </Box>
    </Box>
  );
};

export default AreaChart;
