import React, { useEffect, useState } from "react";
import ClientSidebar from "../components/sidebar/ClientSidebar";
import "./layout.css";
import { Box, Typography, Fab, Paper, Stack } from "@mui/material";
import { Menu, Place, Navigation } from "@mui/icons-material";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useDataContext } from "../context/DataContext";
import { INITIAL_IAQ_STATE } from "../utils/constants";
import { useAuthContext } from "../context/AuthContext";
import { deleteAhuDatabase } from "../db/query";
import { useIaqContext } from "../context/IaqContext";
import SessionTimeout from "../components/session_timeout/SessionTimeout";
import { axiosInstance } from "../utils/Api";
import {
  capitalizeFirstLetter,
  getItemFromLocalstroage,
  logout,
} from "../utils/helper";
import AccessDenied from "../components/AccessDenied/AccessDenied";

const Client = () => {
  const [clientName, setClientName] = useState("");
  const [clientCity, setClientCity] = useState("");
  const { isLoggedIn, isAdmin } = useAuthContext();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { setEnBtuGraph, isClientViewRestricted } = useDataContext();
  const { setIaqData } = useIaqContext();
  const navigate = useNavigate();
  const params = useParams();
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (!isLoggedIn) return;
    const loggedInUserShema = localStorage.getItem("identifier");
    const paramsSchema = params.schema;

    if (loggedInUserShema === paramsSchema) {
      // User is already on the correct client page

      return navigate(`/${loggedInUserShema}/overview`);
    } else {
      // User is trying to access the schema which is different from the schema  in local storage
      // Redirect the user to login page

      logout(isAdmin, paramsSchema);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) return;
    let schema = getItemFromLocalstroage("schema");
    if (!schema) return;
    schema = JSON.parse(schema);
    if (!Array.isArray(schema)) return;
    const name = schema?.[0].Name || "";
    const city = schema?.[0].city || "";
    setClientName(name);
    setClientCity(city);
    // console.log("username => ", getItemFromLocalstroage("username"));
    if (!getItemFromLocalstroage("username")) return;

    setUsername(capitalizeFirstLetter(getItemFromLocalstroage("username")));
  }, [isLoggedIn]);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        if (
          response.data.type == "error" &&
          response.data.title == "Token is invalid"
        ) {
          setSessionExpired(true);
        }

        // console.log(response);

        return response;
      },
    );

    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  const handleNavigationToAdmin = async () => {
    localStorage.removeItem("ahus");
    localStorage.removeItem("identifier");
    localStorage.removeItem("btu");
    localStorage.removeItem("ahu_response");
    localStorage.removeItem("ahu_graph");
    localStorage.removeItem("schema");
    localStorage.removeItem("selectedClient");
    localStorage.removeItem("ahuNames");
    sessionStorage.removeItem("Avg IAQs");
    localStorage.removeItem("clientName");
    setEnBtuGraph({
      labels: "",
      data: "",
      month: "",
    });
    setIaqData(INITIAL_IAQ_STATE);
    await deleteAhuDatabase();
    window.location.href = "/customers";
  };

  if (!isLoggedIn) {
    window.location.href = "https://www.clairco.in/";
    return;
  }

  if (sessionExpired) {
    return <SessionTimeout />;
  }

  return (
    <div className="client_container">
      {isAdmin && (
        <Box
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            zIndex: 2,
          }}
        >
          <Fab
            variant="extended"
            color="primary"
            onClick={handleNavigationToAdmin}
          >
            <Navigation sx={{ mr: 1 }} />
            Admin View
          </Fab>
        </Box>
      )}
      <div
        className={`overlay ${isSidebarOpen ? "open" : ""}`}
        onClick={toggleSidebar}
      ></div>
      <ClientSidebar
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className="client_content_container">
        <div className="client_appbar">
          <div className="menu_icon_btn" onClick={toggleSidebar}>
            <Menu />
          </div>
          {isAdmin ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  sm: {
                    display: "none",
                  },
                }}
              >
                {getItemFromLocalstroage("username") ? (
                  <Typography
                    variant="h6"
                    sx={{ color: "#344A53", fontWeight: 700 }}
                  >
                    Hi,
                    <b style={{ color: "#FACC15", paddingLeft: "5px" }}>
                      {capitalizeFirstLetter(
                        getItemFromLocalstroage("username"),
                      )}
                    </b>
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              <Stack direction="row" alignItems="center">
                {localStorage.getItem("clientName") && (
                  <Place id="location_icon" />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    color: "#344A53",
                    fontWeight: 700,
                    fontSize: {
                      xs: "12px",
                      sm: "16px",
                    },
                  }}
                >
                  {localStorage.getItem("clientName") &&
                    localStorage.getItem("clientName")}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  sm: {
                    display: "none",
                  },
                }}
              >
                {getItemFromLocalstroage("username") ? (
                  <Typography
                    variant="h6"
                    sx={{ color: "#344A53", fontWeight: 700 }}
                  >
                    Hi,
                    <b style={{ color: "#FACC15", paddingLeft: "5px" }}>
                      {capitalizeFirstLetter(
                        getItemFromLocalstroage("username"),
                      )}
                    </b>
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              <Stack direction="row" alignItems="center">
                {localStorage.getItem("clientName") && (
                  <Place id="location_icon" />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    color: "#344A53",
                    fontWeight: 700,
                    fontSize: {
                      xs: "12px",
                      sm: "16px",
                    },
                  }}
                >
                  {localStorage.getItem("clientName") || "Welcome!"}
                </Typography>
              </Stack>
            </Stack>
          )}
        </div>
        {isClientViewRestricted ? (
          <AccessDenied />
        ) : (
          <div className="client_content">
            <Outlet />
          </div>
        )}
      </div>
    </div>
  );
};

export default Client;
