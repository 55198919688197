import { useContext, createContext, useState } from "react";
import {
  AGILE_INSTALLATION_TYPE,
  CLAIRCO_INSTALLATION_TYPE,
  INITIAL_IAQ_STATE,
} from "../utils/constants";
import {
  checkIfKeyExistsInSessionStorage,
  getItemFromLocalstroage,
  getTodayDate,
} from "../utils/helper";
import { getIAQData } from "../utils/Api";

export const IaqContext = createContext();

const IaqContextProvider = ({ children }) => {
  const [iaqData, setIaqData] = useState(INITIAL_IAQ_STATE);
  const [hourlyIaqDataLoading, setHourlyIaqDataLoading] = useState(true);
  const [isIaqHourlyAvgViewRestricted, setIsIaqHourlyAvgViewRestricted] =
    useState(false);
  const updateIaqData = (tempValues, humidityValues) => {
    let avgTemp =
      tempValues.reduce((acc, val) => acc + parseInt(val), 0) /
      tempValues.length;
    let avgHumidity =
      humidityValues.reduce((acc, val) => acc + parseInt(val), 0) /
      humidityValues.length;
    // console.log("avg temp ", avgTemp);
    // console.log("Avg Humidity ", avgHumidity);
    const updatedIaqData = INITIAL_IAQ_STATE.map((item) => {
      if (item.pollutant === "Temp") {
        return {
          ...item,
          value: avgTemp ? parseInt(avgTemp) : "NA",
        };
      } else if (item.pollutant === "Hum") {
        return {
          ...item,
          value: avgHumidity ? parseInt(avgHumidity) : "NA",
        };
      }
      return item;
    });

    sessionStorage.setItem("Avg IAQs", JSON.stringify(updatedIaqData));
    setIaqData(updatedIaqData);
    setHourlyIaqDataLoading(false);
    return;
  };

  const calculateAvgIaqOfCurrentHour = async (schema) => {
    let installationType = getItemFromLocalstroage("installationType");
    const iaqList = schema?.[0].metaData?.devices?.iaq_sensors || [];
    const branchId = schema[0]?.metaData?.branch_id || "";
    if (!iaqList?.length || !branchId) return;
    const iaqs = iaqList.map((sensor) => {
      return {
        deviceId: sensor.device_id,
        branchId,
        day: getTodayDate(),
      };
    });
    setHourlyIaqDataLoading(true);
    try {
      const keyExistsInSessionStorage =
        checkIfKeyExistsInSessionStorage("Avg IAQs");
      if (keyExistsInSessionStorage) {
        const parsedIAQData = JSON.parse(sessionStorage.getItem("Avg IAQs"));
        setIaqData(parsedIAQData);
        setHourlyIaqDataLoading(false);
        return;
      }

      if (!iaqs?.length) throw new Error("No iaqs found");

      if (installationType == AGILE_INSTALLATION_TYPE) {
        const requests = iaqs.map((iaq) => {
          return getIAQData({
            branchId: iaq.branchId,
            deviceId: iaq.deviceId,
            day: iaq.day,
            flag_details: {
              installation_type: AGILE_INSTALLATION_TYPE,
            },
          });
        });
        const responses = await Promise.allSettled(requests);

        let fulfilledRequests = responses.map((response) => {
          if (response.status === "fulfilled") {
            return response.value.data;
          }
          return null;
        });
        // console.log("fulfiiled requests before filtering ", fulfilledRequests);

        let fulfilledRequestsWithValues = fulfilledRequests.filter(
          (request) => request !== null,
        );
        // console.log(
        //   "fulfiiled requests after filtering ",
        //   fulfilledRequestsWithValues,
        // );

        let tempValues = fulfilledRequestsWithValues.map((request) => {
          return (
            request?.data_set?.slice(-2, -1)?.[0]?.ambient_temperature ?? 0
          );
        });

        let humidityValues = fulfilledRequestsWithValues.map((request) => {
          return request?.data_set?.slice(-2, -1)?.[0]?.humidity ?? 0;
        });
        // console.log({
        //   tempValues,
        //   humidityValues,
        // });
        updateIaqData(tempValues, humidityValues);
      }

      if (installationType == CLAIRCO_INSTALLATION_TYPE) {
        const requests = iaqs.map((iaq) => {
          return getIAQData({
            deviceId: iaq.deviceId,
            fetchType: "history",
            flag_details: {
              installation_type: CLAIRCO_INSTALLATION_TYPE,
              interval: "1 hour",
            },
          });
        });

        const responses = await Promise.allSettled(requests);
        if (!responses?.length) throw new Error("No response received for iaq");
        let fulfilledRequests = responses.map((response) => {
          if (response.status === "fulfilled") {
            return response.value.data;
          }
          return null;
        });

        fulfilledRequests = fulfilledRequests.filter(
          (request) => request !== null,
        );

        let dataset = fulfilledRequests.map((request) => request?.msg || []);
        let tempValues = dataset?.map((deviceArray) =>
          deviceArray.length > 0
            ? deviceArray[deviceArray.length - 1]?.metaData
                ?.ambient_temperature ?? 0
            : 0,
        );

        let humidityValues = dataset?.map((deviceArray) =>
          deviceArray.length > 0
            ? deviceArray[deviceArray.length - 1]?.metaData?.humidity ?? 0
            : 0,
        );

        updateIaqData(tempValues, humidityValues);
      }
    } catch (error) {
      // console.error(error);
      if (error.response.status === 403) {
        alert("You do not have enough permissions to view IAQ data");
        setIsIaqHourlyAvgViewRestricted(true);
      }
    } finally {
      setHourlyIaqDataLoading(false);
    }
  };

  return (
    <IaqContext.Provider
      value={{
        iaqData,
        calculateAvgIaqOfCurrentHour,
        hourlyIaqDataLoading,
        setIaqData,
        isIaqHourlyAvgViewRestricted,
      }}
    >
      {children}
    </IaqContext.Provider>
  );
};

export const useIaqContext = () => useContext(IaqContext);

export default IaqContextProvider;
