import { Button, Paper, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Chillers.css";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { fetchChillerData } from "../../utils/Api";
import DataNotFound from "../../components/data_not_found/DataNotFound";
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import GraphSkeleton from "../../components/loading_skeletons/GraphSkeleton";

const ChillerLayout = ({ children }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: {
          xs: "16px",
        },
        width: {
          md: "100%",
          xs: "100%",
        },
      }}
    >
      {children}
    </Paper>
  );
};

const Chillers = () => {
  const navigate = useNavigate();
  const schema = useParams().schema;
  const [chillerDataRows, setChillerDataRows] = useState([]);
  const [chillerData, setChillerData] = useState({
    data: [],
    isError: false,
    isLoading: true,
    error: null,
    isForbidden: false,
  });
  let columns = [
    {
      field: "entityId",
      headerName: "ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      align: "center",
    },
    // {
    //   field: "name",
    //   headerName: "NAME",
    //   flex: 1,
    //   align: "center",
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      align: "center",
      renderCell: (params) => {
        const getParams = () => {
          navigate(`/${schema}/chiller/details`, {
            state: {
              ...params.row,
            },
          });
        };
        return (
          <Button variant="contained" onClick={getParams}>
            View Details
          </Button>
        );
      },
    },
  ];

  function getRowId(row) {
    return row.entityId;
  }

  useEffect(() => {
    const time = Date.now();
    const fiveMinutesAgo = time - 1000 * 60 * 5;
    const payload = {
      timeInMilliseconds: fiveMinutesAgo,
    };
    fetchChillerData(payload)
      .then(({ data: apiResponse }) => {
        setChillerData((prevState) => ({
          ...prevState,
          data: apiResponse?.msg?.length > 0 ? apiResponse?.msg : [],
        }));
      })
      .catch((error) => {
        console.log(error);
        setChillerData((prevState) => ({
          ...prevState,
          isError: true,
          error: "Data not available",
          isForbidden: error?.response?.status === 403 ? true : false,
        }));
      })
      .finally(() => {
        setChillerData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  }, []);

  if (chillerData.isLoading) {
    return <GraphSkeleton />;
  }

  if (chillerData.isForbidden) {
    return <AccessDenied />;
  }

  if (chillerData.isError) {
    return <DataNotFound />;
  }

  if (!chillerData.data.length) {
    return <DataNotFound />;
  }

  return (
    <ChillerLayout>
      <Box
        sx={{
          height: 450,
          width: 1,

          "& .super-app-theme--header": {
            backgroundColor: "#008675",
            color: "white",
          },

          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "center",
          },

          "& .MuiDataGrid-toolbarContainer": {
            paddingBottom: "8px",
          },

          "& .MuiDataGrid-columnHeaderRow": {
            borderRadius: 0,
          },
        }}
      >
        <DataGrid
          rows={chillerData.data ?? []}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={columns}
          checkboxSelection={false}
          pageSizeOptions={[5, 10]}
          getRowId={getRowId}
          slots={{
            toolbar: GridToolbar,
            loadingOverlay: LinearProgress,
            // noRowsOverlay: () => {
            //   return <p style={{ width: 1, height: 400 }}>No Data Awsdscfv</p>;
            // },
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          sx={
            {
              // ".MuiDataGrid-row:nth-child(even)": {
              //   bgcolor: "gainsboro",
              // },
            }
          }
        />
      </Box>
    </ChillerLayout>
  );
};

export default Chillers;
