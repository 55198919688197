import axios from "axios";
import { useState } from "react";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PROD_BASEURL,
});

const useAxios = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchData = async (props) => {
    setIsLoading(true);
    try {
      const { data: apiResponse } = await axiosInstance(props);
      setData(apiResponse);
      return apiResponse;
    } catch (error) {
      setIsError(true);
      if (error.code === "ECONNABORTED") {
        setError("Server took too long to respond");
        return "Server took too long to respond";
      }
      if (error?.response?.status === 403) {
        setError("You do not have enough permissions to perform this action");
        return "You do not have enough permissions to perform this action";
      }
      if (error?.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError("Something went wrong!");
        return "Something went wrong!";
      } else if (error.request) {
        // The request was made but no response was received
        setError("No response received from the server");
        return "No response received from the server";
      } else {
        // Something happened in setting up the request that triggered an Error
        setError("Error occurred while making the request");
        return "Error occured while making the request";
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    error,
    isLoading,
    isError,
    fetchData,
  };
};

export default useAxios;
