import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./fonts.css";
import { BrowserRouter } from "react-router-dom";
import UserContextProvider from "./context/UserContext";
import AuthContextProvider from "./context/AuthContext";
import DataContextProvider from "./context/DataContext";
import AdminContextProvider from "./context/AdminContext";
import ScheduleContextProvider from "./context/ScheduleContext";
import DeviceContextProvider from "./context/DeviceContext";
import HeartBeatContextProvider from "./context/HeatBeatContext";
import IaqContextProvider from "./context/IaqContext";
import BtuContextProvider from "./context/BtuContext";
import AhuContextProvider from "./context/AhuContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <AuthContextProvider>
        <AdminContextProvider>
          <HeartBeatContextProvider>
            <ScheduleContextProvider>
              <DeviceContextProvider>
                <AhuContextProvider>
                  <BtuContextProvider>
                    <IaqContextProvider>
                      <DataContextProvider>
                        <UserContextProvider>
                          <App />
                        </UserContextProvider>
                      </DataContextProvider>
                    </IaqContextProvider>
                  </BtuContextProvider>
                </AhuContextProvider>
              </DeviceContextProvider>
            </ScheduleContextProvider>
          </HeartBeatContextProvider>
        </AdminContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </>,
);
