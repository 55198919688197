import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./customers.css";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { GrFormPrevious } from "react-icons/gr";
import { useForm, useFieldArray } from "react-hook-form";
import { getCustomers, updateCustomerData } from "../../../utils/Api";
import { toast } from "react-toastify";
import { useAdmin } from "../../../context/AdminContext";
import { COLORS } from "../../../utils/constants";
import { FaMinusCircle } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";

const CustomerForm = ({ customerData }) => {
  // console.log(customerData);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
    reset,
    control,
  } = useForm({
    defaultValues: customerData,
  });

  const [updateMetadata, setUpdateMetadata] = useState("");
  const { setCustomerListLoading, setCustomerList } = useAdmin();
  const navigate = useNavigate();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "metadata.devices",
  });

  const {
    fields: ahus,
    append: addAhu,
    remove: removeAhu,
  } = useFieldArray({
    control,
    name: "metadata.devices.ahus",
  });

  const {
    fields: btu_meters,
    append: addBtu,
    remove: removeBtu,
  } = useFieldArray({
    control,
    name: "metadata.devices.btu_meters",
  });

  const {
    fields: iaq_sensors,
    append: addIaq,
    remove: removeIaq,
  } = useFieldArray({
    control,
    name: "metadata.devices.iaq_sensors",
  });

  const onError = (err) => {
    if (err?.metadata?.devices?.ahus) {
      toast.error("All AHU fields are required");
    }
    if (err?.metadata?.devices?.btu_meters) {
      toast.error("All BTU fields are required");
    }
    if (err?.metadata?.devices?.iaq_sensors) {
      toast.error("All IAQ fields are required");
    }
  };

  const onSubmit = async (formData, e) => {
    e.preventDefault();

    if (!customerData?.schema)
      return toast.error("Cannot update Customer! Please try again");
    if (!updateMetadata)
      return toast.error("Cannot update Customer! Please try again");
    let payload;
    // console.log("Update Metadata ? ", updateMetadata);
    if (updateMetadata === "yes") {
      const otherData = { ...formData };
      payload = {
        schema: customerData.schema,
        type: "metaData",
        data: {
          column: "devices",
          data: { devices: formData.metadata.devices, otherData },
        },
      };
    } else {
      payload = {
        schema: customerData.schema,
        type: "no_metadata_changes",
        data: {
          ...formData,
        },
      };
    }

    // console.log(payload);

    try {
      const { data } = await updateCustomerData(payload);
      toast.success("Customer is updated successfully");
      setCustomerListLoading(true);
      const { data: customerList } = await getCustomers();
      localStorage.setItem("customer_list", JSON.stringify(customerList));
      setCustomerList(customerList);
      setCustomerListLoading(false);
      navigate("/customers");
    } catch (error) {
      console.log(error);
      toast.error("Customer update failed");
    }
  };

  const FormInput = ({ name, label, readOnly, type }) => {
    return (
      <div>
        <label>
          {label}
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          {...register(name, {
            required: `${name} is required`,
            validate: (value) =>
              value.trim() !== "" || "Whitespace is not allowed",
          })}
          style={{
            backgroundColor: readOnly ? "gainsboro" : "",
            cursor: readOnly ? "not-allowed" : "",
          }}
          readOnly={readOnly}
          disabled={isSubmitting}
          type={type}
          {...(type === "date"
            ? { max: new Date().toISOString().split("T")[0] }
            : {})}
        />
        {/* {console.log(errors)} */}
        {errors && errors[name] && (
          <span
            style={{
              color: "#C41E3A",
            }}
          >
            {errors[name].message}
          </span>
        )}{" "}
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <FormInput label="Name" name="Name" />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormInput label="City" name="city" />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormInput label="Region" name="region" />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormInput label="Schema" name="schema" readOnly={true} />
        </Grid>
        <Grid item sm={12} md={8}>
          <FormInput label="Address" name="Address" />
        </Grid>
      </Grid>

      <br />
      <p
        style={{
          textAlign: "left",
          padding: "4px 10px",
          background: "orange",
          color: "white",
          marginBottom: "30px",
        }}
      >
        AHUs
      </p>

      <div>
        {ahus.map((device, index) => (
          <div
            key={device.id}
            style={{
              border: "1px solid gainsboro",
              padding: "12px",
              marginBottom: "20px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item sm={12} md={4}>
                <FormInput
                  label="Device ID"
                  name={`metadata.devices.ahus[${index}].device_id`}
                  readOnly={false}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <FormInput
                  label="Friendly Name"
                  name={`metadata.devices.ahus[${index}].friendly_name`}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <div>
                  <label>
                    Installation Date
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    {...register(
                      `metadata.devices.ahus[${index}].installation_date`,
                    )}
                    defaultValue={device.installation_date}
                    disabled={isSubmitting}
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={4}>
                <div>
                  <label>
                    Location
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    {...register(`metadata.devices.ahus[${index}].location`, {
                      required: "This field is required",
                      validate: (value) =>
                        value.trim() !== "" || "Whitespace is not allowed",
                    })}
                    defaultValue={device.location}
                    disabled={isSubmitting}
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={4}>
                <div>
                  <label>
                    Is Schedule Active?
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    defaultValue={device.is_schedule_active}
                    disabled={isSubmitting}
                    {...register(
                      `metadata.devices.ahus[${index}].is_schedule_active`,
                      {
                        required: "This field is required",
                        validate: (value) =>
                          value.trim() !== "" || "Whitespace is not allowed",
                      },
                    )}
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              </Grid>
            </Grid>

            <button
              type="button"
              onClick={() => removeAhu(index)}
              style={{
                backgroundColor: "#C41E3A",
                marginTop: "20px",
                marginRight: "auto",
              }}
            >
              <FaMinusCircle style={{ fontSize: "24px", fontWeight: "bold" }} />

              <span style={{ paddingLeft: "10px" }}>Remove AHU</span>
            </button>
          </div>
        ))}
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
            margin: "0 auto",
          }}
        >
          <button
            type="button"
            style={{
              width: "100%",
              background: "transparent",
              border: "2px dashed " + COLORS.PRIMARY_COLOR,
              color: COLORS.PRIMARY_COLOR,
              marginBottom: "20px",
            }}
            onClick={() =>
              addAhu({
                friendly_name: "",
                installation_date: "",
                location: "",
                is_schedule_active: "",
                device_id: "",
              })
            }
          >
            <FaPlusCircle
              style={{
                fontSize: "24px",
                fontWeight: "bold",
              }}
            />
            <span style={{ paddingLeft: "20px" }}>ADD AHU</span>
          </button>
        </Box>
      </div>
      <p
        style={{
          textAlign: "left",
          padding: "4px 10px",
          background: "purple",
          color: "white",
          marginBottom: "30px",
        }}
      >
        BTUs
      </p>
      {btu_meters.map((device, index) => (
        <>
          <div
            key={device.id}
            style={{
              border: "1px solid gainsboro",
              padding: "12px",
              marginBottom: "20px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item sm={12} md={4}>
                <FormInput
                  label="Device ID"
                  name={`metadata.devices.btu_meters[${index}].device_id`}
                  // readOnly={true}
                  type="text"
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <FormInput
                  label="Friendly Name"
                  name={`metadata.devices.btu_meters[${index}].friendly_name`}
                  type="text"
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <FormInput
                  label="Installation Date"
                  name={`metadata.devices.btu_meters[${index}].installation_date`}
                  type="date"
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <FormInput
                  label="Location"
                  name={`metadata.devices.btu_meters[${index}].location`}
                  type="text"
                />
              </Grid>
            </Grid>

            <button
              type="button"
              onClick={() => removeBtu(index)}
              style={{
                backgroundColor: "#C41E3A",
                marginTop: "20px",
                marginRight: "auto",
              }}
            >
              <FaMinusCircle style={{ fontSize: "24px", fontWeight: "bold" }} />
              <span style={{ paddingLeft: "10px" }}>Remove BTU</span>
            </button>
          </div>
        </>
      ))}
      <Box
        sx={{
          width: {
            xs: "100%",
            md: "50%",
          },
          margin: "0 auto",
        }}
      >
        <button
          type="button"
          onClick={() =>
            addBtu({
              friendly_name: "",
              installation_date: "",
              location: "",
              device_id: "",
            })
          }
          style={{
            width: "100%",
            background: "transparent",
            border: "2px dashed " + COLORS.PRIMARY_COLOR,
            color: COLORS.PRIMARY_COLOR,
            marginBottom: "20px",
          }}
        >
          <FaPlusCircle
            style={{
              fontSize: "24px",
              fontWeight: "bold",
            }}
          />
          <span style={{ paddingLeft: "20px" }}>ADD BTU</span>
        </button>
      </Box>
      <p
        style={{
          textAlign: "left",
          padding: "4px 10px",
          background: "#00A6A6",
          color: "white",
          marginBottom: "30px",
        }}
      >
        IAQs
      </p>
      {iaq_sensors.map((device, index) => (
        <div
          key={device.device_id}
          style={{
            border: "1px solid gainsboro",
            padding: "12px",
            marginBottom: "20px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item sm={12} md={4}>
              <FormInput
                label="Device ID"
                name={`metadata.devices.iaq_sensors[${index}].device_id`}
                // readOnly={true}
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <FormInput
                label="Friendly Name"
                name={`metadata.devices.iaq_sensors[${index}].friendly_name`}
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <FormInput
                label="Location"
                name={`metadata.devices.iaq_sensors[${index}].location`}
              />
            </Grid>
          </Grid>

          <button
            type="button"
            onClick={() => removeIaq(index)}
            style={{
              backgroundColor: "#C41E3A",
              marginTop: "20px",
              marginRight: "auto",
            }}
          >
            <FaMinusCircle style={{ fontSize: "24px", fontWeight: "bold" }} />
            <span style={{ paddingLeft: "10px" }}>Remove IAQ</span>
          </button>
        </div>
      ))}
      <Box
        sx={{
          width: {
            xs: "100%",
            md: "50%",
          },
          margin: "0 auto",
        }}
      >
        <button
          type="button"
          style={{
            width: "100%",
            background: "transparent",
            border: "2px dashed " + COLORS.PRIMARY_COLOR,
            color: COLORS.PRIMARY_COLOR,
          }}
          onClick={() =>
            addIaq({
              friendly_name: "",
              location: "",
              device_id: "",
            })
          }
        >
          <FaPlusCircle
            style={{
              fontSize: "24px",
              fontWeight: "bold",
            }}
          />
          <span style={{ paddingLeft: "20px" }}>ADD IAQ</span>
        </button>
      </Box>

      <br />
      <br />
      <Typography
        sx={{
          textAlign: "left",
          fontSize: "18px",
          fontWeight: "bold",
          color: COLORS.PRIMARY_COLOR,
        }}
      >
        Have you made any changes (add, delete, update) to the AHU, BTU or IAQ?
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ my: 1 }}
      >
        <Box>
          <input
            type="radio"
            value="yes"
            style={{
              width: "50px",
              height: "18px",
              color: COLORS.PRIMARY_COLOR,
              background: COLORS.PRIMARY_COLOR,
              margin: 0,
            }}
            checked={updateMetadata === "yes"}
            onChange={(e) => setUpdateMetadata(e.target.value)}
          />
        </Box>
        <Box>
          <span>Yes</span>
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <input
          type="radio"
          value="no"
          style={{
            width: "50px",
            height: "18px",
            color: COLORS.PRIMARY_COLOR,
            background: COLORS.PRIMARY_COLOR,
            margin: 0,
          }}
          checked={updateMetadata === "no"}
          onChange={(e) => setUpdateMetadata(e.target.value)}
        />
        <span>No</span>
      </Stack>
      {/* <Stack>
        <input
          type="checkbox"
          checked={updateMetadata}
          onChange={(e) => setUpdateMetadata(e.target.checked)}
        />
        <span>Update Devices' data</span>
      </Stack> */}

      <button type="submit" style={{ marginTop: "15px" }}>
        {isSubmitting ? "Saving Changes..." : "Save Changes"}
      </button>
    </form>
  );
};

const UpdateCustomer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location.state);
  return (
    <Paper elevation={0} sx={{ padding: "16px" }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={() => navigate("/customers")}
      >
        <GrFormPrevious style={{ fontSize: "30px" }} />
        <span style={{ fontFamily: "DMSans", fontWeight: "500" }}>
          Previous
        </span>
      </Stack>

      {/** Title */}
      <h1>CUSTOMER UPDATE FORM</h1>
      <br />
      <br />
      {/* Form to update customer information */}
      <CustomerForm customerData={location.state} />
    </Paper>
  );
};

export default UpdateCustomer;
