import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import "./Btu.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState, memo } from "react";
import moment from "moment-timezone";
import AreaChart from "../../components/charts/AreaChart";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import { getBtuData } from "../../utils/Api";
import {
  AGILE_INSTALLATION_TYPE,
  CLAIRCO_CLIENT_HISTORY_TYPES,
  CLAIRCO_INSTALLATION_TYPE,
} from "../../utils/constants";
import { getItemFromLocalstroage, getTodayDate } from "../../utils/helper";
import { toast } from "react-toastify";
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import { useAuthContext } from "../../context/AuthContext";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";

const BtuCard = ({ data, title, lastUpdatedTimeSlot }) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {data}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle2">{lastUpdatedTimeSlot}</Typography>
    </Box>
  );
};

const Btu = () => {
  const location = useLocation();
  const branchId = location.state?.branchId || "";
  const deviceId = location.state?.deviceId || "";
  const [btu, setBtu] = useState(null);
  const { setBreadcrumbs } = useAuthContext();
  const [btuGraph, setBtuGraph] = useState({
    time_slot: [],
    temp_in: [],
    temp_out: [],
    delta_t: [],
    water_flow: [],
    btu_power: [],
    cooling_energy: [],
    volume: [],
  });
  const [interval, setInterval] = useState(
    CLAIRCO_CLIENT_HISTORY_TYPES.LAST_24_HOURS,
  );
  const installationType = getItemFromLocalstroage("installationType");

  const [btuWaterFlow, setBtuWaterFlow] = useState("Not Available");
  const [btuDeltaTemp, setBtuDeltaTemp] = useState("Not Available");
  const [btuCoolingEnergy, setBtuCoolingEnergy] = useState("Not Available");
  const [selectedDate, setSelectedDate] = useState("");
  const [lastFetchedBtuData, setLastFetchedBtuData] = useState(
    "Last fetched BTU Data : NA",
  );
  const params = useParams();
  const schema = params.schema;
  const navigate = useNavigate();
  const [isBtuDataViewRestricted, setIsBtuDataViewRestricted] = useState(false);

  useEffect(() => {
    setBreadcrumbs([
      { path: `/${params.schema}/btu/list`, breadcrumbName: "BTU List" },
      { path: `/${params.schema}/btu`, breadcrumbName: "BTU Details" },
    ]);
  }, [location]);

  useEffect(() => {
    if (btu) {
      const installationType = getItemFromLocalstroage("installationType");
      if (installationType === AGILE_INSTALLATION_TYPE) {
        let lastNonNullObject = null;
        for (let i = btu?.data_set?.length - 1; i >= 0; i--) {
          if (btu.data_set[i].water_flow !== null) {
            lastNonNullObject = btu.data_set[i];
            break;
          }
        }

        if (lastNonNullObject) {
          let lastUpdatedTime = `Last btu data is fetched at ${moment(
            lastNonNullObject?.time_slot,
            "HH:mm",
          ).format("h:mm A")}, ${selectedDate}`;
          setLastFetchedBtuData(lastUpdatedTime);
          if (lastNonNullObject?.water_flow) {
            let waterflow =
              parseInt(lastNonNullObject?.water_flow).toFixed("2") +
              " litre/hr";
            if (waterflow) setBtuWaterFlow(waterflow);
            else setBtuWaterFlow("Not Available");
          }

          if (lastNonNullObject?.delta_t) {
            let temp =
              parseFloat(lastNonNullObject?.delta_t).toFixed("2") + " °C";
            if (temp) setBtuDeltaTemp(temp);
            else setBtuDeltaTemp("Not Available");
          }
          if (lastNonNullObject?.cooling_energy) {
            let btureading = parseInt(
              lastNonNullObject?.cooling_energy,
            ).toLocaleString("en-IN");

            if (btureading) setBtuCoolingEnergy(btureading);
            else setBtuCoolingEnergy("Not Available");
          }
        }
      }

      if (installationType === CLAIRCO_INSTALLATION_TYPE) {
        // console.log(btu);
        let lastNonNullObject = null;
        for (let i = btu?.length - 1; i >= 0; i--) {
          if (btu[i].metaData.water_flow !== null) {
            lastNonNullObject = btu[i];
            break;
          }
        }

        // console.log("last not null object ", lastNonNullObject);

        if (lastNonNullObject) {
          let lastUpdatedTime = `Last btu data is fetched at ${moment(
            lastNonNullObject?.time,
            "HH:mm",
          ).format("h:mm A")}, ${selectedDate}`;
          setLastFetchedBtuData(lastUpdatedTime);
          if (lastNonNullObject?.metaData?.water_flow) {
            let waterflow =
              parseInt(lastNonNullObject?.metaData?.water_flow).toFixed("2") +
              "litre/hr";
            if (waterflow) setBtuWaterFlow(waterflow);
            else setBtuWaterFlow("Not Available");
          }

          if (lastNonNullObject?.metaData.delta_t) {
            let temp =
              parseFloat(lastNonNullObject?.metaData?.delta_t).toFixed("2") +
              " °C";
            if (temp) setBtuDeltaTemp(temp);
            else setBtuDeltaTemp("Not Available");
          }
          if (lastNonNullObject?.metaData?.cooling_energy) {
            let btureading = parseInt(
              lastNonNullObject?.metaData?.cooling_energy,
            ).toLocaleString("en-IN");

            if (btureading) setBtuCoolingEnergy(btureading);
            else setBtuCoolingEnergy("Not Available");
          }
        }
      }
    }
  }, [btu, interval]);

  const fetchBtuData = async (day) => {
    if (!branchId || !deviceId || !day) {
      return;
    }
    const installationType = getItemFromLocalstroage("installationType");
    if (installationType === AGILE_INSTALLATION_TYPE) {
      try {
        let btuResponse = await getBtuData({
          branchId,
          deviceId,
          day,
          flag_details: {
            installation_type: AGILE_INSTALLATION_TYPE,
          },
        });
        // console.log(btuResponse);
        btuResponse = btuResponse.data;
        setBtu(btuResponse);

        if (btuResponse?.data?.code == 405) {
          return alert("Validation exception");
        }

        // if (installationType == CLAIRCO_INSTALLATION_TYPE) {
        //   btuResponse = btuResponse.map((device) => device.metaData);
        // } else {
        //   btuResponse = btuResponse.data_set;
        // }
        btuResponse = Array.isArray(btuResponse)
          ? btuResponse.map((device) => device.metaData)
          : btuResponse?.data_set || [];
        const time_slot = btuResponse?.map((btu) => btu.time_slot) || [];
        const temp_in = btuResponse?.map((btu) => btu.temp_in) || [];
        const temp_out = btuResponse?.map((btu) => btu.temp_out) || [];
        const delta_t = btuResponse?.map((btu) => btu.delta_t) || [];
        const water_flow = btuResponse?.map((btu) => btu.water_flow) || [];
        const btu_power = btuResponse?.map((btu) => btu.btu_power) || [];
        const cooling_energy =
          btuResponse?.map((btu) => btu.cooling_energy) || [];
        const volume = btuResponse?.map((btu) => btu.volume) || [];
        setBtuGraph({
          time_slot,
          temp_in,
          temp_out,
          delta_t,
          water_flow,
          btu_power,
          cooling_energy,
          volume,
        });
      } catch (error) {
        console.log("Error in getting BTU Data : ", error);
        if (error.code === "ECONNABORTED") {
          toast.error("Server took too long to respond.");
        }
        if (error?.response?.status === 403) {
          setIsBtuDataViewRestricted(true);
        }
      }
    }
    if (installationType === CLAIRCO_INSTALLATION_TYPE) {
      try {
        let btuResponse = await getBtuData({
          deviceId,
          fetchType: "history",
          flag_details: {
            installation_type: CLAIRCO_INSTALLATION_TYPE,
            interval,
          },
        });
        btuResponse = btuResponse.data;
        // console.log("btu ", btuResponse);
        setBtu(btuResponse.msg);

        const time_slot =
          btuResponse?.msg?.map((btu) => {
            const date = btu.date;
            const time = btu.time;
            const dateTime = moment(date + "T" + time);
            const formattedDateTime = dateTime.format("hh:mm A, DD MMM");
            return formattedDateTime;
          }) || [];
        const temp_in =
          btuResponse?.msg?.map((btu) => btu.metaData.temp_in) || [];
        const temp_out =
          btuResponse?.msg?.map((btu) => btu.metaData.temp_out) || [];
        const delta_t =
          btuResponse?.msg?.map((btu) => btu.metaData.delta_t) || [];
        const water_flow =
          btuResponse?.msg?.map((btu) => btu?.metaData?.water_flow) || [];
        const btu_power =
          btuResponse?.msg?.map((btu) => btu?.metaData?.btu_power) || [];
        const cooling_energy =
          btuResponse?.msg?.map((btu) => btu?.metaData?.cooling_energy) || [];
        const volume =
          btuResponse?.msg?.map((btu) => btu?.metaData?.volume) || [];
        // console.log({
        //   time_slot,
        //   temp_in,
        //   temp_out,
        //   delta_t,
        //   water_flow,
        //   btu_power,
        //   cooling_energy,
        //   volume,
        // });
        setBtuGraph({
          time_slot,
          temp_in,
          temp_out,
          delta_t,
          water_flow,
          btu_power,
          cooling_energy,
          volume,
        });
      } catch (error) {
        console.log("Error in getting BTU Data : ", error);
        if (error.code === "ECONNABORTED") {
          toast.error("Server took too long to respond.");
        }
        if (error?.response?.status === 403) {
          setIsBtuDataViewRestricted(true);
        }
      }
    }
  };

  let graphData = {
    labels: btuGraph?.time_slot,
    datasets: [
      {
        label: "Temp In",
        data: btuGraph?.temp_in,
        borderColor: "rgb(132, 94, 194)",
        backgroundColor: "rgba(132, 94, 194,0.5)",
        fill: true,
        pointRadius: 2,
        tension: 0.5,
        yAxisID: "temp_flow",
      },
      {
        label: "Temp Out",
        data: btuGraph?.temp_out,
        borderColor: "rgb(0, 201, 167)",
        backgroundColor: "rgba(0, 201, 167,0.5)",
        fill: true,
        pointRadius: 2,
        tension: 0.5,
        yAxisID: "temp_flow",
      },

      {
        label: "Cooling Energy",
        data: btuGraph?.cooling_energy,
        borderColor: "rgb(255, 128, 102)",
        backgroundColor: "rgba(255, 128, 102,0.5)",
        fill: true,
        pointRadius: 2,
        tension: 0.5,
        yAxisID: "cooling_energy",
      },
    ],
  };
  const handleDateChange = (newValue) => {
    const date = moment(newValue["$d"]).format("YYYY-MM-DD");
    setSelectedDate(date.toString());
    fetchBtuData(date);
  };

  useEffect(() => {
    const todayDate = getTodayDate();
    if (!branchId || !deviceId) return;
    fetchBtuData(todayDate);
  }, [interval]);

  return (
    <Box sx={{ padding: { sm: "20px", xs: "5px" } }} className="ahu_container">
      <Paper elevation={0} sx={{ padding: 2, marginBottom: 3 }}>
        <CustomBreadcrumb />
        <br />
        <Divider sx={{ mb: 3 }} />
        <Stack direction="row" gap={5} sx={{ flexWrap: "wrap" }}>
          <Stack>
            <Typography
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
              gutterBottom
            >
              {location.state?.btuDetails?.device_id ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              DEVICE ID
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
              gutterBottom
            >
              {location.state?.btuDetails?.friendly_name ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              BTU NAME
            </Typography>
          </Stack>
          <Stack>
            <Typography
              gutterBottom
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
            >
              {location.state?.btuDetails?.installation_date ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              INSTALLATION DATE
            </Typography>
          </Stack>
          <Stack>
            <Typography
              gutterBottom
              sx={{ color: "#344a53", fontWeight: 700, fontSize: "14px" }}
            >
              {location.state?.btuDetails?.location ?? "Not Available"}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#008675", fontSize: "12px" }}
            >
              LOCATION
            </Typography>
          </Stack>
        </Stack>
      </Paper>
      {isBtuDataViewRestricted ? (
        <AccessDenied />
      ) : (
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          <Grid container item md={8} sm={12}>
            {graphData.hasOwnProperty("datasets") ? (
              <div className="btu_graph_container">
                {installationType === AGILE_INSTALLATION_TYPE ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Select Date"
                      format="DD/MM/YYYY"
                      disableFuture={true}
                      onChange={handleDateChange}
                    />
                  </LocalizationProvider>
                ) : (
                  <select
                    value={interval}
                    onChange={(e) => setInterval(e.target.value)}
                    style={{ width: "300px" }}
                  >
                    <option value="12 hours">12 Hours</option>
                    <option value="24 hours" selected>
                      24 Hours
                    </option>
                    <option value="3 days">3 Days</option>
                    <option value="7 days">7 Days</option>
                  </select>
                )}

                {/* <div style={{ width: "100%", margin: "0 auto" }}> */}
                <AreaChart
                  data={graphData}
                  temp_flow={true}
                  cooling_energy={true}
                  temp={false}
                  ChWP={false}
                  heading="BTU Consumption"
                />
                {/* </div> */}
              </div>
            ) : (
              <Paper sx={{ width: "100%" }} elevation={0}>
                <Typography>No BTU Data Available</Typography>
              </Paper>
            )}
          </Grid>
          <Grid container item md={4} sm={12} xs={12} rowGap={3}>
            <Box className="ahu_cards">
              <BtuCard
                data={btuWaterFlow}
                title="Water Flow"
                lastUpdatedTimeSlot={lastFetchedBtuData}
              />
            </Box>
            <Box className="ahu_cards">
              <BtuCard
                data={btuDeltaTemp}
                title="Delta Temp"
                lastUpdatedTimeSlot={lastFetchedBtuData}
              />
            </Box>
            <Box className="ahu_cards">
              <BtuCard
                data={btuCoolingEnergy}
                title="Cooling Energy"
                lastUpdatedTimeSlot={lastFetchedBtuData}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Btu;
