import React, { useEffect, useState } from "react";
import "./CustomerDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Select,
  OutlinedInput,
  Chip,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { addUserToCustomer, getCustomers, getUsersBySchema } from "../../../utils/Api";
import { useAuthContext } from "../../../context/AuthContext";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { DataGrid } from "@mui/x-data-grid";
import { useDataContext } from "../../../context/DataContext";
import { ToastContainer, toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { useAdmin } from "../../../context/AdminContext";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { COLORS } from "../../../utils/constants";
import { FaEdit } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ViewUsers = ({ openViewUserModal, setOpenViewUserModal, schema }) => {
  const [usersData, setUsersData] = useState([]);
  const [isUserViewRestricted, setIsUserViewRestricted] = useState(false);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data: apiResponse } = await getUsersBySchema(schema);
        // console.log(response);

        apiResponse =
          apiResponse?.response?.users?.map((user) => {
            return {
              ...user,
              name: `${capitalizeFirstLetter(
                user.first_name,
              )}  ${capitalizeFirstLetter(user.last_name)}`,
            };
          }) ?? [];

        // console.log("Api Response ", apiResponse);
        // console.log("user list state ", apiResponse);
        setUsersData(apiResponse ?? []);
        setIsUserDataLoading(false);
      } catch (error) {
        // console.log("Error Fetching data: ", error);
        if (error.response.status === 403) {
          setIsUserViewRestricted(true);
        }
        setIsUserDataLoading(false);
      }
    };
    if (openViewUserModal) {
      fetchData();
    }
  }, [openViewUserModal]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    { field: "email", headerName: "Email", width: 250 },
    { field: "phone_number", headerName: "Contact", width: 120 },
    // { field: "last_login", headerName: "Last Login", width: 100 },
  ];

  if (isUserViewRestricted || isUserDataLoading) {
    return (
      <Box sx={style}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="customer_form_title"
        >
          <Typography> List of Users</Typography>
          <IoMdClose
            onClick={() => setOpenViewUserModal(false)}
            style={{ cursor: "pointer" }}
          />
        </Stack>
        <div>
          <Typography>
            {isUserDataLoading
              ? "Loading..."
              : "You do not have enough permissions to view the users"}
          </Typography>
        </div>
      </Box>
    );
  }

  return (
    <Box sx={style} className="customer_form">
      <div className="customer_form_title">
        <Typography> List of Users</Typography>
        <IconButton
          color="primary"
          className="modal_close_btn"
          onClick={() => setOpenViewUserModal(false)}
        >
          <Close fontSize="large" />
        </IconButton>
      </div>

      {usersData.length ? (
        <div>
          <DataGrid
            rows={usersData}
            columns={columns}
            pageSize={5}
            sx={{
              "& :focus": {
                border: "none",
              },
              "& :active": {
                border: "none",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            disableRowSelectionOnClick
            disableColumnSelector
          />
        </div>
      ) : (
        <Box>
          <Typography>No Users Found!</Typography>
        </Box>
      )}
    </Box>
  );
};

const UserForm = ({ setOpenAddUserModal, schema }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();
  const [selectedRoles, setSelectedRoles] = useState([]);

  const { roles } = useAdmin();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  // console.log(roles);
  const onSubmit = async (data, e) => {
    e.preventDefault();
    // console.log(data);
    // console.log(selectedRoles);
    try {
      const roleIds = selectedRoles
        .map((roleName) => {
          const role = roles.find((r) => r.roleName === roleName);
          return role ? role.id : null;
        })
        .filter(Boolean);

      if (!roleIds.length) {
        return toast.error("Please select at least one user role");
      }
      // console.log("Role IDs ", roleIds);
      const payload = { ...data, schema, roles: roleIds };
      // console.log("user add data ", payload);
      // API call to add the new user here...
      const { data: response } = await addUserToCustomer(payload);
      // console.log(response);
      toast.success("Successfully added user!");
      reset();
    } catch (error) {
      console.log("Error in adding a new user", error);
      if (error?.response?.status === 403) {
        return toast.error(
          "You do not have enough permissions to perform this action!",
        );
      }

      toast.error("Failed to add user.");
    }
  };

  const userFormInputs = [
    {
      label: "First Name",
      type: "text",
      name: "first_name",
      required: true,
      helperText: "",
    },
    {
      label: "Last Name",
      type: "text",
      name: "last_name",
      required: true,
      helperText: "",
    },
    {
      label: "Email",
      type: "email",
      name: "email",
      required: true,
      helperText: "",
    },
    {
      label: "Password",
      type: "password",
      name: "password",
      required: true,
      helperText: "",
    },
    {
      label: "Phone Number",
      type: "text",
      name: "phone_number",
      required: true,
      helperText: "",
    },
  ];
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Box sx={style} className="customer_form">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="customer_form_title"
      >
        <Typography variant="h6"> Add New User</Typography>
        <IoMdClose
          onClick={() => setOpenAddUserModal(false)}
          style={{ cursor: "pointer" }}
        />
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {userFormInputs.map((input, i) => (
            <Grid item md={6} key={i}>
              <div className="customer_input_container">
                <div>
                  <label>
                    {input.label}
                    {input.required && <span style={{ color: "red" }}>*</span>}
                  </label>
                  <input
                    className="customer_input"
                    type={input.type}
                    {...register(input.name, {
                      required: input.required,
                    })}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    style={{
                      border: "1px solid #c8c8c8",
                      background: isSubmitting ? COLORS.GRAY_COLOR : "",
                    }}
                    disabled={isSubmitting}
                  />

                  {!input.helperText ? null : (
                    <p className="helper_text">{input.helperText}</p>
                  )}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid item sm={6}>
          <div style={{ width: "100%" }}>
            <label style={{ color: "gray" }}>Roles</label>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={selectedRoles}
              onChange={handleChange}
              renderValue={(selectedRoles) => (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selectedRoles.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Stack>
              )}
              MenuProps={MenuProps}
              sx={{ width: "100%" }}
              disabled={isSubmitting}
            >
              {roles.map((role) => (
                <MenuItem key={role.roleName} value={role.roleName}>
                  <Checkbox
                    checked={selectedRoles.indexOf(role.roleName) > -1}
                  />
                  <ListItemText primary={role.roleName} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
        <br />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
          sx={{
            background: isSubmitting ? COLORS.GRAY_COLOR : COLORS.PRIMARY_COLOR,
            cursor: isSubmitting ? "not-allowed" : "",
          }}
        >
          {isSubmitting ? "Adding User..." : "Add User"}
        </Button>
      </form>
    </Box>
  );
};

const CustomerBreadCrumb = ({ customer }) => {
  const navigate = useNavigate();
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CustomBreadcrumb />
      <FaEdit
        style={{ cursor: "pointer", color: "#14b8a6" }}
        title="Edit Customer"
        onClick={() =>
          navigate("/customers/update", {
            state: customer,
          })
        }
      />
    </Stack>
  );
};

const CustomerDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openViewUserModal, setOpenViewUserModal] = useState(false);
  const [alert, showAlert] = useState(false);
  const { updatePilotMode, pilotModeUpdateStatus } = useDataContext();
  const [pilotMode, setPilotMode] = useState(
    location.state?.metadata?.pilotMode,
  );
  const { setCustomerListLoading, setCustomerList } = useAdmin();
  const customerName = location.state?.Name ?? "Not Available";
  const city = location.state?.city ?? "Not Available";
  const address = location.state?.Address ?? "Not Available";
  const region = location.state?.region ?? "Not Available";
  const schema = location.state?.schema ?? "Not Available";
  const clientID = location.state?.metadata?.client_id ?? "Not Available";
  const branchId = location.state?.metadata?.branch_id ?? "Not Available";
  const AHUs = location.state?.metadata?.devices?.ahus?.map((ahu) => ahu) ?? [];
  const BTUs =
    location.state?.metadata?.devices?.btu_meters?.map((btu) => btu) ?? [];
  const IAQs =
    location.state?.metadata?.devices?.iaq_sensors?.map((iaq) => iaq) ?? [];
  const rows = [];

  rows.push({ title: "Schema", value: schema });
  rows.push({ title: "Client ID", value: clientID });
  rows.push({ title: "Branch ID", value: branchId });

  const { breadcrumbs, setBreadcrumbs } = useAuthContext();
  const handleChange = async (event) => {
    try {
      setPilotMode(event.target.checked);
      updatePilotMode(schema, event.target.checked);
      showAlert(true);
      const { data: customerList } = await getCustomers();
      localStorage.setItem("customer_list", JSON.stringify(customerList));
      setCustomerList(customerList);
      setCustomerListLoading(false);
      navigate("/customers");
    } catch (error) {
      console.log("Error updating pilot mode", error);
      if (error?.response?.status === 403) {
        toast.warning(
          "You do not have enough permissions to perform this action",
        );
      }
    }
  };
  useEffect(() => {
    setBreadcrumbs([
      { path: "/customers", breadcrumbName: "Customer List" },
      { path: "/customer", breadcrumbName: customerName },
    ]);
  }, [location]);

  return (
    <Box>
      {/* <ToastContainer /> */}
      <Paper elevation={1}>
        <Box sx={{ padding: "20px" }}>
          <CustomerBreadCrumb customer={location.state} />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mb: "20px" }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenAddUserModal(true)}
              sx={{ mt: "20px" }}
            >
              Add User
            </Button>
            <Button
              variant="contained"
              // onClick={() => setOpenViewUserModal(true)}
              onClick={() =>
                navigate("/customer/users", {
                  state: location.state,
                })
              }
              sx={{ mt: "20px", ml: "20px" }}
            >
              View Users
            </Button>
            <FormControlLabel
              sx={{
                ml: 0,
                mt: "20px",
              }}
              control={
                <Switch
                  checked={pilotMode}
                  onChange={handleChange}
                  name="pilotMode"
                  sx={{
                    m: "auto",
                  }}
                />
              }
              label="Pilot Mode"
            />
          </Stack>
          <Typography gutterBottom color="primary" sx={{ textAlign: "left" }}>
            {customerName}
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {address}
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {city}
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {region}
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ mt: "20px" }}>
            AHUs
          </Typography>
          <Snackbar
            open={alert}
            autoHideDuration={6000}
            message={pilotModeUpdateStatus}
          />
          {AHUs?.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Installation Date</TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Location</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {AHUs?.map((ahu, i) => (
                    <TableRow key={i}>
                      <TableCell>{ahu.friendly_name}</TableCell>
                      <TableCell>{ahu.installation_date}</TableCell>
                      <TableCell>{ahu.device_id}</TableCell>
                      <TableCell>{ahu.location}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert severity="error" sx={{ m: "20px 0" }}>
              AHUs are not installed at this site.
            </Alert>
          )}
          <Typography variant="h6" gutterBottom>
            BTUs
          </Typography>
          {BTUs?.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Installation Date</TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {BTUs?.map((btu, i) => (
                    <TableRow key={i}>
                      <TableCell>{btu.friendly_name}</TableCell>
                      <TableCell>{btu.installation_date}</TableCell>
                      <TableCell>{btu.device_id}</TableCell>
                      <TableCell>{btu.location}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert severity="error" sx={{ m: "20px 0" }}>
              BTUs are not installed at this site.
            </Alert>
          )}
          <Typography variant="h6" gutterBottom>
            IAQs
          </Typography>
          {IAQs?.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {IAQs?.map((iaq, i) => (
                    <TableRow key={i}>
                      <TableCell sx={{ color: "#737373" }}>
                        {iaq.friendly_name}
                      </TableCell>
                      <TableCell>{iaq.device_id}</TableCell>
                      <TableCell>{iaq.location}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert severity="error" sx={{ m: "20px 0" }}>
              IAQs are not installed at this site.
            </Alert>
          )}
        </Box>
      </Paper>
      <Modal open={openAddUserModal} onClose={() => setOpenAddUserModal(false)}>
        <UserForm setOpenAddUserModal={setOpenAddUserModal} schema={schema} />
      </Modal>
      <Modal
        open={openViewUserModal}
        onClose={() => setOpenViewUserModal(false)}
      >
        <ViewUsers
          openViewUserModal={openViewUserModal}
          setOpenViewUserModal={setOpenViewUserModal}
          schema={schema}
        />
      </Modal>
    </Box>
  );
};

export default CustomerDetails;
