import { useState, useEffect, memo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Paper, Button, Fab, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAdmin } from "../../../context/AdminContext";
import moment from "moment-timezone";
import { Add } from "@mui/icons-material";
import "./customers.css";
import { useAuthContext } from "../../../context/AuthContext";
import AccessDenied from "../../../components/AccessDenied/AccessDenied";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit } from "react-icons/fa";

const Customers = () => {
  const navigate = useNavigate();
  const {
    customerList,
    customerListLoading,
    setAdminView,
    setSelectedClient,
    isRestricted,
  } = useAdmin();
  let currentMonthSavingsTabelHead = moment().format("MMM") + " savings";
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const { setBreadcrumbs } = useAuthContext();
  useEffect(() => {
    if (customerList.length > 0) {
      let columns = [
        { field: "id", headerName: "ID" },
        { field: "logo_url", headerName: "#" },
        { field: "schema", headerName: "Schema" },
        {
          field: "Name",
          headerName: "Customer",
          headerClassName: "header-cell",
          // width: 300,
          flex: 1,
          renderCell: (params) => {
            const schema = params.row.schema;
            if (!Object.keys(params.row.metadata).length)
              return params.row.Name;
            if (
              (!params.row?.metadata?.devices?.ahus ||
                params.row?.metadata?.devices?.ahus?.length === 0) &&
              (!params.row?.metadata?.devices?.btu_meters ||
                params.row?.metadata?.devices?.btu_meters?.length === 0) &&
              (!params.row?.metadata?.devices?.iaq_sensors ||
                params.row?.metadata?.devices?.iaq_sensors?.length === 0)
            )
              return params.row.Name;

            return (
              <Link
                to={`/${schema}/overview`}
                style={{ color: "#11765b", fontStyle: "italic" }}
                onClick={() => {
                  localStorage.setItem(`identifier`, schema);
                  localStorage.setItem("adminView", true);
                  setSelectedClient(schema);
                  localStorage.setItem("selectedClient", schema);
                  setAdminView(true);
                }}
              >
                {params.row.Name}
              </Link>
            );
          },
        },

        {
          field: "currentMonthSavings",
          flex: 1,
          headerName: currentMonthSavingsTabelHead,
          renderCell: (params) => {
            const savings = params.value;
            const savingsStyle = {
              fontWeight: "bold",
              color: params.value === "Not Available" ? "red" : "green",
            };
            return <span style={savingsStyle}>{params.value}</span>;
          },
        },

        // {
        //   field: "Address",
        //   headerName: "Address",
        //   flex: 1,
        // },
        {
          field: "branch_id",
          headerName: "Branch ID",
          flex: 1,
          renderCell: (params) => {
            return <span style={{ textAlign: "center" }}>{params.value}</span>;
          },
        },

        {
          field: "client_id",
          headerName: "Client ID",
          flex: 1,
        },
        { field: "city", headerName: "City", flex: 1 },
        { field: "website_link", headerName: "Link" },
        { field: "poc_name", headerName: "POC Name" },
        { field: "poc_email", headerName: "POC Email" },
        { field: "poc_contact", headerName: "POC Contact" },
        { field: "region", headerName: "Region", flex: "auto" },
        {
          field: "action",
          headerName: "Action",
          // width: 180,
          flex: 1,
          renderCell: (params) => {
            const getParams = () => {
              navigate("/customer", {
                state: {
                  ...params.row,
                },
              });
            };
            return (
              <Button variant="contained" onClick={getParams}>
                View Details
              </Button>
            );
          },
        },
      ];

      const rowFormat = customerList.map((response) => {
        return {
          id: response?.id || "Not Available",
          Name: response?.Name || "Not Available",
          city: response?.city || "Not Available",
          schema: response?.schema || "Not Available",
          region: response?.region || "Not Available",
          Address: response?.Address || "Not Available",
          currentMonthSavings: "Not Available",
          metadata: response?.metaData || "Not Available",
          client_id: response?.metaData?.client_id || "Not Available",
          branch_id: response?.metaData?.branch_id || "Not Available",
        };
      });
      setRows(rowFormat);
      setColumns(columns);
    }
  }, [customerList]);

  useEffect(() => {
    setBreadcrumbs([{ path: "/customers", breadcrumbName: "Customer List" }]);
  }, []);

  if (customerListLoading) return "Customer List Loading...";

  if (isRestricted) {
    return <AccessDenied />;
  }
  return (
    <Paper
      elevation={0}
      sx={{
        p: "10px",
        width: "100%",
        overflowX: "auto",
      }}
    >
      <Box
        sx={{
          width: { xs: "900px", md: "100%" },
          overflowX: "auto",
          zIndex: 0,
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            columns: {
              columnVisibilityModel: {
                id: false,
                website_link: false,
                schema: true,
                logo_url: false,
                poc_name: false,
                poc_contact: false,
                poc_email: false,
                Address: false,
                region: true,
                client_id: false,
                branch_id: false,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          disableRowSelectionOnClick
          disableColumnSelector
          // disableColumnFilter
          sx={{
            "& :focus": {
              border: "none",
            },
            "& :active": {
              border: "none",
            },
          }}
        />
      </Box>
      <Box sx={{ padding: "20px" }}>
        <Fab
          variant="extended"
          color="primary"
          sx={{ marginRight: "auto" }}
          onClick={() => navigate("/customers/add")}
        >
          <Add sx={{ mr: 1 }} />
          Customer
        </Fab>
      </Box>
    </Paper>
    /* </Box> */
  );
};

export default memo(Customers);
