import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./DeviceDetails.css";
import { Grid, Paper, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { toast } from "react-toastify";
import { controlDevice, getLatestDeviceData } from "../../../utils/Api";
import moment from "moment";

const Select = ({
  label,
  register,
  name,
  options,
  isSubmitting,
  defaultValue,
}) => {
  return (
    <div>
      <label>{label}</label>
      <select
        {...register(name)}
        defaultValue={defaultValue}
        disabled={isSubmitting}
      >
        {options.map((option, index) => (
          <option value={option.name} key={index}>
            {option.title}
          </option>
        ))}
      </select>{" "}
    </div>
  );
};

const Input = ({ label, register, name, ...rest }) => {
  return (
    <div>
      <label>{label}</label>
      <input {...register(name, {})} {...rest} />
    </div>
  );
};

const SetTemperature = ({ temperature, lastFetchedTime }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm();
  const device = useLocation().state.device;
  const navigate = useNavigate();
  const onSubmit = async (data, e) => {
    e.preventDefault();
    // console.log("set temp react hook form data ", data);
    data.setTemp = data.setTemp ? data.setTemp * 10 : 0;
    const payload = {
      deviceId: device.id,
      commandType: "mode",
      command: {
        key: "STEMP",
        value: data.setTemp,
        expiry: (data.expiry * 60).toString(),
      },
    };

    // console.log("temp payload ", payload);

    try {
      const { data } = await controlDevice(payload);
      toast.success("Temperature is set successfully!");
    } catch (error) {
      console.log(error);
      if (error.code === "ECONNABORTED") {
        toast.error("Problem! Cannot connect to the device");
      } else if (error.request) {
        console.log("Request error:", error.request);
        toast("Request error, please check your network.");
      } else if (error.response.status === 403) {
        toast.warning(
          "You do not have enough permisssions to perform this action",
        );
      } else if (error.response) {
        console.log("Response error:", error.response.data);
        console.log("Status:", error.response.status);
        toast(error.response?.data?.msg || error.response.data);
      } else {
        toast.error("An unexpected error occurred. Please try again later");
      }
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {lastFetchedTime && (
          <p className="helperText">
            * The last update was at {lastFetchedTime}
          </p>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={5}>
            {device?.metaData?.actions?.setTemp?.value && (
              <>
                <Input
                  label="Set Temperature (°C)"
                  register={register}
                  name="setTemp"
                  type="number"
                  // isSubmitting={isSubmitting}
                  defaultValue={temperature}
                  min={18}
                  max={30}
                  step="any"
                  disabled={isSubmitting}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            {device?.metaData?.actions?.setTemp?.value && (
              <Input
                label="Expiry Time (in minutes)"
                register={register}
                name="expiry"
                type="number"
                // isSubmitting={isSubmitting}
                disabled={isSubmitting}
                defaultValue={0}
                min={0}
              />
            )}
          </Grid>
        </Grid>
        <button
          type="submit"
          className={
            isSubmitting ? "update_ahu_btn_disabled" : "update_ahu_btn"
          }
          disabled={isSubmitting}
        >
          {isSubmitting ? "Saving Changes..." : "Save Changes"}
        </button>
      </form>
    </div>
  );
};

const ToggleAHU = ({ ahuStatus, lastFetchedTime }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm();
  const device = useLocation().state.device;
  const navigate = useNavigate();
  const onSubmit = async (data, e) => {
    e.preventDefault();

    // data.setTemp = data.setTemp ? data.setTemp * 10 : 0;
    // console.log(data);

    const deviceONOFFPayload = {
      deviceId: device.id,
      commandType: "mode",
      command: {
        key: "RELAY1_SET",
        value: data.power,
        expiry: (data.expiry * 60).toString(),
      },
    };

    // console.log("ahu payload ", deviceONOFFPayload);

    try {
      const { data: deviceResponse } = await controlDevice(deviceONOFFPayload);
      toast.success("AHU power status updated successfully!");
    } catch (error) {
      console.log(error);
      if (error.code === "ECONNABORTED") {
        toast.info("Problem! Cannot connect to the device");
      } else if (error.request) {
        console.log("Request error:", error.request);
        toast("Request error, please check your network.");
      } else if (error.response.status === 403) {
        toast.warning(
          "You do not have enough permisssions to perform this action",
        );
      } else if (error.response) {
        // console.log("Response error:", error.response.data);
        // console.log("Status:", error.response.status);
        toast.error(error.response?.data?.msg || error.response.data);
      } else {
        toast.error("An unexpected error occurred. Please try again later");
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {lastFetchedTime && (
          <p className="helperText">
            * The last update was at {lastFetchedTime}
          </p>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={5}>
            {device?.metaData?.actions?.power?.value && (
              <Select
                label="Turn AHU ON/OFF"
                register={register}
                name="power"
                options={[
                  { name: "1", title: "ON" },
                  { name: "0", title: "OFF" },
                ]}
                isSubmitting={isSubmitting}
                defaultValue={ahuStatus}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            {device?.metaData?.actions?.power?.value && (
              <Input
                label="Expiry Time (in minutes)"
                register={register}
                name="expiry"
                type="number"
                // isSubmitting={isSubmitting}
                disabled={isSubmitting}
                defaultValue={0}
                min={0}
              />
            )}
          </Grid>
        </Grid>
        <button
          type="submit"
          className={
            isSubmitting ? "update_ahu_btn_disabled" : "update_ahu_btn"
          }
          disabled={isSubmitting}
        >
          {isSubmitting ? "Saving Changes..." : "Save Changes"}
        </button>
      </form>
    </div>
  );
};

const DeviceControl = () => {
  const device = useLocation().state.device;
  const [showDeviceControlBtns, setShowDeviceControlBtns] = useState(false);
  const [showLoadingText, setShowLoadingText] = useState(true);
  const [showComponent, setShowComponent] = useState(null);
  const [fetchError, setFetchError] = useState(false); // New state for fetch error
  const [ahuStatus, setAhuStatus] = useState(null); // State for AHU status
  const [temperature, setTemperature] = useState(null); // State for temperature
  const [lastFetchedTime, setLastFetchedTime] = useState("");
  // role based restriction
  const [isDeviceFetchRestricted, setIsDeviceFetchRestricted] = useState(false);

  useEffect(() => {
    getLatestDeviceData(device.id)
      .then(({ data: response }) => {
        // console.log(response);
        if (!response) {
          setFetchError(true); // Set fetch error state if response is falsy
          setShowLoadingText(false); // Hide loading text
          return toast("Unable to fetch live data for this device");
        }
        // Clear fetch error state if data is fetched successfully
        setFetchError(false);
        setShowLoadingText(false);
        // console.log(response.msg.metaData);
        // Extract data from response and update states
        setAhuStatus(response.msg.metaData.unit_st);
        const stemp = Number(response.msg.metaData.stemp) / 10;
        setTemperature(stemp);
        setLastFetchedTime(
          moment(response.msg.time, "HH:mm:ss").format("h:mm A"),
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setFetchError(true); // Set fetch error state if there's an error
        setShowLoadingText(false); // Hide loading text
        toast("Unable to fetch live data for this device");
        if (error.response.status === 403) {
          setIsDeviceFetchRestricted(true);
        }
      });
  }, [device]);

  const handleClick = (component) => {
    setShowComponent(component);
  };

  if (isDeviceFetchRestricted) {
    return (
      <Paper sx={{ padding: "20px" }}>
        <Typography>
          You do not have enough permissions to access this page
        </Typography>
      </Paper>
    );
  }

  return (
    <div className="ahu_details">
      <Link to={"/device/details"} className="back_btn">
        <span>
          <ArrowBackIosIcon />
        </span>
        <span>Previous</span>
      </Link>
      <br />
      <p style={{ textAlign: "left" }}>
        Device ID - <b>{device?.id ?? "Not Available"}</b>
      </p>
      {fetchError ? (
        <div>
          <p>There is some issue with the device</p>
        </div>
      ) : showLoadingText ? (
        <div>
          <p>Testing the Device Connection. Please wait for a moment</p>
        </div>
      ) : (
        <div>
          {showComponent === "SetTemperature" ? (
            <SetTemperature
              temperature={temperature}
              lastFetchedTime={lastFetchedTime}
            />
          ) : showComponent === "ToggleAHU" ? (
            <ToggleAHU
              ahuStatus={ahuStatus}
              lastFetchedTime={lastFetchedTime}
            />
          ) : (
            <div>
              <button onClick={() => handleClick("SetTemperature")}>
                Set Temperature
              </button>
              <button onClick={() => handleClick("ToggleAHU")}>
                Turn On / Off AHU
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DeviceControl;
