import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import { useAuthContext } from "../../../context/AuthContext";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";

const ScheduleDetails = () => {
  const location = useLocation();
  const { setBreadcrumbs } = useAuthContext();
  const scheduleName = location.state?.name || "Not Available";
  const type = location.state?.type || "Not Available";
  const frequency = location.state?.frequency || "Not Available";
  const runningDays = location.state?.days || "Not Available";
  const schedule = location.state?.schedule || "Not Available";
  const rows = [];

  rows.push({ title: "Name", value: scheduleName });

  useEffect(() => {
    setBreadcrumbs([
      { path: "/schedule", breadcrumbName: "Schedules" },
      {
        path: "/scheduleDetails",
        breadcrumbName: scheduleName,
      },
    ]);
  }, []);

  return (
    <Box>
      <Paper element={1}>
        <Box sx={{ padding: "20px" }}>
          <CustomBreadcrumb />
          <Typography
            gutterBottom
            color="primary"
            sx={{ mt: "20px", textAlign: "left" }}
          >
            {"Name: " + scheduleName}
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {"Schedule Type: " + type}
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {"Frequency: " + frequency + " minutes"}
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {"Running Days: " + runningDays}
          </Typography>
        </Box>
        <div style={{ padding: "20px" }}>
          <h4>Slots</h4>
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Schedule</th>
                </tr>
              </thead>
              <tbody>
                {schedule?.map((data, index) => (
                  <tr key={index}>
                    <td>
                      {Object.keys(data)[0][0] +
                        Object.keys(data)[0][1] +
                        ":" +
                        Object.keys(data)[0][2] +
                        Object.keys(data)[0][3]}
                    </td>
                    <td>{Object.values(data)[0]}</td>
                  </tr>
                )) || "No Data Available"}
              </tbody>
            </table>
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default ScheduleDetails;
