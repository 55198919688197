import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "../../pages/admin/devices/Device.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
);

const DeviceGraph = ({ label, values, labels }) => {
  const data = {
    // labels: ["January", "February", "March", "April", "May"],
    labels,
    datasets: [
      {
        // label: "Sales",
        label,
        fill: true, // Fill the area under the line
        lineTension: 0.5, // Tension of the line (0 means straight lines)
        backgroundColor: "rgba(75,192,192,0.4)", // Area fill color
        borderColor: "rgba(75,192,192,1)", // Line color
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 0.5,
        pointHitRadius: 10,
        // data: [65, 59, 80, 81, 56],
        data: values,
      },
    ],
  };

  // Chart options
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        },
      },
    },
  };
  return <Line data={data} options={options} />;
};

export default DeviceGraph;
