import React, { useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useUserContext } from "../../context/UserContext";
import SimpleSnackbar from "../notification/SimpleSnackbar";
import { logout } from "../../utils/helper";
import axios from "axios";
import "./sidebar.css";
import {
  Air,
  Dashboard,
  Hvac,
  LockOpen,
  Logout,
  RoomPreferences,
  Close,
  AcUnitOutlined,
} from "@mui/icons-material";
import { Stack, Modal, Box, Typography, Button, Divider } from "@mui/material";
const EnergyReport = ({
  handleDownloadReport,
  isOpen,
  closeModal,
  setFromDate,
  setToDate,
  fromDate,
  toDate,
  readOnlyDate,
  downloadReportBtnText,
  disableDownloadbtn,
}) => {
  const minimumToDate = dayjs(fromDate).add(1, "day");
  return (
    <Modal open={isOpen} onClose={() => closeModal(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "5px",
          padding: "20px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h6">Energy Efficieny Report</Typography>
          </Box>
          <Box>
            <Button onClick={() => closeModal(false)}>
              <Close />
            </Button>
          </Box>
        </Stack>
        <Divider light />
        <Stack className="modal_body">
          <Box sx={{ width: "100%", my: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select From Date"
                format="DD/MM/YYYY"
                disableFuture={true}
                onChange={(newValue) => {
                  const date = dayjs(newValue).format("YYYY-MM-DD");
                  setFromDate(date);
                }}
                readOnly={readOnlyDate}
                sx={{ zIndex: 1, width: "100%" }}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select To Date"
                format="DD/MM/YYYY"
                disableFuture={true}
                minDate={minimumToDate}
                onChange={(newValue) => {
                  const date = dayjs(newValue).format("YYYY-MM-DD");
                  setToDate(date);
                }}
                sx={{ zIndex: 1, width: "100%", mb: "20px" }}
                readOnly={readOnlyDate}
              />
            </LocalizationProvider>
          </Box>
          <div>
            <button
              className="report_download_btn"
              onClick={handleDownloadReport}
              disabled={disableDownloadbtn}
              // style={{ cursor: "not-allowed" }}
            >
              {downloadReportBtnText}
            </button>
          </div>
        </Stack>
      </Box>
    </Modal>
  );
};

const ClientSidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { setIsAdmin, setIsLoggedIn, isAdmin } = useAuthContext();
  const { selected, setSelected, selectedTwo, setSelectedTwo } =
    useUserContext();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [readOnlyDate, setReadOnlyDate] = useState(false);
  const [downloadReportBtnText, setDownloadReportBtnText] =
    useState("Download Report");
  const [disableDownloadbtn, setDisableDownloadbtn] = useState(false);

  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [alert, setAlert] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDownloadReport = async () => {
    console.log("Downloading Report");
    setReadOnlyDate(true);
    setDownloadReportBtnText("Downloading Report");
    setDisableDownloadbtn(true);
    axios({
      method: "post",
      url: "https://api.claircoair.com/api/generateReport",

      data: {
        branchId: JSON.parse(localStorage.getItem("schema"))[0].metaData
          .branch_id,
        schema: params.schema,
        fromDate,
        toDate,
        type: "energyReport",
      },
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "api-key": "lcvhzec0ae4u20047s36mfexcjr2ebd3cpk28b9yca9c9f95d17dgeda",
      },
      timeout: 30 * 1000,
    })
      .then((response) => {
        // console.log(response);
        // Create a download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.xlsx"); // Set the file name with the .xlsx extension

        // Append the link to the body before triggering the click event
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link
        document.body.removeChild(link);
        setAlert("Download successful");
      })
      .catch((error) => {
        console.error("Error downloading report:", error);
        setAlert("Downloading of report is failed");
        setOpenAlertBox(true);
      })
      .finally(() => {
        setReadOnlyDate(false);
        setDownloadReportBtnText("Download Report");
        setDisableDownloadbtn(false);
      });
  };

  const handleLogout = async () => {
    logout(isAdmin, params.schema);
  };
  const menuItemsOne = [
    {
      icon: <Dashboard />,
      text: "Overview",
      path: `/${params.schema}/overview`,
    },
    {
      icon: <Air />,
      text: "AHU",
      path: `/${params.schema}/ahu/list`,
    },
    {
      icon: <Hvac />,
      text: "BTU",
      path: `/${params.schema}/btu/list`,
    },
    {
      icon: <RoomPreferences />,
      text: "IAQ",
      path: `/${params.schema}/iaq/list`,
    },
    {
      text: "Chiller Management",
      path: `/${params.schema}/chiller/list`,
      icon: <AcUnitOutlined />,
    },
  ];
  let menuItemsTwo = [
    {
      icon: <LockOpen />,
      text: "Change Password",
      path: `/${params.schema}/changepassword`,
    },
    {
      icon: <Logout />,
      text: "Logout",
      path: `/${params.schema}/logout`,
    },
  ];

  if (isAdmin) {
    menuItemsTwo = menuItemsTwo.filter(
      (item) => item.text !== "Change Password",
    );
  }

  const handleSecondMenuList = (menuPath, menuText, selectedMenuIndex) => {
    if (menuText === "Logout") {
      handleLogout();
      return;
    }
    navigate(menuPath);
    setSelectedTwo(selectedMenuIndex);
    setSelected(null);
    toggleSidebar();
  };

  return (
    <div className={`client_sidebar ${isSidebarOpen ? "open" : ""}`}>
      <div className="client_menu_items">
        <img
          src="https://static.wixstatic.com/media/840069_a293e3debec44af58ef18f0305740397~mv2.png/v1/fill/w_188,h_85,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/White%20without%20Clean%20Air%20G.png"
          style={{ margin: "0 auto 20px auto" }}
        />
        {menuItemsOne.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              navigate(item.path);
              setSelected(index);
              setSelectedTwo(null);
              toggleSidebar();
            }}
            className={
              selected !== index
                ? "client_menu_item"
                : "client_menu_item_active"
            }
          >
            {item.icon}
            <span className="client_menu_text">{item.text}</span>
          </div>
        ))}
      </div>
      <div className="client_menu_items">
        {menuItemsTwo.map((item, index) => (
          <div
            key={index}
            onClick={() => handleSecondMenuList(item.path, item.text, index)}
            className={
              selectedTwo !== null && selectedTwo === index
                ? "client_menu_item_active"
                : "client_menu_item"
            }
          >
            {item.icon}
            <span className="client_menu_text">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientSidebar;
