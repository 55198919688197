import { Alert, Box } from "@mui/material";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAhuMonthlyData } from "../../db/query";
import { getItemFromLocalstroage } from "../../utils/helper";
import {
  AGILE_INSTALLATION_TYPE,
  CLAIRCO_INSTALLATION_TYPE,
} from "../../utils/constants";
import { toast } from "react-toastify";

const AhuList = () => {
  const [schema, setSchema] = useState(
    JSON.parse(localStorage.getItem("schema")),
  );

  const installationType = getItemFromLocalstroage("installationType");

  const ahuList = schema?.[0]?.metaData.devices.ahus;
  // console.log(ahuList);
  const [ahuCompleteInfo, setAhuCompleteInfo] = useState(null);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getAhuMonthlyData()
      .then((response) => {
        setAhuCompleteInfo(response[0].data);
      })
      .catch((error) => {
        console.log(error);
        setAhuCompleteInfo(null);
      });
  }, []);

  const navigatePage = async (ahu) => {
    const deviceId = ahu.device_id;
    // console.log("Device ID => ", deviceId);
    if (installationType == AGILE_INSTALLATION_TYPE) {
      if (ahuCompleteInfo && ahuCompleteInfo.length > 0) {
        const selectedAhuData = ahuCompleteInfo.find((item) => {
          if (!item) return null;
          if (item.deviceId == deviceId) return item;
        });
        // console.log(selectedAhuData);

        if (
          typeof selectedAhuData === "object" &&
          selectedAhuData !== null &&
          !Array.isArray(selectedAhuData)
        ) {
          navigate(`/${params.schema}/ahu`, {
            state: {
              ahu: selectedAhuData,
              deviceId,
              ahuDetails: ahu,
            },
          });
        } else {
          toast.error("No data available for the selected AHU!");
        }
      } else {
        toast.error("No AHU data available!");
      }
    }
    if (installationType == CLAIRCO_INSTALLATION_TYPE) {
      // console.log("Device ID => ", deviceId);
      // console.log("ahu ", ahu);
      navigate(`/${params.schema}/ahu`, {
        state: {
          deviceId,
          ahuDetails: ahu,
        },
      });
    }
  };

  if (!ahuList || (ahuList && ahuList.length == 0)) {
    return <div style={{ padding: "2rem" }}>NO AHU DATA AVAILABLE</div>;
  }
  return (
    <Box>
      {/* <Alert severity="info" sx={{ mb: "20px", background: "#D9FBF7" }}>
        This is a list of all the available air handling units in this building.
      </Alert> */}
      <TableContainer sx={{ borderRadius: "5px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                AHU NAME
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                INSTALLATION DATE
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                LOCATION
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ahuList?.map((ahu, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "white",
                    color: " #344a53",
                  }}
                >
                  {ahu?.friendly_name}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "white",
                    color: " #344a53",
                  }}
                >
                  {ahu?.installation_date}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "white",
                    color: " #344a53",
                  }}
                >
                  {ahu?.location}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "white",
                  }}
                >
                  <Button variant="contained" onClick={() => navigatePage(ahu)}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AhuList;
