import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Paper, Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const IaqList = () => {
  const navigate = useNavigate();
  const iaqDevices =
    JSON.parse(localStorage.getItem("schema"))?.[0]?.metaData.devices
      ?.iaq_sensors || [];
  const params = useParams();
  const schema = params.schema;
  const columns = [
    {
      field: "id",
      headerName: "Device",
      width: 150,
    },
    {
      field: "friendly_name",
      headerName: "Name",
      sortable: false,
      width: 250,
    },
    {
      field: "location",
      headerName: "Location",
      sortable: false,
      flex: 3,
    },

    {
      headerName: "Actions",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() =>
            navigate(`/${schema}/iaq`, {
              state: {
                deviceInfo: params.row,
              },
            })
          }
        >
          View
        </Button>
      ),
    },
  ];
  const [iaqList, setIaqList] = useState([]);
  const [iaqDeviceListLoading, setIaqDeviceListLoading] = useState(true);
  useEffect(() => {
    let rows = [];
    iaqDevices.forEach((iaqDevice) => {
      rows.push({
        id: iaqDevice?.device_id || "Not Available",
        friendly_name: iaqDevice?.friendly_name || "Not Available",
        location: iaqDevice?.location || "Not Available",
      });
    });
    setIaqList(rows);
    setIaqDeviceListLoading(false);
  }, []);

  if (iaqDeviceListLoading) return "Loading...";

  if (!iaqList.length) return "IAQ List Not Available";

  return (
    <Paper
      elevation={0}
      sx={{
        p: "10px",
        width: "100%",
        overflowX: "auto",
      }}
    >
      <Box
        sx={{
          width: { xs: "900px", md: "100%" },
          overflowX: "auto",
          zIndex: 0,
        }}
      >
        <DataGrid
          rows={iaqList}
          columns={columns}
          disableSelectionOnClick // Disable row selection
          disableClickEventBubbling // Disable focus when clicking on a row
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns status, the other columns will remain visible
                // id: true,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </Box>
    </Paper>
  );
};

export default IaqList;
