import { useNavigate } from "react-router-dom";
import { useSchedule } from "../../../context/ScheduleContext";
import { useEffect, useState, memo } from "react";
import { Box, Paper, Fab, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";
import { useAuthContext } from "../../../context/AuthContext";
import AccessDenied from "../../../components/AccessDenied/AccessDenied";

const Schedule = () => {
  const navigate = useNavigate();
  const { scheduleList, scheduleListLoading, isSchduleViewRestricted } =
    useSchedule();

  useEffect(() => {
    // console.log("schedules ", scheduleList);
  }, [scheduleList]);

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const { setBreadcrumbs } = useAuthContext();
  useEffect(() => {
    if (scheduleList.length > 0) {
      let columns = [
        { field: "id", headerName: "ID", flex: 1 },
        { field: "name", headerName: "Name", flex: 1 },
        {
          field: "frequency",
          headerName: "Running Frequency (in mins)",
          flex: 1,
        },
        { field: "days", headerName: "Running Days", flex: 1 },
        { field: "type", headerName: "Type", flex: 1 },
        {
          field: "action",
          headerName: "Action",
          flex: 1,
          renderCell: (params) => {
            const getParams = () => {
              navigate("/scheduleDetails", {
                state: {
                  ...params.row,
                },
              });
            };
            return (
              <Button variant="contained" onClick={getParams}>
                View Details
              </Button>
            );
          },
        },
      ];
      const rowFormat = scheduleList.map((response) => {
        return {
          id: response?.id || "NA",
          name: response?.name || "NA",
          frequency: response?.scheduleConfig?.frequency || "NA",
          days: response?.scheduleConfig?.runningDays || "NA",
          type: response?.scheduleConfig?.taskType || "NA",
          schedule: response?.schedule || "NA",
        };
      });

      setRows(rowFormat);
      setColumns(columns);
    }
  }, [scheduleList]);

  useEffect(() => {
    setBreadcrumbs([{ path: "/schedule", breadcrumbName: "Schedules" }]);
  }, []);

  if (scheduleListLoading) return "Schedule List Loading";

  if (isSchduleViewRestricted) {
    return <AccessDenied />;
  }

  if (!scheduleList.length) {
    return (
      <Paper sx={{ padding: "20px" }}>
        <Typography sx={{ textAlign: "left" }}>No Schedules Found!</Typography>
      </Paper>
    );
  }

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          p: "10px",
          width: "100%",
          overflowX: "auto",
        }}
      >
        <Box sx={{ width: { xs: "900px", md: "100%" }, overflowX: "auto" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  name: true,
                  frequency: true,
                  days: true,
                  type: true,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            disableRowSelectionOnClick
            disableColumnSelector
            sx={{
              "& :focus": {
                border: "none",
              },
              "& :active": {
                border: "none",
              },
            }}
          />
        </Box>
        <Box sx={{ padding: "20px" }}>
          <Fab
            variant="extended"
            color="primary"
            sx={{ marginTop: "auto" }}
            onClick={() => navigate("/schedule/add")}
          >
            <Add sx={{ mr: 1 }} />
            Schedule
          </Fab>
        </Box>
      </Paper>
    </>
  );
};

export default memo(Schedule);
