import { useContext, createContext, useState, useEffect } from "react";
import { getBtuData } from "../utils/Api";
import {
  calculateLastHourBtuConsumption,
  getItemFromLocalstroage,
  getTodayDate,
} from "../utils/helper";
import {
  AGILE_INSTALLATION_TYPE,
  CLAIRCO_INSTALLATION_TYPE,
} from "../utils/constants";

export const BtuContext = createContext();

const getConsumptionProperty = (responseItem, installationType) => {
  if (responseItem.status === "fulfilled") {
    return installationType === AGILE_INSTALLATION_TYPE
      ? responseItem.value.data.data_set
      : installationType === CLAIRCO_INSTALLATION_TYPE
      ? responseItem.value.data.metaData
      : null; // Handle other installation types if needed
  } else {
    return "NA";
  }
};

const BtuContextProvider = ({ children }) => {
  const [btuLastHourConsumptionList, SetBtuLastHourConsumptionList] = useState(
    [],
  );

  const [
    isBtuLastHourConsumptionViewRestricted,
    setIsBtuLastHourConsumptionViewRestricted,
  ] = useState(false);
  const [
    btuLastHourConsumptionListLoading,
    setBtuLastHourConsumptionListLoading,
  ] = useState(true);
  const calculateBtuLastHourConsumptionOfAllDevices = async () => {
    try {
      let schema = getItemFromLocalstroage("schema");
      let installationType = getItemFromLocalstroage("installationType");
      schema = JSON.parse(schema);
      let branchId = schema?.[0]?.metaData?.branch_id;
      if (!schema || !branchId) return;
      const btuDevices =
        schema?.[0]?.metaData?.devices?.btu_meters?.map((btu) => btu || "") ||
        [];
      // console.log(btuDevices);
      const btuDevicesNames =
        schema?.[0]?.metaData?.devices?.btu_meters.map(
          (btu) => btu.friendly_name || "",
        ) || [];

      // console.log("btu devices => ", btuDevices);
      const requests = btuDevices.map((btu) => {
        // clairco payload
        if (installationType == CLAIRCO_INSTALLATION_TYPE) {
          return getBtuData({
            deviceId: btu.device_id,
            fetchType: "history",
            flag_details: {
              interval: "24 hours",
              installation_type: CLAIRCO_INSTALLATION_TYPE,
            },
          });
        }
        // Agile payload
        return getBtuData({
          branchId,
          deviceId: btu.device_id,
          day: getTodayDate(),
          flag_details: {
            installation_type: AGILE_INSTALLATION_TYPE,
          },
        });
      });
      const btuResponse = await Promise.allSettled(requests);
      // console.log(btuResponse);
      if (!btuResponse.length) return;

      if (installationType == AGILE_INSTALLATION_TYPE) {
        let btuConsumptionList = btuDevicesNames.map((name, index) => {
          return {
            name,
            consumption:
              btuResponse[index].status === "fulfilled"
                ? calculateLastHourBtuConsumption(
                    btuResponse[index].value.data.data_set,
                  )
                : "NA",
          };
        });
        SetBtuLastHourConsumptionList(btuConsumptionList);
      }

      if (installationType == CLAIRCO_INSTALLATION_TYPE) {
        let btuConsumptionList = btuDevicesNames.map((name, index) => {
          return {
            name,
            consumption:
              btuResponse[index].status === "fulfilled"
                ? calculateLastHourBtuConsumption(
                    btuResponse[index].value.data.map(
                      (device) => device.metaData,
                    ),
                  )
                : "NA",
          };
        });
        SetBtuLastHourConsumptionList(btuConsumptionList);
      }
    } catch (error) {
      console.log("Error in getting BTU Data : ", error);
      if (error?.response?.status === 403) {
        setIsBtuLastHourConsumptionViewRestricted(true);
      }
    } finally {
      setBtuLastHourConsumptionListLoading(false);
    }
  };
  return (
    <BtuContext.Provider
      value={{
        btuLastHourConsumptionList,
        calculateBtuLastHourConsumptionOfAllDevices,
        btuLastHourConsumptionListLoading,
        isBtuLastHourConsumptionViewRestricted,
      }}
    >
      {children}
    </BtuContext.Provider>
  );
};

export const useBtuContext = () => useContext(BtuContext);

export default BtuContextProvider;
